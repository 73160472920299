@media (max-width: 1175px) {
  .tab-selector-container {
    padding: 0 10px;
  }
}

@media (max-width: 570px) {
  .tab-selector-container {
    padding: 0 10px;
  }
}
