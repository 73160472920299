.jobs-tab-header {
  border-bottom: none;
  padding-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  .job-filter {
    select {
      border-radius: 24px !important;
    }
    & > ul {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin: 20px 0;
      padding: 0;

      ul#dLabel {
        display: inline;
      }

      li {
        vertical-align: top;
        padding: 0;
        margin: 20px 0 0 0;
        display: flex;
        align-items: center;
        margin-right: 20px;
        border-right: 1px solid rgba(182, 182, 182, 0.5);

        .form-field-wrapper {
          padding: 0;
          display: flex;
          flex-direction: column;

          label {
            margin: 0;
            padding: 0;
            vertical-align: middle;
            min-width: 100px;
          }

          input,
          textarea {
            width: auto;
            padding: 6px 12px;
            max-height: 34px;
            vertical-align: middle;
            border-radius: 24px;
          }

          select {
            min-width: 200px;
            border-radius: 24px;
          }

          .dropdown {
            display: inline-table;
            padding: 0;
            margin: 0;

            .input-group {
              padding: 0;

              input {
                min-width: 300px;
              }
            }
          }

          .date-picker {
            input {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            button.btn {
              border-radius: 0;
              border-top-right-radius: 24px;
              border-bottom-right-radius: 24px;
            }
          }

          #singleSelect {
            display: inline-block;
            width: auto;
            border-radius: 24px;
          }
        }

        .remove-icon {
          vertical-align: middle;
        }

        .glyphicon {
          top: 0;

          &.glyphicon-trash {
            top: 10px;
            margin: 0 20px;
          }

          &.glyphicon-info-sign {
            display: none;
          }
        }
      }
    }
  }

  .filters {
    display: flex;
    flex-direction: column;
    padding: 0 15px;

    span.filter {
      padding-left: 0;
      margin-left: 0;
    }

    .filter-lead {
      line-height: 34px;
      font-weight: bold;

      @media (min-width: 1200px) {
        margin-right: 10px;
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
    }

    .date-picker-wrapper {
      padding-bottom: 10px;
      margin-right: 0px;

      @media (min-width: 768px) {
        border-right: 1px solid var(--main-color);
        padding-right: 10px;
        padding-bottom: 0;
      }

      @media (min-width: 1200px) {
        display: flex;
        justify-content: center;
      }

      .input-group.filter {
        border: none !important;
        height: 34px;
        padding-right: 0;

        input {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border: 1px solid #eaeef1 !important;
          margin-top: 0;
        }

        button {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          border: 1px solid #eaeef1 !important;
        }
      }
    }

    .skill-filter {
      padding-bottom: 10px;
      flex: 1 0;

      @media (min-width: 768px) {
        border-right: 1px solid var(--main-color);
        padding: 0 10px;
      }

      @media (min-width: 1200px) {
        display: flex;
        justify-content: center;
      }

      .select-wrapper {
        display: block;
        margin-right: 0;
        flex: 1 0;
        height: 34px;

        &:after {
          top: 20px;
          right: 15px;
        }

        &:before {
          top: 4px;
          right: 15px;
        }

        #skillSelect {
          box-shadow: none;
          border-radius: 20px;
          color: grey !important;
          height: 34px;
          margin-left: 0;
        }
      }
    }

    .smart-search {
      @media (min-width: 768px) {
        padding-left: 10px;
        padding-bottom: 0;
      }

      @media (min-width: 1200px) {
        display: flex;
        justify-content: center;
      }

      .input-group.filter {
        display: block;
        border: none !important;
        padding-right: 0;

        input {
          border-radius: 20px;
          height: 34px;
          margin-left: 0;
          margin-top: 0;
        }
      }
    }
  }

  .misc-holder {
    background-color: #fff;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .switch-buttons {
      align-self: flex-end;
    }
  }
}
