.login-image {
  width: 100%;
  margin-bottom: 50px;
}

.sso-message{
  display: flex;
  flex-direction: column;
  
  img{
    height: 150px;
  }
}
.loginScreen {
  height: 800px;
  padding-top: 200px;

  @media (max-width: 425px) {
    padding-top: 50px;
  }

  input,
  button {
    border-radius: 10px;
    width: 100%;
    outline: none !important;
    border: none;
    height: 50px;
  }

  input {
    border: 1px solid #e0e0e0;
    background-color: #f9f9f9;
  }

  form {
    max-width: 420px;
    margin: 0 auto;
    background: #fff;
    padding: 32px;
    padding-bottom: 124px;
    border-radius: 20px;
    float: none;
    text-align: center;

    @media (max-width: 425px) {
      max-width: 300px;
    }

    h1 {
      font-size: 22px;
      font-weight: 700;
      color: #1b1b1bcc;
      margin-top: 12px;
      margin-bottom: 24px;
    }

    &.forgot-password {
      .recovery-icon {
        margin: 0 auto;
        background-color: #dc8a591a;
        border-radius: 50%;
        width: 68px;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 48px;
          height: 48px;
        }
      }

      h1 {
        margin-bottom: 12px;
      }

      .subtitle {
        margin-bottom: 32px;
        color: #1b1b1b99;
        font-size: 16px;
      }

      .password-rules-text {
        text-align: left;
        font-size: 14px;

        ul {
          margin: 0;
          padding: 0;
          margin-left: 20px;

          li {
            text-align: left;
          }
        }
      }
    }
  }

  .forgot-password-link {
    font-weight: bold;
    text-decoration: underline;
    margin-top: 24px;
    cursor: pointer;
    display: block;
  }

  .form-group {
    margin-bottom: 20px;
  }

  button.btn.btn-default {
    margin-top: 5px;
    background: $white;
    color: $niceBlue;
  }
}

.error-message {
  font-size: 1.6rem;
  padding: 15px 0 15px 0;
  font-weight: 700;
  color: $red;
  text-align: center;
}

.forgot-password {
  color: #000;
  float: right;
  font-size: 14px;
  padding-top: 15px;

  &:hover {
    color: #000;
  }
}