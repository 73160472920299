.rzslider {
  .rz-pointer {
    background-color: var(--main-color);
  }
}

.custom-slider.rzslider .rz-bar {
  background: #333;
  height: 2px;
}
.custom-slider.rzslider .rz-selection {
  background: var(--main-color);
}

.custom-slider.rzslider .rz-pointer {
  width: 15px;
  height: 15px;
  top: -8px;
  bottom: 0;
  background-color: var(--main-color);
}

.custom-slider.rzslider .rz-pointer:after {
  display: none;
}

.custom-slider.rzslider .rz-pointer.rz-active {
  background-color: var(--main-color);
}

.custom-slider.rzslider .rz-pointer.rz-active:after {
  outline: none;
  border: none;
}

.custom-slider.rzslider .rz-pointer.rz-active:focus {
  outline: 0;
}

.custom-slider.rzslider .rz-bubble {
  bottom: 20px;
}

.custom-slider.rzslider .rz-limit {
  font-weight: bold;
  color: var(--main-color);
}

.custom-slider.rzslider .rz-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: transparent;
  top: -1px;
}

.custom-slider.rzslider .rz-tick.rz-selected {
  background: var(--main-color);
}

.custom-slider.rzslider .rz-model-value {
  background: var(--main-color);
  color: white;
  border-radius: 6px;
}
