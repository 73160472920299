#adminTabs.tabset {
  .nav.nav-tabs.nav-justified {
    border-bottom: 1px solid #b3b5b7;
  }

  .nav-tabs.nav-justified>li {
    width: auto;

    @media (min-width: 767px) {
      display: inline-block;
      float: left;
    }
  }

  .nav-tabs>li.active>a,
  .nav-tabs>li>a {
    background: transparent;
    border: none;
  }
}

#reportingTabs.tabset {
  .nav.nav-tabs.nav-justified {
    border-bottom: 1px solid #b3b5b7;
  }

  .nav-tabs.nav-justified>li {
    width: auto;

    @media (min-width: 767px) {
      display: inline-block;
      float: left;
    }
  }

  .nav-tabs>li.active>a,
  .nav-tabs>li>a {
    background: transparent;
    border: none;
  }

  li.active>a {
    color: $black !important;
    font-weight: 400;
  }
}

.tabset {
  .nav {
    background-color: transparent;
    font-weight: 400;

    li>a {
      color: $black !important;
      background-color: transparent;
      padding: 0 40px 0 0;

      span {
        display: block;
        padding: 17px 0 17px 0;
        border-bottom: 8px solid transparent;
      }
    }

    li.active>a {
      color: $niceBlue !important;
      font-weight: 700;
      padding: 0 40px 0 0;

      span {
        border-bottom: 2px solid $niceBlue !important;
      }
    }
  }
}

.tab-content {
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .tabset {
    .nav {
      li>a {
        padding: 0 10px 0 0;
      }

      li.active>a {
        padding: 0 10px 0 0;
      }
    }
  }
}

.transformer-tabs {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 3px solid white;
  }

  li {
    display: inline-block;
    padding: 0;
    vertical-align: bottom;

    &:nth-child(1) .active {
      color: lighten(#9b59b6, 20%);
      border-bottom-color: #9b59b6;
    }

    &:nth-child(2) .active {
      color: lighten(#3498db, 20%);
      border-bottom-color: #3498db;
    }

    &:nth-child(3) .active {
      color: lighten(#e67e22, 20%);
      border-bottom-color: #e67e22;
    }

    &:nth-child(4) .active {
      color: lighten(#c0392b, 20%);
      border-bottom-color: #c0392b;
    }
  }

  a {
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 0.5rem;

    &.active {
      border-bottom: 3px solid transparent;
      position: relative;
      bottom: -3px;
    }
  }

  @media (max-width: 700px) {
    ul {
      border-bottom: 0;
      overflow: hidden;
      position: relative;
      background: #666;
      /* fallback */
      background: linear-gradient(#666, #222);

      &::after {
        content: '☰';
        position: absolute;
        top: 8px;
        right: 15px;
        z-index: 2;
        pointer-events: none;
      }

      &.open {
        a {
          position: relative;
          display: block;
        }
      }
    }

    li {
      display: block;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &.active {
        border: 0;
        z-index: 1;
        background: #666;
        /* fallback */
        background: linear-gradient(#666, #222);
      }
    }
  }
}

.tabs {
  >div {
    display: none;
    padding: 1rem;

    &:nth-of-type(1) {
      background: #9b59b6;
    }

    &:nth-of-type(2) {
      background: #3498db;
    }

    &:nth-of-type(3) {
      background: #e67e22;
    }

    &:nth-of-type(4) {
      background: #c0392b;
    }
  }

  >.active {
    display: block;
  }
}

.jobs-root-header {
  position: relative;
}

.jobs-root-selector {
  background: #fff;
  display: flex;
  align-items: center;

  border-radius: 20px;
  height: 40px;
  font-weight: 300;
  list-style-type: none;
  width: 280px;
  padding: 0;
  position: absolute;
  bottom: 15px;
  right: 0px;

  @media (max-width: 1024px) {
    position: static;
  }

  li {
    display: block;
    width: 50%;
    height: 100%;
    text-align: center;
    border-radius: 20px;
    line-height: 40px;

    &.active,
    &:hover {
      background-color: red;
      color: #fff;
      cursor: pointer;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:nth-child(2) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

//body {
//  padding: 1rem;
//  background: #333;
//  color: white;
//  font: 100%/1.4 Sans-Serif;
//}

// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
