.dashboard-widget {
  .widget-content {
    .widget-header {
      text-align: center;
      .widget-header-number {
        font-size: 36px;
        color: $niceBlue;
      }
    }
    .shift-count-holder {
      padding-left: 0;
      margin: 0 auto;
      .shift-count {
        display: inline-block;
        height: 60px;
        padding: 5px;
        border-left: 4px solid transparent;
        .data-value {
          font-size: 25px;
          color: #5a5a5a;
        }
        .data-status {
          font-size: 16px;
          &.Pending {
            color: $pending;
          }
          &.Completed {
            color: $completed;
          }
          &.Committed {
            color: $committed;
          }
          &.Canceled {
            color: $canceled;
          }
          &.Active {
            color: $active;
          }
          &.Open {
            color: $open;
          }

          &.Denied {
            color: $denied;
          }
        }

        &:hover {
          border-left: 4px solid $niceBlue;
          background: #f0f0f0;
        }
      }
    }
  }
}
