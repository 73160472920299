@import '../../../../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../../../../node_modules/angularjs-slider/dist/rzslider.css';
@import '../../../../node_modules/angular-wizard/dist/angular-wizard.css';
@import '../../../../node_modules/angular-bootstrap-toggle/dist/angular-bootstrap-toggle.css';

@import '../../../../node_modules/font-awesome/css/font-awesome.css';
@import '../../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import '../../../../node_modules/sweetalert2/src/sweetalert2.scss';
@import '../../../../node_modules/spin.js/spin.css';

@import '../../../../node_modules/angular-calendar/css/angular-calendar.css';
@import '../../../../node_modules/@angular/material/legacy-prebuilt-themes/legacy-indigo-pink.css';
