.filters {
  float: left;
  margin-bottom: 10px;

  .filter-lead {
    font-weight: bold;
  }

  select.filter,
  input.filter,
  span.filter {
    margin-left: 15px;
    padding-right: 10px;
    height: 40px;
    color: $niceBlue;
    border-radius: 20px;
    font-weight: bold;
    padding-left: 20px;
    outline: none;
    border: 1px solid $niceBlue;
    transition: all 0.2s linear;

    &::placeholder {
      color: #000000 !important;
      opacity: 1;
    }

    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #000000 !important;
      opacity: 0.8;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #000000 !important;
      opacity: 0.8;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000000 !important;
      opacity: 0.8;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #000000 !important;
      opacity: 0.8;
    }
  }

  span.filter {
    background: #ffffff;

    input {
      margin-top: 3px;
    }

    input, .input-group-btn, .input-group-btn button {
      border: none;
      outline: none;
      box-shadow: none;
      background: transparent !important;
      color: $niceBlue;
    }
  }
}
