.bottom-footer{

  background: transparent;
  color: black;

  .bull{
    font-size: 32px;
    padding-right: 10px;
    position: relative;
    top: 7px;
  }

  a{
    color: black!important;
  }

}


.navbar.navbar-default.navbar-static-bottom {
  margin-top: 0!important;
  background-color: transparent!important;
}