rzslider {
  span.rz-pointer {
    background-color: #000;
    width: 15px;
    height: 15px;
    top: -8px;

    &:after {
      display: none;
    }
  }

  span.rz-bubble:not(.rz-limit) {
    color: $white;
    background: #000;
    border-radius: 6px;
  }

  span.rz-bar {
    background: transparent;
  }

  .rz-ticks {
    .tick {
      background: transparent;
      height: 4px;
    }
  }
}