.tab-header-container {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;

  .tab-header-left {
    display: flex;

    :not(:first-child) {
      .filters {
        margin-left: 10px;
      }
    }
  }

  .tab-header-right {

    :not(:last-child) {
      margin-right: 10px;
    }

    .export-to-csv {
      border: none;
      border-radius: 50%;
      background: $niceBlue;
      color: #fff;
      width: 40px;
      height: 40px;
      transition: all linear 0.2s;

      &:hover {
        background: #fff;
        color: $niceBlue;
      }
    }
  }
}

@media (max-width: 570px) {
  .tab-header-container {
    flex-direction: column;

    .tab-header-left {
      flex-direction: column;

      .filters {
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        width: 100%;

        span,
        select {
          margin: 5px 0;
        }
      }
    }

    .tab-header-right {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0;
    }
  }
}
