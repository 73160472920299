@font-face {
  font-family: 'hss2';
  src: url('../../fonts/hss2.eot');
  src: url('../../fonts/hss2.eot?#iefix') format('embedded-opentype'), url('../../fonts/hss2.woff') format('woff'),
    url('../../fonts/hss2.ttf') format('truetype'), url('../../fonts/hss2.svg#hss2') format('svg');

  font-weight: normal;
  font-style: normal;
}

[class*='icon-']:before {
  display: inline-block;
  font-family: 'hss2';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-hide-canceled:before {
  content: '\0041';
  font-size: 23px;
  color: $niceBlue;
}

.icon-filter:before {
  content: '\0042';
  font-size: 23px;
  color: $niceBlue;
}

.icon-plus-blue:before {
  content: '\0043';
  font-size: 19px;
  color: $niceBlue;
  margin: 2px 10px 0 -10px;
}

.icon-minus-blue:before {
  content: '\0058';
  font-size: 19px;
  color: $niceBlue;
  margin: 2px 10px 0 -10px;
}

.icon-legend:before {
  content: '\0044';
  font-size: 23px;
  color: $niceBlue;
}

.icon-show-canceled:before {
  content: '\0045';
  font-size: 23px;
  color: $niceBlue;
}

.icon-close-black:before {
  content: '\0046';
}

.icon-date-arrow-left {
  padding: 7px 0 2px 9px;
}

.icon-date-arrow-left:before {
  content: '\0047';
  font-size: 19px;
  color: $white;
}

.glyphicon-chevron-left:before {
  content: '\0047';
}

.icon-date-arrow-right {
  padding: 7px 9px 2px 0;
}

.icon-date-arrow-right:before {
  content: '\0048';
  font-size: 19px;
  _padding: 6px 7px 0px 0;
}

.glyphicon-chevron-right:before {
  content: '\0048';
}

.icon-date-black:before {
  content: '\0049';
}

.icon-drobdown-arrow1:before {
  content: '\004a';
  font-size: 14px;
  color: $white;
}

.icon-drobdown-arrow2:before {
  content: '\004b';
  font-size: 14px;
  color: $white;
}

.icon-duration:before {
  content: '\004c';
}

.icon-duration-big:before {
  content: '\004c';
  font-size: 23px;
}

.icon-duration-big:before {
  content: '\004c';
  font-size: 23px;
}

.icon-end:before {
  content: '\004d';
}

.icon-end-big:before {
  font-size: 23px;
  content: '\004d';
}

.icon-input-arrows-black:before {
  content: '\004e';
}

.icon-notification:before {
  content: '\0050';
  font-size: 23px;
  color: $niceBlue;
}

.icon-refreshmap:before {
  content: '\0051';
}

.icon-search:before {
  content: '\0052';
  font-size: 19px;
  color: $niceBlue;
}

.icon-search-white:before {
  content: '\0052';
  font-size: 19px;
  color: $white;
}

.icon-start:before {
  content: '\0053';
}

.icon-worker:before {
  content: '\0054';
  font-size: 12px;
  color: $black;
}

.icon-review:before {
  content: '\005A';
  font-size: 23px;
  color: $niceBlue;
}

.glyphicon-trash:before {
  content: '\004f';
  color: grey;
}

.glyphicon-calendar:before {
  content: '\0049';
  color: grey;
}

.glyphicon-time:before {
  content: '\e023';
}

.glyphicon-triangle-top:before {
  content: '\0055';
  color: grey;
}

.glyphicon-chevron-down:before {
  content: '\0055';
  color: $niceBlue;
}

.glyphicon-menu-down:before {
  content: '\0055';
  color: grey;
}

.glyphicon-triangle-bottom:before {
  content: '\0056';
  color: grey;
}

.glyphicon-chevron-up:before {
  content: '\0056';
  color: $niceBlue;
}

.glyphicon-menu-up:before {
  content: '\0056';
  color: grey;
}

.glyphicon-info-sign:before {
  content: '\0057';
  color: $niceBlue;
}

.glyphicon-minus-sign:before {
  content: '\0058';
  color: $red;
}

.glyphicon-star:before {
  content: '\0059';
}

.glyphicon-star-empty:before {
  content: '\005a';
}

.glyphicon-plus-sign:before {
  content: '\0043';
  color: $niceBlue;
}

.glyphicon-download-alt:before {
  content: '\e025';
  font-size: 19px;
  color: $niceBlue;
  margin: 2px 10px 0 -10px;
}

.glyphicon-download:before {
  content: '\e026';
}

.glyphicon-upload:before {
  content: '\e027';
}
