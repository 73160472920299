/**
 * For the correct positioning of the placeholder element, the dnd-list and
 * it's children must have position: relative
 */
ul[dnd-list], ul[dnd-list] > li {
    position: relative;
}

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop to it once it's empty
 */
ul[dnd-list] {
    min-height: 30px;
    padding-left: 0px;
    li {
      background-color: #337ab7;
      border: none;
      color: #fff;
      height: 30px;
      width: 30px;
    }
}

/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
ul[dnd-list] .dndDraggingSource {
    display: none;
}

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
ul[dnd-list] .dndPlaceholder {
    display: block;
    background-color: #ddd;
    min-height: 30px;
}


.block-row {
  display: inline-flex;
}
.block {
  height: 30px;
  width: 30px;
  border:1px solid;
}
