$mainColor: var(--main-color);
$highlightColor: var(--highlight-color);

//FROM calendar.scss
.top-left-tabbar {
  &.today-group {
    border-right: 1px solid $mainColor;

    label {
      color: $mainColor;
    }
  }

  &.calendar-group {
    border-left: 1px solid $mainColor;
    border-right: 1px solid $mainColor;
    background-color: $mainColor;

    label {
      background-color: $mainColor;
    }
  }

  &.timespan-group {
    border-left: 1px solid $mainColor;
    border-right: 1px solid $mainColor;

    label {
      &.active {
        border: 1px solid $mainColor;
        color: $mainColor;

        &:hover {
          border: 1px solid $mainColor;
        }
      }
    }
  }

  &.type-group {
    label {
      &.active {
        color: $mainColor;
        border-bottom: 3px solid $mainColor;
      }
    }
  }
}

.glyphicon {
  img {
    color: $mainColor;
  }
}

.secondary-filter {
  a.active {
    color: $mainColor;

    .glyphicon {
      color: $mainColor;
    }
  }

  .calendar-filters-left {
    .today-button {
      border-right: 1px solid $mainColor;

      label {
        color: $mainColor;
      }
    }

    .calendar-group-button {
      border-right: 1px solid $mainColor;

      @media (max-width: 580px) {
        border-right: none;
      }

      label {
        background: $mainColor !important;
      }
    }

    .timespan-button {
      border-right: 1px solid $mainColor;

      label.active {
        border: 1px solid $mainColor;
        color: $mainColor;
      }
    }
  }

  .glyph-link-right {
    border-left: 1px solid $mainColor;
  }
}

.third-filter {
  .btn.apply-filter {
    background: $mainColor;
  }

  .reset-filter {
    color: $mainColor;
  }
}

//from datetimepicker.scss

.datetimepicker {
  border-top: 2px solid $mainColor;
}

.datepicker-top:before {
  border-bottom: 10px solid $mainColor;
}

.datetimepicker .minute:hover,
.datetimepicker .hour:hover,
.datetimepicker .day:hover {
  background: $mainColor;
}

.datetimepicker td.active,
.datetimepicker td.active:hover,
.datetimepicker td.active.disabled,
.datetimepicker td.active.disabled:hover {
  background-image: $mainColor;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='$mainColor', GradientType=0);
  border-color: $mainColor;
}

.datetimepicker td.active:hover,
.datetimepicker td.active:hover:hover,
.datetimepicker td.active.disabled:hover,
.datetimepicker td.active.disabled:hover:hover,
.datetimepicker td.active:active,
.datetimepicker td.active:hover:active,
.datetimepicker td.active.disabled:active,
.datetimepicker td.active.disabled:hover:active,
.datetimepicker td.active.active,
.datetimepicker td.active:hover.active,
.datetimepicker td.active.disabled.active,
.datetimepicker td.active.disabled:hover.active,
.datetimepicker td.active.disabled,
.datetimepicker td.active:hover.disabled,
.datetimepicker td.active.disabled.disabled,
.datetimepicker td.active.disabled:hover.disabled,
.datetimepicker td.active[disabled],
.datetimepicker td.active:hover[disabled],
.datetimepicker td.active.disabled[disabled],
.datetimepicker td.active.disabled:hover[disabled] {
  background-color: $mainColor;
}

.datetimepicker span:hover {
  background: $mainColor;
}

.datetimepicker span.active,
.datetimepicker span.active:hover,
.datetimepicker span.active.disabled,
.datetimepicker span.active.disabled:hover {
  background-image: $mainColor;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='$mainColor', GradientType=0);
  border-color: $mainColor;
}

.datetimepicker span.active:hover,
.datetimepicker span.active:hover:hover,
.datetimepicker span.active.disabled:hover,
.datetimepicker span.active.disabled:hover:hover,
.datetimepicker span.active:active,
.datetimepicker span.active:hover:active,
.datetimepicker span.active.disabled:active,
.datetimepicker span.active.disabled:hover:active,
.datetimepicker span.active.active,
.datetimepicker span.active:hover.active,
.datetimepicker span.active.disabled.active,
.datetimepicker span.active.disabled:hover.active,
.datetimepicker span.active.disabled,
.datetimepicker span.active:hover.disabled,
.datetimepicker span.active.disabled.disabled,
.datetimepicker span.active.disabled:hover.disabled,
.datetimepicker span.active[disabled],
.datetimepicker span.active:hover[disabled],
.datetimepicker span.active.disabled[disabled],
.datetimepicker span.active.disabled:hover[disabled] {
  background-color: $mainColor;
}

.datetimepicker {
  table > thead > tr > th {
    color: $mainColor !important;
  }
}

//from loginScreen
.loginScreen {
  button.btn.btn-default {
    color: #fff;
    background-color: $highlightColor;
  }

  .forgot-password-link {
    color: $mainColor !important;
  }
}

//from modals
.modal-header {
  color: $mainColor;
  border-bottom: 14px solid $mainColor;

  &.provider-modal {
    background-color: $mainColor;

    .btn-success {
      background-color: $highlightColor;
    }
  }
}

//from navbars
.navbar {
  background-color: $mainColor;

  .active > a,
  .navbar-nav > li > a:hover {
    background-color: $highlightColor !important;
  }
  .navbar-nav > li.user-display-name > a:hover {
    background: none !important;
  }

  .open > a,
  .open a:focus {
    background-color: $mainColor !important;
  }
}

//from notifications
.notification-widget {
  border-top: 2px solid $mainColor;

  &:before {
    border-bottom: 10px solid $mainColor;
  }
}

//from pageHeader
.page-tittle-right {
  .notification-button {
    border-left: 1px solid $mainColor;
  }
}

//from reportingTabs
.key-stats {
  .header {
    background-color: $mainColor;
  }
}

//from tabs
.tabset {
  li.active > a {
    color: $mainColor !important;

    span {
      border-bottom: 2px solid $mainColor !important;
    }
  }
}

//from style
.btn.btn-success,
.btn.btn-primary {
  background-color: $mainColor;
}

.glyphicon.glyphicon-plus-sign {
  color: $mainColor;
}

//from sessionNotifications
.sessionDashboardIndex h3 {
  color: $highlightColor;
}

//from svgfonts
.icon-filter,
.icon-plus-blue,
.icon-minus-blue,
.icon-legend,
.icon-notification,
.icon-search,
.icon-show-canceled,
.icon-hide-canceled,
.icon-duration-big,
.icon-end-big,
.icon-review {
  &:before {
    color: $mainColor;
  }
}

//from tables
.scroll-table-header > th {
  background-color: $mainColor;
}

//from workerFilter
.worker-filter {
  .apply-filters {
    color: $mainColor;
  }
}

.schedule-step {
  outline-color: $highlightColor;
}

.theme-green .bs-datepicker-head {
  background-color: $mainColor !important;
}

.form-inline {
  .legend {
    font-size: 0.8em;
    padding-top: 10px;

    .legend-item:nth-child(2) {
      margin-left: 10px;
    }
  }
}

.form-inline.schedule-job {
  .scheduleOfferHolder {
    .schedulePercent {
      background: $highlightColor;
    }

    .offerPercent {
      background: $mainColor;
    }
  }

  .bundled-worker-selection {
    li {
      background: $mainColor;

      .bundled-worker-close {
        color: $mainColor;
      }
    }
  }
}

.steps-indicator {
  &:before {
    background-color: $mainColor;
  }

  li {
    &.editing a {
      color: $highlightColor;
    }

    &.done a:before {
      background-color: $mainColor;
    }

    &.editing a:before {
      background-color: $highlightColor;
    }
  }
}

.wizard-toggle {
  .btn-info {
    background-color: $mainColor;
  }
}

.mighty-picker-calendar__day--in-range {
  color: #fff;
  background: $highlightColor;
}

.mighty-picker-calendar__day--selected {
  background: $mainColor;
}

.clear-date-time {
  background: $mainColor;
}

//---------------------------------------------------------------------------

// new TimeReports tab timereports.html
#reportingTabs.tabset {
  li.active > a {
    span {
      border-bottom: 8px solid $mainColor !important;
    }
  }
}

.time-reports-tab-menu-items {
  .filter-section {
    .active {
      background: $mainColor;
      color: white;
    }
  }

  .btn-container {
    i {
      color: $mainColor;
    }
  }
}

#timereports-tab-table {
  .button {
    border-color: $mainColor;
    color: $mainColor;

    &:hover {
      color: white;
      background: $mainColor;
    }
  }

  .link {
    color: $mainColor;
  }

  .link:hover {
    font-weight: bolder;
  }

  td {
    &.rating-column {
      color: $mainColor;
    }

    .glyphicon.glyphicon-calendar:before {
      color: $mainColor !important;
    }
  }

  .tooltip.customClass .tooltip-inner {
    background-color: $mainColor;
  }

  .tooltip.customClass .tooltip-arrow {
    border-left-color: $mainColor !important;
    border-right-color: $mainColor !important;
    // border-top-color: $mainColor !important;
    // border-bottom-color: $mainColor !important;
  }

  .vertical-divider {
    background-color: $mainColor;
  }

  .time-picker-container {
    border-top: 2px solid $mainColor;

    .glyphicon-chevron-up:before,
    .glyphicon-chevron-down:before {
      color: $mainColor;
    }

    .time-btn {
      background-color: $mainColor;
    }
  }

  .time-interval-container {
    border-top: 2px solid $mainColor;

    .time-interval-units {
      border-bottom: 1px solid $mainColor;
    }

    .time-btn {
      background-color: $mainColor;
    }
  }
}

//---------------------------------------------------------------------------
// MODAL
//---------------------------------------------------------------------------

.modal-header.repeat-modal {
  h1 {
    color: $mainColor;
  }
}

.modal-body.repeat-modal {
  .container-fluid {
    .date-picker-container {
      .glyphicon-calendar:before {
        color: $mainColor;
      }
    }

    .time-picker-outer-container {
      .glyphicon-time:before {
        color: $mainColor;
      }

      .time-picker-container {
        border-top: 2px solid $mainColor;

        .glyphicon-chevron-up:before,
        .glyphicon-chevron-down:before {
          color: $mainColor;
        }

        .time-btn {
          background-color: $mainColor;
        }
      }
    }

    .button-container {
      .button.ok {
        background-color: $mainColor;
        color: white;
      }

      .button.ok:hover {
        color: $mainColor;
        background-color: white;
      }
    }

    .selected-worker-number-container:after {
      border-color: $mainColor transparent transparent transparent;
    }
  }
}

#time-picker-popup-container {
  .glyphicon-chevron-up:before,
  .glyphicon-chevron-down:before {
    color: $mainColor;
  }
}

//---------------------------------------------------------------------------
// MOBILE view
//---------------------------------------------------------------------------

.mobile-time-reports-tab-menu-items {
  .filter-section {
    .active {
      background: $mainColor;
      color: white;
    }
  }

  .btn-container {
    i {
      color: $mainColor;
    }

    &.verify-all {
      background-color: $mainColor;
    }
  }
}

.mobile-time-reports-table {
  .mobile-row {
    .mobile-row-item {
      &.rating {
        color: $mainColor;
      }

      &.calendar,
      &.clock {
        color: $mainColor;
      }
    }

    &.buttons {
      .verify-button.main-action {
        background-color: $mainColor;
      }

      .unverify {
        color: $mainColor;
      }

      .no-show-button:hover {
        cursor: pointer;
        color: $mainColor;
        text-decoration: underline;
      }
    }
  }
}

.link-button:hover {
  color: $mainColor;
}

.mobile-time-reports-change-modal,
.mobile-time-reports-table {
  .mobile-row {
    .mobile-row-item {
      &.rating {
        color: $mainColor;
      }

      &.clock {
        color: $mainColor;
      }
    }

    &.buttons {
      .button {
        &.verify {
          background-color: $mainColor;
        }
      }
    }

    .time-picker-container,
    .time-interval-container {
      .glyphicon-chevron-up:before,
      .glyphicon-chevron-down:before {
        color: $mainColor;
      }

      .time-btn {
        background-color: $mainColor;
      }
    }
  }
}

.container-fluid.adminmenu.dashboard.job-order-report-container {
  .date-picker-wrapper.col-sm-3.col-xs-12,
  .col-sm-3.col-xs-12.skill-filter,
  .col-sm-3.col-xs-12.smart-search {
    border-right: 1px $mainColor solid;

    .glyphicon {
      fill: $mainColor !important;
    }

    .glyphicon-calendar {
      &::before {
        fill: $mainColor !important;
        color: $mainColor !important;
      }
    }

    .icon-search-orange {
      fill: $mainColor !important;

      &::before {
        fill: $mainColor !important;
        color: $mainColor !important;
      }
    }
  }

  .col-sm-3.col-xs-12.switch-buttons {
    .fa.fa-eye,
    .fa.fa-eye-slash,
    .fa.fa-list {
      color: $mainColor !important;
    }
  }
}

.schedule-job {
  .button-container {
    .button-name {
      color: $mainColor;
    }
  }
}

.app-worker-skills {
  &-list {
    li {
      border-color: $mainColor !important;
      color: $mainColor !important;
    }
  }
}

.app-tabs-list-item.selected,
.app-tabs-list-item:hover {
  border-color: $mainColor !important;
  color: $mainColor !important;
}

.cal-week-view {
  .cal-header.cal-today {
    border-color: $mainColor !important;
    background-color: $highlightColor;
  }
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: $highlightColor;
}

.app-current-work-title {
  &-today {
    color: $mainColor;
  }

  &-selector {
    background: $mainColor;
  }

  &-period {
    div.active {
      color: $mainColor;
      border: 1px solid $mainColor !important;
    }
  }
}

.jobs-root-selector {
  li.active,
  li:hover {
    background-color: $mainColor !important;
  }
}

.app-worker-stats {
  &-item {
    &-value {
      color: $mainColor;
    }
  }
}

.provider-table-container {
  .worker-filter {
    ul {
      li {
        border-right: 1px solid $mainColor;
      }
    }
  }
}

.app-worker-page-profile-section-profile-data {
  .name-button-container {
    button {
      background: $mainColor !important;
    }
  }
}
