.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media only screen and (max-width: 768px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}

.mobile-tab-selector {
  margin-left: 5px;
  margin-right: 10px;

  .form-group {
    display: flex;
    justify-content: center;

    .select-wrapper {
      width: 100%;

      select {
        border: none;
        color: gray;
        padding: 0 35px 0 20px;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}
