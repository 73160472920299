.tab-container {
  padding: 0;
}

@media (max-width: 1175px) {
  .tab-container {
    padding: 10px;
  }
}

@media (max-width: 570px) {
  .tab-container {
    padding: 10px;
  }
}
