//all
.tab-menu-items {
  padding: 10px 0 20px 0;

  #sessionSearchForm {
    width: 100%;
  }

  .glyph-link {
    position: relative;
    top: -55px;
  }
}
.nav-tabs.nav-justified > li > a {
  @media (max-width: 991px) {
    height: 60px;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Price
.price-tab-body {
  min-height: 300px;
  border-bottom: 1px solid $lightGray;
}
////////////////////////////////////////////////////////////////////////////////
// pool
.pool-tab {
  .tab-menu-items {
    padding: 10px 0 20px 0;
    .glyph-link {
      @media (max-width: 767px) {
        padding: 20px 0 20px 0;
      }
    }
  }
}
.pool-providers {
  text-align: left;
  .checkbox-inline {
    margin: 0;
  }
  ul {
    list-style-type: none;
  }
}
////////////////////////////////////////////////////////////////////////////////
// escalations place tab
.default-option {
  input[type='checkbox'] {
    height: 18px;
    width: 18px;
  }
}
////////////////////////////////////////////////////////////////////////////////
// meeting place tab
.location-tab {
  select.form-control#locationFilter {
    height: 37px;
    min-width: 190px;
  }
  .address-display {
    padding-top: 10px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
    span.clickable {
      padding-left: 10px;
    }
    h4 {
      font-weight: 700;
    }
  }
}

.pending-invitations-icon {
  background: var(--main-color);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 0;
  text-align: center;
  position: relative;
  left: -10px;
  &:before {
    color: #fff !important;
    position: relative;
    top: 3px;
    font-size: 14px !important;
  }
}
