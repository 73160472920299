@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Open_Sans-normal-400.woff') format('woff');
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/Open_Sans-normal-700.woff') format('woff');
  unicode-range: U+0-10FFFF;
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../../fonts/glyphicons-halflings-regular.eot');
  src: url('../../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/glyphicons-halflings-regular.woff') format('woff'),
    url('../../fonts/glyphicons-halflings-regular.ttf') format('truetype'),
    url('../../fonts/glyphicons-halflings-regular.svg?glyphicons_halflingsregular') format('svg');
}
