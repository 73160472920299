.tab-selector-container {
    padding: 10px 0 25px 0;

    .tab-selector {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    background-color: transparent;
    font-weight: 400;
    padding: 0 20px 25px 0;

    .tab {
      position: relative;
      display: inline;
      padding: 0 20px 25px 0;

      .tab-item {
        padding: 17px 0;
      }
    }
  }
}

@media (max-width: 1175px) {
  .tab-selector-container {
    padding: 10px;
  }
}

@media (max-width: 570px) {
  .tab-selector-container {
    padding: 10px;
  }
}
