.branch-enterprise-container {
  padding: 25px 0;
  margin-left: 5px;

  .form-inline {
    .form-group {
      display: flex;
      flex-direction: row;
    }
  }
}

@media (max-width: 1175px) {
  .page-header {
    .page-tittle-left {
      padding: 0 10px;
    }
  }
  .branch-enterprise-container {
    padding: 25px 10px;
  }
}

@media (max-width: 570px) {
  .page-header {
    .page-tittle-left {
      padding: 0 10px;
    }
  }

  .branch-enterprise-container {
    padding: 25px 10px;

    .form-inline {
      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
      }
    }
  }
}

.user-popover-host {
  position: fixed !important;
  left: unset !important;
  right: 20px !important;

  .arrow {
    display: none;
  }
  .popover-content {
    padding: 0 !important;
  }

  @media (max-width: 1175px) {
    top: 20px !important;
  }
}

.user-popover {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 24px;
  background: #fff;

  .person-details {
    display: flex;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }
  }

  .userName {
    font-weight: bold;
    color: #000000cc;
    font-size: 20px;
    line-height: 23px;
  }

  .email {
    margin-top: 4px;
    color: #00000080;
    font-size: 18px;
  }

  .marketplace-info {
    text-align: left;
    font-weight: bold;
    color: #00000080;

    font-size: 14px;

    .marketplace-details {
      display: flex;
      align-items: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
  }

  button {
    margin-top: 24px;
    color: #00000099;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    padding: 12px 0;
  }
}
