////////////////////////////////////////////////////////////////////////////////
/* SESSION DASHBOARD*/
.sessionNotifications, .sessionQueue {
  padding-top:20px;
  padding-bottom:30px;
}
.sessionDashboardIndex h3{
  font-size:2rem;
  color: $lightBlue;
  font-weight: 400;
  padding-bottom:15px;
}
.sessionNotifications table tr th{
  border:none;
  padding: 11px 0px 11px 15px;
}
////////////////////////////////////////////////////////////////////////////////
/* Job Queue Links */
.jobQueueLinks {
  float:right;
  padding-top:5px;
}
.jobQueueLinks>a>.glyphicon-plus-sign {
  padding-right:5px;
}
.jobQueueLinks>a {
  padding-right:15px;
  font-weight:700;
  font-size:1.4rem;
  color: $black;
  text-decoration: none;
}

#searchGlyphcon {
  background-color: $gray;
  padding-top:2px;
}

// breakpoint session queue controls
@media (max-width: 767px) {
  select.form-control#queueFilter {
    width:100% !important;
  }
  .form-inline .input-group#sessionSearchForm {
    padding: 10px 0 0 0;
  }
  #sessionQueueForm{
    .glyph-link>a {
      padding-right: 15px;
    }
  }
}
@media (max-width: 991px) and (min-width: 768px){
  .form-inline .input-group#sessionSearchForm {
    padding-left:0;
  }
  #sessionQueueForm{
    .glyph-link>a {
      padding-right: 0;
    }
  }
}
