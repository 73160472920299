.worker-filter {
  padding-top: 0 !important;
  h4 {
    color: #2e2e2e;
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0;
  }
  button {
    outline: none !important;
  }

  #singleSelect.filter-list {
    display: inline-block;
    width: auto;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;

    li {
      vertical-align: top;
      padding: 0;
      margin: 20px 0 0 0;

      .form-field-wrapper {
        padding: 0;
        display: inline-block;

        label {
          vertical-align: middle;
          margin-bottom: 0;
        }

        input, textarea {
          width: auto;
          padding: 6px 12px;
          max-height: 34px;
          vertical-align: middle;
        }
        div[isteven-multi-select] {
          padding: 0;
          display: inline-block;
        }
        #singleSelect {
          display: inline-block;
          width: auto;
        }
      }

      .remove-icon {
        vertical-align: middle;
      }
    }
  }

  .apply-filters {
    background: none;
    color: $niceBlue;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
}

.provider-count {
  float:right;
  font-weight: 700;
}