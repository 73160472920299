@import '_angular-mighty-datepicker';
@import '_fonts';
@import '_colors';
@import '_style';
@import '_forms';
@import '_navbars';
@import '_modals';
@import '_tables';
@import '_sessionNotifications';
@import '_loginScreen';
@import '_individualJobScreen';
@import '_reportingTabs';
@import '_tabs';
@import '_adminTabs';
@import '_multi-select';
@import '_datetimepicker';
@import '_calendar';
@import '_angular-drag-and-drop';
@import '_slider';
@import '_pageheader';
@import '_notification';
@import '_footer';
@import '_svgfonts';
@import '_workerFilter';
@import '_dashboard';
@import '_adminmenu';
@import '_widgets';
@import '_apiLogsFilter';
@import '_timeReports';
@import '_timeReportsRepeatModal';
@import '_timeReportsMobile';
@import '_timeReportsChangeMobileModal';
@import '_dirMobileTabSelector';
@import '_schedule';
@import '_sweet-alert';
@import 'angular-slider';
@import 'browser-notification-modal';
@import 'worker';

// Analytics Module
@import 'analytics/new-reports.component';
@import 'analytics/line-chart.component';
@import 'analytics/tab-container';

// Shared Module
@import 'shared/branch-selector';
@import 'shared/filter-component';
@import 'shared/page-header';
@import 'shared/spinner';
@import 'shared/tab-header';
@import 'shared/tab-selector';
@import 'shared/tab-selector.container';
@import '_jobstab';
@import '_communications';

app-select {
  display: flex !important;
  align-items: center !important;
  height: 44px !important;
  flex-direction: column;
  flex-wrap: wrap;

  .mat-select-value {
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .mat-select-arrow {
    border: none !important;
  }

  .mat-select-trigger {
    visibility: hidden;
  }

  label {
    height: 44px;
    line-height: 44px;
    margin: 0;
    font-weight: 400;
  }
}

.mat-select-panel-wrap {
  min-width: 300px;
}
