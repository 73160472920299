.schedule-popover {
  visibility: hidden;
}

.calendar-wrapper {
  width: 100%;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    overflow: scroll;
  }
  @media (max-width: 1199px) {
    overflow: scroll;
  }
  .table {
    max-width: inherit;
  }

  #schedule-table-head {
    border-bottom: 1px solid $white;
  }

  //th.type-filter-label-column{
  //  width: 120px!important;
  //}

  &.hide-canceled {
    .timebar-canceled {
      display: none;
    }
  }
}

.session-updated {
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 5px,
    rgba(255, 255, 255, 0.15) 5px,
    rgba(255, 255, 255, 0.15) 10px
  );
}

.top-left-tabbar {
  position: relative;
  top: 18px;

  label.btn {
    width: 100px;
    border-radius: 0;
  }

  label:active {
    box-shadow: none;
  }

  .btn-default.active {
    background-color: $black;
    border: 1px solid $black;
    color: $white;
  }
  label.btn.page-ctrl {
    width: 26px;
    border: 1px solid $grayBorder;
    padding: 6px 0;
  }
  label.btn.calendar-ctrl {
    width: auto;
  }

  &.today-group {
    border-right: 1px solid $niceBlue;
    label {
      background: $white;
      border: none;
      color: $niceBlue;
    }
  }

  &.calendar-group {
    border-left: 1px solid $niceBlue;
    border-right: 1px solid $niceBlue;
    padding: 0 30px;
    height: 30px;
    border-radius: 20px;
    background-color: $niceBlue;
    position: relative;

    label {
      background-color: $niceBlue;
      color: $white;
      border: none !important;

      &.page-ctrl {
        position: absolute;
        width: 20px;
        height: 20px;
        padding: 0;
        top: 4px;

        &:first-child {
          left: 3px;
        }

        &:last-child {
          right: 3px;
        }
      }

      &.calendar-ctrl {
        height: 30px;
      }
    }
  }
  &.timespan-group {
    border-left: 1px solid $niceBlue;
    border-right: 1px solid $niceBlue;
    padding: 0 20px;

    label {
      background: $white;
      border: none;
      color: $gray;
      font-weight: 400;
      border-radius: 0;
      margin-left: 0 !important;

      &.active {
        border: 1px solid $niceBlue;
        border-radius: 20px !important;
        background: $white;
        color: $niceBlue;
        font-weight: 700;
        box-shadow: none;
        &:hover {
          border: 1px solid $niceBlue;
        }
      }

      &:active,
      &:hover {
        background-color: $white;
        box-shadow: none;
        margin-left: 0;
        border: none;
      }
    }
  }
  &.type-group {
    label {
      font-size: 16px;
      background: none;
      border: none;
      font-weight: 400;
      padding-bottom: 8px;

      &.active {
        background: none;
        color: $niceBlue;
        font-weight: 700;
        border: none;
        box-shadow: none;
        border-bottom: 3px solid $niceBlue;
      }
    }
  }
}

.main-filter {
  border-bottom: 1px solid #b3b5b7;
  padding-bottom: 15px;
}

.glyph-link {
  padding-top: 0;
}

.glyph-link > a {
  background: $white;
  border-radius: 15px;
  padding: 0 15px;
  font-weight: normal !important;
  font-size: 1.4rem;
  color: #8b8b8b !important;
  text-decoration: none;
  margin-left: 40px;
  display: inline-block;
  height: 30px;
  line-height: 30px;

  .glyphicon {
    padding: 0;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 850px) {
    margin-left: auto;
  }
}

.glyphicon {
  img {
    color: $niceBlue;
    font-size: 20px;
    right: 10px;
    top: -4px;
    position: relative;
  }
}

.secondary-filter {
  background: $white;
  padding: 10px 0;

  @media (max-width: 768px) {
    height: 105px;
  }

  @media (max-width: 425px) {
    height: 150px;
  }

  a.active {
    color: $niceBlue;
    .glyphicon {
      color: $niceBlue;
    }
  }

  .datetimepicker {
    right: 0;
  }

  .calendar-filters-left {
    .datetimepicker {
      left: 0;
      width: 50%;

      @media (min-width: 1440px) {
        left: 90px;
      }

      @media (max-width: 1024px) {
        left: 90px;
      }

      @media (max-width: 768px) {
        left: 70px;
        width: 50%;
      }

      @media (max-width: 425px) {
        width: 100%;
        left: 0;
      }
    }

    @media (max-width: 1024px) {
      border-bottom: 1px solid $lightGray;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .today-button {
      float: left;
      border-right: 1px solid $niceBlue;
      width: 10%;
      text-align: center;
      padding-right: 20px;

      @media (max-width: 580px) {
        display: none;
      }

      label {
        background: none;
        border: 0;
        color: $niceBlue;
        font-weight: normal;
      }

      label:active {
        box-shadow: none;
      }
    }

    .calendar-group-button {
      float: left;
      border-right: 1px solid $niceBlue;
      width: 50%;
      text-align: center;
      padding: 0 20px;
      text-align: center;

      @media (max-width: 580px) {
        padding: 0 20px 0 0;
      }

      @media (max-width: 480px) {
        width: 100%;
        border-right: 0px solid $niceBlue;
        padding: 0;
      }

      label {
        background: $niceBlue;
        border: 0;
        color: $white;
      }

      .btn-left {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        text-align: left;
        width: 20%;
      }

      .btn-center {
        padding-bottom: 7px;
        width: 60%;
      }

      .btn-right {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        text-align: right;
        width: 20%;
      }

      //label.calendar-ctrl{
      //  width: 70%!important;
      //}
    }

    .timespan-button {
      float: left;
      border-right: 1px solid $niceBlue;
      width: 40%;
      text-align: center;

      @media (max-width: 580px) {
        width: 50%;
      }

      @media (max-width: 480px) {
        display: none;
      }

      @media (max-width: 1024px) {
        border-right: 0px solid $niceBlue;
      }

      label {
        padding-left: 29px;
        padding-right: 29px;
        height: 33px;
        display: inline-block;
        background-color: transparent;
        border-radius: 30px;
        color: #fff;
        text-align: center;
        line-height: 22px;
        font-size: 12px;
        box-shadow: none;
        border: 1px solid transparent;
        color: $gray;
        margin: 0 10px;
        font-weight: normal;
      }

      label.active {
        border: 1px solid $niceBlue;
        color: $niceBlue;
      }
    }
  }

  .free-text-search {
    padding: 0;
    display: inline;
    position: relative;

    @media (max-width: 768px) {
      padding: 0 0 0 20px;
    }

    @media (max-width: 425px) {
      width: 100%;
      padding: 0 20px 10px 20px;
      display: block;
    }

    span {
      margin: 3px 0 0 0;
    }

    #sessionSearchInput {
      border: none;
    }

    #sessionSearchInput:focus {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }

  .glyph-link-right {
    float: right;
    border-left: 1px solid $niceBlue;
    padding: 5px 10px;

    @media (max-width: 580px) {
      border-left: none !important;
    }

    img {
      width: 27px;
    }
    .pause-job {
      img {
        width: unset ;
        vertical-align: top;
        cursor: pointer;
        color: #23527c;
      }
    }
    @media (max-width: 425px) {
      border-left: 0;
      border-top: 1px solid $lightGray;
      display: block;
      float: none;
      padding-top: 15px;
    }

    a {
      margin: 0 10px;
      padding: 5px;
    }
  }
}

.calendar-filters-right {
  padding: 0;
}

.third-filter {
  min-height: 100px;
  background-color: $white;
  color: $black;
  border-top: 1px solid #c5c5c5;

  .btn.apply-filter {
    border-radius: 20px;
    background: $niceBlue;
    color: $white;
    font-weight: 400;
  }

  .reset-filter {
    color: $niceBlue;
    font-weight: 400;
    position: relative;
    top: 1px;
    margin-left: 20px;
  }

  .feature-filter {
    display: inline-block;
    width: 200px;
    padding: 15px 10px;

    textarea {
      height: 34px;
      margin-bottom: -12px;
    }
  }
  .glyph-link {
    vertical-align: top;
    text-align: right;
    color: $white;

    a {
      color: $white;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
.type-filter {
  min-width: 150px;
  vertical-align: initial;
}

.fixed-time-display {
  font-size: 0.8rem;
}

.day-time > th {
  font-size: 0.1rem;
}

////////////////////////////////////////////////////////////////////////////////
// legend popover

.popover.top .arrow:after {
  border-top-color: #edf2f5;
}

.popover.left .arrow:after {
  border-left-color: #edf2f5;
}

.popover.right {
  top: 0;
  margin-left: 10px !important;
  background: transparent !important;
  padding: 0;

  .arrow {
    right: -100px !important;
  }
  .arrow:after {
    border-right-color: black;
  }
  &.Completed .arrow:after {
    border-right-color: $completed;
  }
  &.Open .arrow:after {
    border-right-color: $open;
  }
  &.Pending .arrow:after {
    border-right-color: $pending;
  }
  &.Committed .arrow:after {
    border-right-color: $committed;
  }
  &.Active .arrow:after {
    border-right-color: $active;
  }
  &.Canceled .arrow:after {
    border-right-color: $canceled;
  }
  &.overlap .arrow:after {
    border-right-color: $black;
  }
}

.popover.left {
  top: 0;
  margin-left: -10px !important;
  background: transparent !important;
  padding: 0;

  .arrow {
    right: -10px;
  }
  .arrow:after {
    border-left-color: black;
  }
  &.Completed .arrow:after {
    border-left-color: $completed;
  }
  &.Open .arrow:after {
    border-left-color: $open;
  }
  &.Pending .arrow:after {
    border-left-color: $pending;
  }
  &.Committed .arrow:after {
    border-left-color: $committed;
  }
  &.Active .arrow:after {
    border-left-color: $active;
  }
  &.Canceled .arrow:after {
    border-left-color: $canceled;
  }
  &.overlap .arrow:after {
    border-left-color: $black;
  }
}

.popover.right .popover-content {
  position: absolute;
  left: 0px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.22);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.22);
}

.schedule-popover {
  .popover-content {
    padding: 0;
    position: absolute;
    left: -249px;
    top: -28px;
    background: white;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.22);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.22);
  }
}

.timetable-popover,
.job-order-popover {
  .popover-content {
    padding: 0;
    position: absolute;
    background: white;
    left: -249px;
    top: -28px;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.22);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.22);
  }
}

.calendar-legend {
  li {
    cursor: pointer;
  }
  .completed {
    background-color: $completed;
  }
  .open {
    background-color: $open;
  }
  .pending {
    background-color: $pending;
  }
  .committed {
    background-color: $committed;
  }
  .active {
    background-color: $active;
  }
  .overlap {
    background: repeating-linear-gradient(
      0deg,
      rgb(165, 42, 42),
      rgb(165, 42, 42) 4px,
      rgba(0, 0, 0, 0.498039) 4px,
      rgba(0, 0, 0, 0.498039) 8px
    );
  }
  .canceled {
    background-color: $canceled;
  }
  ul {
    padding-left: 10px;
    font-size: 1.6rem;
    li {
      padding: 5px 0;
      list-style-type: none;
    }
  }
  .input-color .color-box {
    width: 13px;
    height: 13px;
    display: inline-block;
  }
  .session-details {
    h1 {
      font-size: 1.2rem;
    }
  }
  hr {
    border-color: $black;
  }
}

.popover {
  //border: none;
  //box-shadow: none;
  //padding: 0 !important;
  //margin: 0 !important;
  //width: 120px;
  //height: auto !important;
  //background: transparent;
  //background: white;
  position: absolute;
  top: -100px;
  //left: 5px
  //width: 169px;
  //height: 100px!important;

  .calendar-action {
    padding: 0;
    margin: 0;
    width: 248px;
    //background: $white;
    //border-left: 1px solid #F6F6F6;
    height: 100% !important;
    background-color: #ffffff;
    //border: 1px solid red;

    ul {
      background: $newGray;
      padding: 0;
      margin: 0;
    }
    li {
      width: 100%;
      padding: 0;
      padding-left: 10px;
      border-bottom: 1px solid $white;
      font-size: 14px;
      height: 34px;
      line-height: 34px;
    }

    .session-details {
      background: $white;
      margin: 0;
      margin-bottom: 18px;

      table {
        margin-top: 18px;
      }

      .calendar-action-icon {
        padding-left: 14px;
        padding-bottom: 8px;
        width: 1px;
      }

      .calendar-action-title {
        padding-left: 15px;
        color: #999;
        text-align: right;
        vertical-align: top;
      }

      .calendar-action-value {
        text-align: left;
        padding-left: 10px;
        vertical-align: top;
      }
      .calender-action-all-day-text {
        margin-top: 6px;
        text-align: center;
        color: #999;
        font-weight: bold;
      }
    }
  }

  &.right {
    .calendar-action {
      &.Committed {
        border-left: 2px solid $committed;
      }

      &.Canceled {
        border-left: 2px solid $canceled;
      }

      &.Open {
        border-left: 2px solid $open;
      }

      &.Pending {
        border-left: 2px solid $pending;
      }

      &.Active {
        border-left: 2px solid $active;
      }

      &.Completed {
        border-left: 2px solid $completed;
      }

      &.overlap {
        border-left: 2px solid $black;
      }
    }
  }

  &.left {
    .calendar-action {
      &.Committed {
        border-right: 2px solid $committed;
      }

      &.Canceled {
        border-right: 2px solid $canceled;
      }

      &.Open {
        border-right: 2px solid $open;
      }

      &.Pending {
        border-right: 2px solid $pending;
      }

      &.Active {
        border-right: 2px solid $active;
      }

      &.Completed {
        border-right: 2px solid $completed;
      }

      &.overlap {
        border-right: 2px solid $black;
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// Modal
.schedule-modal {
  text-align: center;
  select#singleSelect {
    display: inline;
    width: 50%;
  }
}

/////////////////////////////////////////////////////////////////////////////
#schedule-table {
  background: $white;
}

.column-right-details {
  float: right;
  width: 150px;
  height: 30px;
}

table.timeline-schedule-table {
  table-layout: fixed !important;
  width: 100% !important;

  .cal-provider-col {
    float: left;
    margin-top: 6px;
  }
  .cal-provider-picture-wrapper {
    padding-top: 5px;
    position: absolute;
    left: 30px;
    top: -2px;
    z-index: 10;

    cursor: pointer;
  }

  .cal-provider-picture-wrapper-left {
    //width: 40px;
    //position: relative;
    //padding-right: 9px;
    //height: 20px;
    //display: inline-block;
    //background-color: transparent;
    //border-radius: 30px;
    //color: #fff;
    //text-align: left;
    //box-shadow: none;
    //border: 1px solid $white;
    //margin: 12px 51px 0 12px;

    .right-user {
      width: 40px;
      height: 20px;
      display: inline-block;
      background-color: transparent;
      border-radius: 30px;
      color: #fff;
      text-align: left;
      box-shadow: none;
      border: 1px solid #fff;
      margin: 8px 0 0 12px;
      top: 0px;
      left: -16px;
      padding: 0 3px;
      font-size: 11px;
      color: black;
    }

    .right-user:before {
      position: relative;
      top: 3px;
      left: 2px;
    }
  }

  img.cal-provider-img {
    width: 35px;
    height: 35px;
    border: 1px solid $calProvBorder;
    background: #e5e5e5;
  }

  //day
  .type-filter-label-column,
  #time-table-label-column {
    width: 120px !important;
    position: relative;

    @media (max-width: 1670px) {
      width: 160px !important;
    }

    @media (max-width: 1440px) {
      width: 160px !important;
    }

    @media (max-width: 1310px) {
      width: 190px !important;
    }

    @media (max-width: 1280px) {
      width: 190px !important;
    }

    @media (max-width: 1170px) {
      width: 250px !important;
    }

    @media (max-width: 1024px) {
      width: 250px !important;
    }

    @media (max-width: 425px) {
      width: 280px !important;
    }

    .type-filter-label {
      position: absolute;
      bottom: 5px;
      left: 8px;
      font-size: 1.2rem;
      font-weight: 400;
    }

    #month-display {
      float: left;
      font-weight: 700;
      position: relative;
      font-size: 18px;
    }

    #month-display.day {
      top: -15px;
    }

    #month-display.week {
      top: -15px;
    }
  }

  .parent-row > td:first-child {
    padding: 5px 5px 5px 10px;
    background-color: $newGray;
    border-top: 1px solid $white !important;
    border-bottom: 1px solid $white !important;
    position: relative;

    .glyphicon-triangle-bottom {
      position: absolute;
      bottom: 0px;
      right: -17px;
      transform: rotate(-135deg);
      color: $white;
    }

    .glyphicon-triangle-top {
      position: absolute;
      bottom: -7px;
      right: 13px;
      transform: rotate(135deg);
      color: $white;
    }

    .provider-details {
      margin-top: 5px;
      //display: inline-block;
      //max-width: 130px;
      //word-wrap: break-word;
      //color: #ADA185;
      //margin-left: 77px;

      //.glyphicon.glyphicon-user {
      //  color: $gray;
      //}
      //
      //.glyphicon-user:before {
      //  position: absolute;
      //  top: 3px;
      //}
      //
      //.provider-fixed-placeholder {
      //  height: 3rem;
      //}
      b {
        font-weight: normal;
        color: $calProvBorder;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
      }
      b:nth-child(1),
      span + b {
        color: $timlinename;
      }

      .total-minutes {
        color: #333 !important;
      }
    }
    .job-details {
      display: inline-block;
      max-width: 150px;
      word-wrap: break-word;
      color: #ada185;

      &.interest {
        max-width: unset;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        br {
          display: none;
        }
        dir-apply-indicator {
          flex-basis: 100%;
        }

        & > div {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
        }
      }
    }
    .sorting-glyph {
      float: right;
      display: inline-block;
    }
  }
  #time-table-label-column {
    font-size: 1.4rem;
    width: 99%;
  }

  .timeline-container {
    height: 100%;
    position: relative;
    overflow: hidden;

    .overlap-block:focus,
    dir-time-bar-display:focus {
      outline: none;
    }
  }
  tbody > tr:not(.summary-row) > td {
    height: 60px;
    padding: 0;
    border: 1px solid #ddd !important;
    .timeline-rect {
      width: 35px;
      height: 35px;
      background-color: #ccc;
      position: absolute;
    }
  }
  .child-row > td:first-child {
    padding: 5px 5px 5px 10px;

    .job-details {
      display: inline-block;
      max-width: 175px;
      padding-left: 0;
      word-wrap: break-word;

      &.interest {
        max-width: unset;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        br {
          display: none;
        }
        dir-apply-indicator {
          flex-basis: 100%;
        }

        & > div {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
        }
      }
    }
    .contractor-glyph {
      display: inline-block;
      min-width: 18px;
      min-height: 20px;
      vertical-align: top;
    }
    .provider-details {
      //display: inline-block;
      //max-width: 130px;
      word-wrap: break-word;
      .glyphicon.glyphicon-user {
        color: $gray;
      }

      b {
        font-weight: 400;
      }
    }
  }
  .week-view-label {
    padding: 11px 0;
    width: 110px;

    .day-label {
      padding-left: 5px;
      font-size: 18px;
    }
  }
  .day-view-label {
    padding: 8px 0px 2px 0;
    width: 185px;
    .day-label {
      padding-left: 5px;
      font-size: 18px;
    }
    .time-hash-marks {
      text-align: justify;
      padding: 0 15px;
      font-size: 13px;
      color: #545454;
      font-weight: 400;
      line-height: 0;
      padding-top: 10px;
      @media (max-width: 1260px) {
        font-size: 9px;
      }
      span {
        width: 100%;
        display: inline-block;
      }

      &:after {
        content: '|';
        left: -17px;
        top: -3px;
        position: relative;
        color: #545454;
      }

      &.first {
        &:after {
          content: none;
        }
      }
    }
  }
  .time-hash-marks {
    font-size: 13px;
    line-height: 0;
    font-weight: 400;
    text-align: justify;
    padding: 0 10px;
    padding-top: 10px;

    &:after {
      content: '|';
      left: -12px;
      top: -3px;
      position: relative;
      color: #545454;
    }
    &.first {
      &:after {
        content: none;
      }
    }
    @media (max-width: 1600px) {
      font-size: 9px;
    }

    span {
      width: 100%;
      display: inline-block;
    }
  }
}

.providers-week {
  padding: 5px 5px 5px 10px !important;

  .cal-provider-picture-wrapper {
    top: 7px !important;
  }

  .provider-details {
    b {
    }
  }
}

.spinner#scheduleSpinner {
  top: 35px;
}

.timebar-container {
  position: relative;
}

img.logged-in-state {
  border-left: 2px solid $green !important;
  border-top: 2px solid $green !important;
}

img.logged-out-state {
  border-left: 2px solid $yellow !important;
  border-top: 2px solid $yellow !important;
}

img.no-logged-in-state {
  border-left: 2px solid $red !important;
  border-top: 2px solid $red !important;
}

img.vetting-approved-state {
  border-right: 2px solid $green !important;
  border-bottom: 2px solid $green !important;
}

img.vetting-waiting-state {
  border-right: 2px solid $yellow !important;
  border-bottom: 2px solid $yellow !important;
}

img.vetting-denied-state,
img.vetting-no-state {
  border-right: 2px solid $red !important;
  border-bottom: 2px solid $red !important;
}

.loggedin-state-legend {
  width: 140px;
  height: 25px;
  color: $black;
  ul {
    padding-left: 10px;
    font-size: 1.2rem;
    li {
      padding: 5px 0;
      list-style-type: none;
    }
  }
  .logged-in {
    background-color: $green;
  }
  .logged-out {
    background-color: $yellow;
  }
  .no-data {
    background-color: $red;
  }
  .input-color .color-box {
    width: 13px;
    height: 10px;
    display: inline-block;
  }
}

.provider-canceled,
.provider-open,
.provider-pending,
.provider-waiting,
.provider-interest {
  .provider-details {
    margin-left: 0 !important;
  }

  .cal-provider-col {
    display: none;
  }

  .column-right-details {
    float: left;
    width: 150px;
    height: 30px;
  }
}

.parent-row {
  &.provider-canceled {
    background: #fef3ef;

    td:first-child {
      background: $canceled!important;
      .provider-details {
        b {
          color: $white !important;
        }
      }
    }
  }

  &.provider-open {
    background: #fff9eb;

    td:first-child {
      background: $open !important;
      .provider-details {
        b {
          color: $white !important;
        }
      }
    }
  }

  &.provider-pending {
    background: #e8eef4;

    td:first-child {
      background: $pending !important;
      .provider-details {
        b {
          color: $white !important;
        }
      }
    }
  }

  &.provider-interest {
    background: #e8eef4;

    td:first-child {
      background: $interest !important;
      .provider-details {
        b {
          color: $white !important;
        }
      }
    }
  }

  &.provider-waiting {
    background: #e8eef4;

    td:first-child {
      background: $waiting !important;
      .provider-details {
        b {
          color: $white !important;
        }
      }
    }
  }
}

.summary-row {
  .total-workers-hours {
    background: #1897b6;
    color: white;
    font-size: 12px;
  }

  .data-column {
    font-size: 12px;
    text-align: center;
  }
}

.child-row {
  &.provider-canceled {
    background: #fef3ef;
  }

  &.provider-open {
    background: #fff9eb;
  }

  &.provider-pending {
    background: #e8eef4;
  }
  &.provider-interest {
    background: #e8eef4;
  }
}

.legend-row {
  background: $white;
  border-top: 1px solid $lightGray;
  min-height: 50px;
  font-size: 1.4rem;

  .legend-title {
    font-weight: 700;
    margin-left: 16px;
  }

  .legend-item {
    display: inline-block;
    width: 110px;
    height: 25px;
    text-align: center;
    border-radius: 20px;
    line-height: 25px;
    color: $white;
    margin-top: 12px;
    margin-left: 10px;

    &.committed {
      background: $committed;
    }

    &.open {
      background: $open;
    }

    &.pending {
      background: $pending;
    }

    &.active {
      background: $active;
    }

    &.completed {
      background: $completed;
    }

    &.canceled {
      background: $canceled;
    }

    &.overlap {
      background: $overlap;
    }

    &.interest {
      background: $interest;
    }

    &.waiting {
      background: $waiting;
    }
    
    &.paused {
      background: $paused;
    }

  }
}

.icon-drobdown-pos {
  position: absolute;
  right: 5px;
  top: 40px;
}

dir-time-bar-display {
  height: 99%;
  position: absolute;
  border-radius: 5px;
}

.fixed {
  .row {
    margin: 0;
  }
}

.calendar-jobs-view {
  .cal-provider-img {
    display: none;
  }
}

.schedule-step {
  width: 16.6%;
  display: inline-block;
  height: 100%;
  position: relative;
  z-index: 1;
}

.schedule-step-week {
  width: 25%;
  display: inline-block;
  height: 100%;
  position: relative;
  z-index: 1;
}

#time-table-body {
  td {
    position: relative;
  }
}

.schedule-step-list {
  position: absolute;
  width: 100%;
  height: 100%;
}

.apply-indicator {
  display: flex;
  justify-content: space-between;

  .total-workers {
    font-weight: bold;
  }
}

.bundled-job-indicator {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100px;
  text-align: right;

  span {
    display: block;
    margin-right: 45px;
    height: 16px;

    &.current-workers {
      color: #fa0;
    }

    &.total-workers {
      color: $black;
    }
  }

  .pie-chart {
    $c2: #fa0; // active color

    width: 30px;
    height: 30px;
    top: 5px;
    right: 5px;
    margin: auto;
    position: absolute;
    background: $pending linear-gradient(to right, $pending 50%, $c2 50%);
    color: $c2;
    border-radius: 50%;

    @keyframes spin {
      to {
        transform: rotate(180deg);
      }
    }

    @keyframes background {
      50% {
        background-color: currentColor;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 60%;
      height: 60%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      border-radius: 50%;
      background: transparent;
    }
    &::before {
      content: '';
      position: absolute;
      display: block;
      background-color: inherit;
      height: 100%;
      width: 50%;
      bottom: 0;
      right: 0;
      border-radius: 0 100% 100% 0 / 50%;
      transform: rotate(0);
      transform-origin: 0 50%;
      animation: 50s spin infinite linear, 100s background infinite step-end;
      animation-play-state: paused;
      animation-delay: inherit;
    }
  }
}
