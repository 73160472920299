.toggle-button {
  border: 1px solid var(--main-color);
  border-radius: 43px;
  height: 55px;
  padding-left: 25px;
  padding-right: 32px;
  display: flex;
  align-items: center;
  margin-right: 24px;
  background: #eaeef1;
  cursor: pointer;

  &.selected {
    background: var(--main-color-light);
  }

  .text-holder {
    margin-left: 19px;
    text-align: center;
    font-size: 16px;
    color: #363636;

    .text-label {
      overflow: hidden;
      height: 22px;
      font-weight: 400;
      @media (max-width: 1650px) {
        height: 20px;
        font-size: 14px;
      }
    }

    .text-status {
      font-weight: 700;
    }
  }

  .toggle {
    width: 34px !important;
    height: 20px !important;
    border-radius: 31px;

    &.btn-primary {
      background: var(--main-color) !important;

      .toggle-group {
        right: -15px;
        top: -1px;
        width: 144%;
      }
    }

    &.off {
      background: #7c7c7c !important;

      .toggle-group {
        left: -22px;
        top: -2px;
      }
    }

    .toggle-on,
    .toggle-off {
      display: none !important;
    }

    .toggle-handle {
      width: 18px !important;
      height: 18px !important;
      background: #fff !important;
      padding: 0;
      margin: 0;
      border-radius: 50%;
      opacity: 1;
    }
  }
}
