@import '_colors';

.modal-dialog {
  z-index: 1000;
}

.clear {
  clear: both;
}

button:focus {
  outline: none;
}

@media only screen and (max-device-width: 768px) {
  .modal-open {
    // block scroll for mobile;
    // causes underlying page to jump to top;
    // prevents scrolling on all screens
    overflow: hidden;
    position: fixed;
    width: 100%;
    -webkit-overflow-scrolling: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.spinner {
  position: relative;
  top: 80px;
  z-index: 100;
}

.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.angular-google-map-container {
  height: 482px;
}

html {
  height: 100%;
}

body {
  font-family: 'Helvetica', sans-serif;
  background: $lightGrayBackground;
  overflow-y: scroll;
  height: 100%;

  &.popover-open {
    .main-body-container {
      height: 100%;
    }
  }

  .main-body-container {
    background: $lightGrayBackground;
    padding: 0px;
    //min-height: 1100px;
    //margin: 0 15px;

    @media (max-width: 736px) {
      margin: 0;
    }
  }
}

.spinner-disable-background {
  opacity: 0.7;
  pointer-events: none;
}

h3 {
  font-weight: 700;
}

img.img-circle {
  width: 100px;
  height: 100px;
}

.img-circle.blank-avatar {
  background-color: $lightGray;
}

.clickable:hover {
  cursor: pointer;
}

.logout-img {
  width: 14px;
  height: 14px;
}

////////////////////////////////////////////////////////////////////////////////
/* LINKS */
/* unvisited link */
a:link {
  color: $skyBlue;
}

/* visited link */
a:visited {
}

/* mouse over link */
a:hover {
  text-decoration: none;
}

/* selected link */
a:active {
}

a.navbar-brand {
  padding-top: 5px;
  padding-bottom: 5px;

  img {
    float: left;
  }

  h1 {
    float: right;
    font-size: 22.1px;
    font-weight: 400;
    margin-top: 20px;
    padding-left: 20px;
  }
}

////////////////////////////////////////////////////////////////////////////////
/* BUTTONS */
.btn.btn-default {
  color: $black;
  background-color: $lightGray;
  font-weight: 700;

  &:focus,
  &:active {
    background-color: transparent;
  }
}

.btn.btn-primary {
  color: $white;
  background-color: $niceBlue;
  font-weight: 700;
  border-radius: 20px;
  border: none;
}

.btn.btn-success {
  color: $white;
  background-color: $niceBlue;
  font-weight: 700;
  border-radius: 20px;
}

.btn.btn-danger {
  color: $white;
  background-color: $red;
  font-weight: 700;
  border-radius: 20px;
}

.btn.btn-warning {
  color: #fff;
  background-color: #fff;
  font-weight: 700;
  border-color: #e4c341;
  border-radius: 0;
  display: inline-block !important;
  /* width: auto !important; */
  position: absolute;
  top: 9px;
  right: 70px;
  border: 0;
  padding: 0;
  width: 27px;
  height: 27px;
}

.live-indicator {
  width: 27px;
  height: 27px;
  display: inline-block;
  background: #fff;
  padding: 0;
  position: absolute;
  top: 9px;
  right: 27px;
}

.reporting-map {
  top: 10px;
  height: 31px;
}

button.map-auto-fit {
  margin-left: 25px;
}

button.map-auto-fit:hover {
  background-color: lightGray;
}

button.map-auto-fit:focus {
  outline: none;
}

////////////////////////////////////////////////////////////////////////////////
/* PAGE HEADER */
.page-header {
  margin: 0;
  margin-right: 10px;
  padding: 0;
}

.page-header h1 {
  margin-top: 30px;
  border: 0 none;
  font-size: 3.6rem;
  font-weight: 400;
  // @media (max-width: 767px) {font-size: 3.1rem;}
}

.page-header small span {
  float: right;
  padding-top: 12px;
  padding-bottom: 10px;
  font-size: 1.8rem;
  color: $darkGray;

  @media (max-width: 767px) {
    display: none;
  }
}

.page-header span#date-time {
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 1.4rem;
  color: $darkGray;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

////////////////////////////////////////////////////////////////////////////////
/* PAGINATION STYLING */
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover {
  background-color: #f1f5f8 !important;
  color: #424a54 !important;
  border-left: none;
  border-right: none;
  border-color: $lightGrayBorder !important;
}

.pagination > li > a {
  color: #424a54 !important;
  background-color: $white !important;
  border-color: $lightGrayBorder !important;
  font-size: 1.4rem;
  width: 34px;
  height: 34px;
  padding: 6px 12px;
}

.pagination > li:first-child > a,
.pagination > li:last-child > a {
  color: black !important;
  background-color: $white !important;
  font-weight: normal;
  width: 65px;
  height: 34px;
}

::-moz-selection {
  background-color: $lightGrayBorder !important;
  text-shadow: none;
}

::selection {
  background-color: $lightGrayBorder !important;
  text-shadow: none;
}

.directionLinks {
  background-color: red;
}

.pageControls {
  text-align: center;
}

////////////////////////////////////////////////////////////////////////////////
/* Glypicon */
.glyphicon {
  top: 3px;
  padding: 0 3px;
}

.glyphicon.glyphicon-search {
  color: $white;
}

.glyphicon.glyphicon-plus-sign {
  color: $niceBlue;
  font-size: 20px;
  right: 9px;
  top: 5px;
}

.glyphicon.glyphicon-minus-sign {
  color: $red;
}

.glyph-link {
  float: right;
  padding-top: 10px;
  margin-right: 0 !important;

  @media (max-width: 630px) {
    padding: 30px 0 15px 0;
  }
}

.glyph-link > a > .glyphicon-plus-sign {
  padding-right: 5px;
}

.glyph-link > a {
  padding-right: 15px;
  font-weight: 700;
  font-size: 1.4rem;
  color: $black;
  text-decoration: none;

  @media (max-width: 425px) {
    margin: 3px 0;
  }
}

.glyphicon-remove-sign {
  color: $red;
}

.instance-count-glyph-btns {
  display: inline-block;

  button {
    opacity: 100;
    font-size: 21px;
    font-weight: 700;
    -webkit-appearance: none;
    cursor: pointer;
    background: 0 0;
    border: 0;
    padding: 0;

    &.confidential-button {
      position: relative;
      bottom: 10px;
    }
  }
}

@media (max-width: 480px) {
  .row {
    margin-left: -15px;
    margin-right: 0;
  }
}

.login_status_0,
.login_status_1,
.login_status_2,
.vetting_status_0,
.vetting_status_1,
.vetting_status_2 {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 5px;
}

.login_status_0,
.vetting_status_100 {
  background: yellow;
}

.login_status_1,
.vetting_status_0,
.vetting_status_1,
.vetting_status_2 {
  background: green;
}

.login_status_-1,
.vetting_status_101 {
  background: red;
}
