.mobile-time-reports-change-modal {
  background-color: white;

  .modal-container {
    width: 90%;
    margin: 0 auto;

    .mobile-row {
      width: 100%;
      padding: 5px 0 13px 0;

      &.rating-row {
        min-height: unset !important;
      }

      .mobile-collapse {
        visibility: collapse;
      }

      &.odd {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 55px;

        &.combined {
          border: 1px solid lightgray;
          border-radius: 3px;
          padding: 10px;

          .value {
            font-weight: bold;
          }
        }
      }

      

      .mobile-row-item {
        display: flex;
        flex-direction: row;

        .icon-plus-blue {
          &:before {
            margin-right: -5px !important;
          }
        }

        &.break-content {
          flex-grow: 1;
        }

        &.column {
          flex-direction: column;
        }

        &.time {
          display: flex;
          flex-direction: column;
        }

        &.break {
          flex-grow: 0;
        }

        &.clock,
        &.calendar {
          width: 14px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex-grow: 1;
          align-items: flex-end;

          .glyphicon {
            height: 14px;
            top: 0;
            padding: 0;
          }
        }

        &.worker-name {
          font-size: 18px;
          font-weight: bold;
          color: gray;
        }

        .title {
          font-size: 12px;
          color: gray;
        }
      }

      &.buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 20px;

        .button {
          width: 45%;
          line-height: 30px;
          text-align: center;
          border-radius: 3px;
          margin: 0 auto;

          &.cancel {
            color: gray;
            border: 1px solid gray;
            margin-right: 8px;
            border-radius: 9px;
          }

          &.verify {
            color: white;
            margin-right: 8px;
            border-radius: 9px;
          }

          &.greyed-out {
            background-color: grey !important;
            color: #000 !important;
          }
        }
      }

      
    }

    .divider {
      height: 1px;
      border-bottom: 1px solid lightgray;
      width: 100%;
    }
  }
}

.mobile-time-reports-change-modal,
.mobile-time-reports-table {
  .datetimepicker {
    .minute {
      width: 20%;
    }
  }

  .picker-header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    height: 30px;
    width: 100%;

    #picker-error-msg {
      color: #880000;
      position: relative;
      top: 0;
      width: auto;
      background: transparent;
      margin: 0;
      line-height: 30px;
      height: 30px;
    }

    #picker-close-btn {
      z-index: 99999999;
      position: absolute;
      font-size: 15px;
      cursor: pointer;
      top: 2px;
      color: #fff;
      border-radius: 20px;
      padding: 0 10px;
      width: 70px;
      height: 25px;
      margin: 0;
      line-height: 25px;
      text-align: center;
    }

    #picker-save-btn {
      right: 80px;
    }
  }
  .time-picker-container {
    position: relative;
    height: 132px;
    padding: 0 10px;
    background-color: white;
    z-index: 99;
    box-shadow: 0px 5px 10px 0px #c9cccf;
    margin-left: 10px;

    .time-picker-wrapper {
      tr:nth-child(even) {
        background-color: white !important;
      }
      td > input {
        border: none;
        padding-left: 0;
        border-radius: 0;
        background-color: white;
        padding: 0;
      }
      button {
        background-color: white;
        width: 48px;
      }
      button:focus {
        outline: none;
      }
    }
  }

  .time-picker-container,
  .time-interval-container {
    .button-container {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;

      .time-btn {
        font-size: 15px;
        cursor: pointer;
        color: #fff;
        border-radius: 20px;
        padding: 0;
        width: 70px;
        height: 25px;
        margin: 0;
        line-height: 25px;
        text-align: center;

        &.ok {
          &:before {
            content: '';
            width: 10px;
            border-bottom: 1px solid white;
          }
          &:after {
            content: '';
            width: 5px;
            border-left: 1px solid white;
          }
        }
      }
    }
  }

  .time-interval-container {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    background-color: white;
    box-shadow: 0px 5px 10px 0px #c9cccf;
    margin-left: 10px;

    .time-interval-units {
      display: flex;
      flex-direction: row;
      width: 75px;

      input {
        width: 40px;
        border: none;
        padding: 0;
        text-align: end;
        padding-right: 5px;
      }

      span {
        padding-right: 0;
      }
    }

    .time-container {
      display: flex;
      padding-top: 15px;

      .colon {
        padding-right: 0;
        width: 20px;
        text-align: center;
      }
    }

    .time-hours-input-row {
      background-color: white !important;

      input {
        width: 50px;
        border: none;
        padding-left: 0;
        border-radius: 0;
        background-color: white;
        padding: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        text-align: center;
      }
    }
  }
}