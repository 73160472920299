

$open: var(--session_open);
$completed: var(--session_completed);
$committed: var(--session_committed);
$canceled:  var(--session_canceled);
$active: var(--session_active);
$denied: var(--session_denied);
$overlap: var(--session_overlap);
$interest:  var(--session_interest);
$waiting: var(--session_waiting);
$paused: var(--session_paused);
$pending: var(--session_pending);

$white: #ffffff;
$black: #2d3941;

$lightBlue: #1a8fd2;
$skyBlue: #3aafda;
$niceBlue: #0082cd;

$lightGrayBackground: #eaeef1;

$gray: #9ca8b0;
$lightGray: #dee5ea;
$lightGrayBlue: #f5f7fa;
$grayBorder: #d8d8d8;
$lightGrayBorder: #ccd1d9;
$darkGray: #434a54;
$calProvBorder: #979797;
$timlinename: #2e2e2e;

$yellow: #e4c341;
$red: #cc514c;

$orange: #ea9405;

$green: #38b393;
$limeGreen: #b7dc5a;
$darkGreen: #159f7b;

$newGray: #e5e5e5;
$selectedGreen: #76ba1e;
$modalBackground: #f8f8f8;
$modalBorder: #b6b6b6;

