.enterprise-holder {
  form {
    margin-left: 5px;
  }
}

.dashboard {
  padding: 0 15px;

  .dashboard-header {
    padding-top: 10px;
    border-bottom: 1px solid #b3b5b7;
    padding-bottom: 20px;

    .back-button {
      background: $niceBlue;
      color: #fff;
      border-radius: 20px;
      border: none;
      height: 36px;
      font-size: 16px;
      padding: 0 12px;
    }

    .tools {
      float: right;
    }

    .filters {
      float: left;
      margin-bottom: 10px;

      select.filter, input.filter, span.filter {
        margin-left: 15px;
        width: 150px;
        height: 40px;
        color: $niceBlue;
        border-radius: 20px;
        font-weight: bold;
        padding-left: 20px;
        outline: none;
        border: 1px solid $niceBlue;
        transition: all 0.2s linear;

        &::placeholder {
          color: #000000 !important;
          opacity: 1;
        }

        &::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #000000 !important;
          opacity: 0.8;
        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #000000 !important;
          opacity: 0.8;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #000000 !important;
          opacity: 0.8;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #000000 !important;
          opacity: 0.8;
        }
      }

      select.filter {
        &:focus {
          transition: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      span.filter {
        background: #ffffff;

        input {
          margin-top: 3px;
        }

        input, .input-group-btn, .input-group-btn button {
          border: none;
          outline: none;
          box-shadow: none;
          background: transparent !important;
          color: $niceBlue;
        }
      }

      .category-header {
        margin-left: 50px;
      }
    }

    .date-holder {
      margin-top: 10px;
      height: 40px;
      clear: both;
      .date-control {
        display: inline-block;

        .date-label {
          line-height: 40px;
          display: block;
          float: left;
        }

        .date-text {
          margin: 0 15px;
          outline: none;
          float: right;
          text-align: center;

          border-radius: 20px;
          height: 40px;
          color: $niceBlue;
          font-weight: bold;
          border: 1px solid $niceBlue;

          &::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: $niceBlue;
          }

          &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: $niceBlue;
            opacity: 1;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $niceBlue;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $niceBlue;
          }
        }
      }
    }
  }

  .widget-area {
    margin-top: 10px;
    text-align: center;
    .widget {
      background: #fff;
    }

    ul {
      padding: 0;
      li {
        display: inline-block;
        text-align: left;
        vertical-align: top;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .no-selected-widget {
    text-align: center;
  }
}

.theme-green .bs-datepicker-head {
  background-color: $niceBlue !important;
}

.page-holder {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.request-list-header {
  margin: 16px 0;
  .request-list-header-left {
    float: left;

    h4 {
      float: left;
      font-size: 22px;
      padding: 12px 20px 12px 0;
      margin: 0;
      border-right: 1px solid #d2d5d8;
      margin-right: 15px;
    }

    .filtered-holder {
      float: left;
      height: 48px;
      line-height: 48px;
      .filtered-number {
        font-size: 22px;
        color: $niceBlue;
        font-weight: bold;
        padding-right: 15px;
      }
      .filtered-text {
        font-size: 20px;
      }
    }
  }

  .request-list-header-right {
    float: right;

    .search-text {
      height: 40px;
      border-radius: 20px;
      border: 1px solid $niceBlue;
      width: 360px;
      padding: 0 20px;
      outline: none;
    }

    .search-button,
    .export-to-csv {
      border: none;
      border-radius: 50%;
      background: $niceBlue;
      color: #fff;
      width: 40px;
      height: 40px;
      transition: all linear 0.2s;

      &:hover {
        background: #fff;
        color: $niceBlue;
      }
    }
  }
}

@media (max-width: 1450px) {
  .dashboard {
    .widget-area {
      ul {
        margin: 0 -30px;
        li {
          margin-right: 5px;
        }
      }
    }
  }
}

@media (max-width: 925px) {
  .dashboard {
    .dashboard-header {
      position: relative;
      .filters {
        float: none;
        .filter-lead,
        .category-header {
          display: block;
          font-weight: bold;
          margin-left: 0;
          margin-bottom: 20px;
          margin-top: 10px;
        }
      }

      .tools {
        position: absolute;
        bottom: 30px;
        right: 0;
      }

      .back-button {
        width: 100%;
        padding: 0 10px;
      }
    }

    .widget-area {
      ul {
        padding: 0;
        margin: 0;

        li {
          max-width: 100%;
        }
      }
    }

    .request-list-header-right {
      float: none;

      .search-text {
        width: 75%;
      }

      .search-button,
      .export-to-csv {
        float: right;
      }
    }
  }
}
@media (max-width: 570px) {
  .dashboard {
    .dashboard-header {
      .filters {
        padding: 10px 15px 10px 15px;
        float: none;
        .filter-lead,
        .category-header {
          display: block;
          font-weight: bold;
          margin-left: 0;
          margin-bottom: 20px;
        }

        select.filter {
          display: block;
          margin-bottom: 10px;
          width: 100%;
        }
      }

      .back-button {
        width: 100%;
        padding: 0 10px;
      }

      .tools {
        position: static;
      }
    }

    .widget-area {
      ul {
        padding: 0;

        li {
          max-width: 100%;
        }
      }
    }

    .request-list-header-right {
      float: none;

      .search-text {
        width: 70%;
      }

      .search-button,
      .export-to-csv {
        float: right;
      }
    }
  }
}

.dir-switch-select {
  &#dateSwitch {
    &:first-child {
      width: 90px;
    }
  }

  width: 100%;

  div.select-option {
    border: 1px #D9D9D9 solid;
    padding: 5px 10px 5px 5px;
    width: 49%;
    float: left;
    color: #D9D9D9;
    border-radius: 0 20px 20px 0;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 16px;
    font-weight: bold;

    &:first-child {
      padding: 5px 5px 5px 10px;
      border-radius: 20px 0 0 20px;
      border-right: 1px #D9D9D9 solid;
      text-align: right;
    }

    &.selected {
      color: #707070;
      background-color: #ffffff;
    }
  }

  div.clear {
    clear: both;
  }
}

#dateSwitch {
  .dir-switch-select {
    div.select-option {
      &:first-child {
        width: 90px;
      }
    }
  }
}

#idSwitch {
  .dir-switch-select {
    div.select-option {
      &:first-child {
        width: 98px;
      }
    }
  }
}

.job-order-report-container {
  background-color: #EAEEF1;

  .row {
    &.tab-menu-items {
      div, span.date-picker-wrapper {
        margin: 10px 15px 10px 15px;
        padding: 0 !important;
      }
    }
  }

  .icon-search-orange {
    height: 10px;
    // fill: $orange !important;

    &::before {
      font-size: 16px;
      // fill: $orange !important;
      // color: $orange !important;
    }
  }

  .table.table-striped.job-order-report-table {
    th {
      color: #707070;
      padding: 25px 2px 25px 8px;
      line-height: 33px;
    }
  }

  select.filter, input.filter, span.filter {
    color: #000000 !important;
    font-weight: normal !important;
    border: 1px $lightGrayBackground solid !important;

    a {
      color: #000000 !important;
    }

    // .glyphicon {
    //   fill: $orange !important;
    // }

    // .glyphicon-calendar {
    //   &::before {
    //     fill: $orange !important;
    //     color: $orange !important;
    //   }
    // }
  }

  .fa-pencil {
    &:before {
      color: #838383;
    }

    &:hover {
      &:before {
        color: $orange;
      }
    }
  }

  th {
    background-color: #FAFAFA;
    border: none !important;

    .fa-sort, .fa-sort-desc, .fa-sort-asc {
      padding: 10px 0 8px 0;
      float: right;

      &:before {
        color: #D9D9D9;
      }
    }
  }

  tr {
    &:nth-child(even) {
      background-color: #F6F6F6 !important;
    }
  }
}

.job-order-popover {
  >.arrow {
    z-index: 1000;
    border-left-color: rgba(100, 100, 100, 0.25) !important;

    &:after {
      border-left-color: #ffffff !important;
      border-right-color: black !important;
    }
  }

  .calendar-action {
    ul {
      background-color: #ffffff;

      li {
        border-bottom: 1px #e5e5e5 solid;
      }
    }
  }
}
