.mobile-time-reports-tab-menu-items {
  .action-button {
    height: 55px;
    display: flex;
    align-items: center;
    color: #fff;
    background-color: var(--main-color);
    padding-left: 12px;
    padding-right: 24px;
    border-radius: 43px;
    margin-bottom: 24px;
    font-size: 21px;
    cursor: pointer;
    flex-wrap: wrap;

    .toggler {
      background-color: var(--main-color-light) !important;
      width: 47px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 50%;
      margin-right: 16px;
      transition: ease-in 0.2s;

      &.active {
        transform: rotate(180deg);
      }
    }

    &.repeat-action-button {
      border-radius: 19px;
      height: auto;
      padding: 10px;

      .button-holder {
        margin-top: 20px;
        flex-basis: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          width: 100%;
        }
      }
    }

    &.disabled {
      background: #eaeef1;
      color: #000;
      cursor: not-allowed;

      .toggler {
        background-color: #7c7c7c !important;
      }
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 10px 0;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
  }

  .filter-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 16px;

    .filter-job-order {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #b6b6b6;
      border-radius: 41px;
      padding: 8px;
      width: 100%;
    }

    .filter-type-selectors {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      height: 55px;
      background: #eaeef1;
      padding: 0 8px;
      align-items: center;
      border-radius: 36px;
    }

    .job-order-btn,
    .extract-btn {
      line-height: 39px;
      padding: 0 10px;
      cursor: pointer;
      width: 50%;
      text-align: center;
      background: transparent;
      border-radius: 36px;
      height: 39px;

      &.active {
        font-weight: 700;
      }
    }

    .select-btn {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .select-wrapper {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      border-radius: 36px;
      border: 1px solid #b6b6b6;
      width: 100%;
      margin-right: 0;
      height: 55px;

      select {
        border: none;
        padding: 0 35px 0 20px;
        box-shadow: none;
      }

      &:before,
      &:after {
        content: none;
      }
    }

    .sorter {
      display: inline-block;
      overflow: hidden;
      background-color: #eaeef1;
      height: 55px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 43px;
      font-size: 16px;
      padding: 0 23px;

      label {
        font-weight: 400 !important;
        margin: 0;
        height: 23px;
        overflow: hidden;
      }

      select {
        font-size: 16px;
        border: none;
        box-shadow: none;
        background: transparent;
        -webkit-appearance: none;
        margin-left: 10px;
      }
    }

    .show-hide-buttons {
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 32px;
      justify-content: center;

      & > * {
        margin-top: 16px;
      }
    }
  }

  .button-section {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .verified-info-container {
      padding: 18px 24px;
      padding-right: 0;
      font-size: 21px;
      display: flex;
      align-items: center;
      border-radius: 43px;
      margin-left: auto;
      border: none;
      padding-left: 0;
      justify-content: space-evenly;

      .bold {
        font-weight: 700;
      }

      .separator {
        width: 1px;
        height: 100%;
        background-color: #b6b6b6;
        margin: 0 16px;
      }
    }

    .all-actions-header {
      display: flex;
      justify-content: space-between;
      padding-top: 12px;
      width: 100%;
      align-items: center;
      margin-bottom: 24px;

      p {
        font-size: 28px;
        font-weight: 700;
      }

      button {
        border-radius: 50%;
        background-color: var(--main-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 47px;
        height: 47px;
        border: none;
      }
    }
  }

  .btn-container {
    cursor: pointer;
    margin: 0 15px;

    &.verify-all {
      width: 200px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      border-radius: 9px;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      margin: 16px 0;
    }
  }

  .chart-container {
    .bundled-job-indicator {
      width: auto;
      position: relative;

      .indicator-number-container {
        display: flex;
        flex-direction: column;

        span {
          line-height: normal;
        }
      }

      .pie-chart {
        top: 0;
      }
    }
  }
}

.mobile-time-reports-table {
  padding: 30px 10px;

  .mobile-card {
    margin: 10px 8px 20px 5px;
    background-color: white;
    -webkit-box-shadow: 1px 5px 10px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 5px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 5px 10px 0px rgba(0, 0, 0, 0.5);

    .mobile-row {
      display: flex;
      padding: 8px 0;
      padding: 10px 8px;

      &.odd {
        justify-content: space-between;
      }

      &.even {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
      }

      &.buttons {
        display: flex;
        justify-content: space-between;
        background-color: #f6f6f6;
        padding: 12px 8px;

        div {
          justify-content: space-between;
        }

        .verify-button {
          width: 105px;
          text-align: center;
          border: 1px solid #808080;
          border-radius: 9px;
          font-weight: 700;
          font-size: 14px;
          color: #808080;
          line-height: 30px;
          height: 30px;
          margin: 0px;

          &.main-action {
            color: white;
            border: none;
          }
        }

        .link {
          font-size: 12px;

          &.change {
            color: gray;
            line-height: 30px;
            font-weight: 500;
          }

          &.verified {
            color: #90c300;
          }

          &.unverified {
            font-weight: 500;
          }
        }
      }

      .mobile-row-item {
        display: flex;
        flex-direction: column;

        input[readonly] {
          background: white;
          border: 1px solid #808080;
          border-radius: 28px;
          height: 37px;
        }

        &.worker-name {
          font-size: 18px;
          font-weight: bold;
          color: gray;
        }

        &.status {
          background-color: lightgray;
          padding: 1px 5px;
        }

        &.time {
          .title {
            font-size: 12px;
            color: gray;
          }
        }
      }
    }
  }
}
