.mighty-picker-calendar__day {
  cursor: pointer;
  position: relative;
  color: #333;
}
.mighty-picker-calendar__day--disabled {
  color: #aaa;
  cursor: auto;
}
.mighty-picker-calendar__day--in-range {
  color: #347ab3;
  font-weight: bold;
}
.mighty-picker-calendar__day-marker-wrapper {
  position: relative;
}
.mighty-picker-calendar__day-marker {
  display: none;
  position: absolute;
  background: #fff;
  padding: 4px 6px;
  border: 1px solid #aaa;
  bottom: 16px;
}
.mighty-picker-calendar__day--marked:hover .mighty-picker-calendar__day-marker {
  display: block;
}
.mighty-picker-calendar__day--marked {
  color: orange;
  font-weight: bold;
}
.mighty-picker-calendar__day--selected {
  color: #3b5;
  font-weight: bold;
}
.mighty-picker__month {
  display: inline-block;
  margin: 0 6px;
}
