.form-control {
  border-radius: 0;
  border: 1px solid $modalBorder;
}

/* Input Forms */
select.form-control#queueFilter {
  height: 37px;
  width: 150px !important;
  border-radius: 20px;
}

#sessionQueueForm>.form-group>label {
  padding-right: 10px;
}

.form-inline .input-group>.form-control#sessionSearchInput {
  height: 37px;
}

.form-inline .input-group#sessionSearchForm {
  padding-left: 50px;
}

.job-type.schedule-job.assignment {
  float: right;
  position: relative;
  top: -50px;
}

#sessionSearchForm {
  input {
    //border-top-left-radius: 20px!important;
    //border-bottom-left-radius: 20px!important;
  }

  .input-group-addon {
    //border-top-right-radius: 20px!important;
    //border-bottom-right-radius: 20px!important;
  }
}

#sessionQueueForm {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 5px;

  @media (max-width: 991px) and (min-width: 768px) {
    #sessionSearchForm {
      width: 100%;
      padding-top: 10px;
    }

    select.form-control#queueFilter {
      width: 240px !important;
    }

    .reset-filter-btn {
      float: right;
    }

    .second-filter {
      margin-left: 10px;
    }
  }

  @media (max-width: 767px) {
    .reset-filter-btn {
      margin-top: 10px;
      width: 100%;
    }

    .second-filter {
      margin-top: 10px;
    }
  }
}

.date-picker-btn {
  background-color: $white !important;
}

.row.session-queue-actions {
  padding: 5px 0;

  .col-md-8 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .col-md-4 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.dropdown input[disabled] {
  background: $white;
  border-radius: 0;
  border-color: $modalBorder;
}

.input-group-addon {
  background-color: $newGray;
  border-radius: 0;
  border-color: $modalBorder;
}

.timePicker {
  float: left;
  padding-right: 10px;
  padding-top: 37px;
  font-weight: 700;
}

.form-errors {
  color: $red;
  padding-top: 10px;
}

.form-group.request-job {
  label {
    margin-top: 15px;
  }
}

.slider {
  padding: 10px 20px 0px 20px;

  ul.rz-ticks {
    background-color: #c6c6c6;
  }

  .rz-ticks>.tick {
    display: inline;
  }
}

.required-inner,
.match-inner {
  visibility: hidden;
  display: inline-block;
  width: 12px;
}

@media (min-width: 992px) {
  .field-name {
    position: relative;
    right: 10px;
  }

  .field-modifiers {
    position: absolute;
    right: 0;

    .required,
    .match {
      float: none;
    }
  }
}

.field-modifiers {
  width: 25px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
}

.required {
  position: relative;

  .required-inner {
    float: left;
    visibility: visible;
    font-size: 20px;
    padding-top: 3px;
  }
}

.match {
  position: relative;

  .match-inner {
    visibility: visible;
    float: right;

    &:after {
      content: ' \2713';
      font-size: 15px;
    }
  }
}

@media (max-width: 991px) {

  .required-inner,
  .match-inner {
    float: none !important;
  }

  .required {
    .required-inner {
      display: inline;
      position: relative;
      top: 3px;
    }
  }

  .match {
    .match-inner {
      display: inline;
    }
  }
}

select#singleSelect {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  //background-image: none;
  border: 1px solid $modalBorder;
  //  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  //box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  //-webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  //-o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  //transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  //-webkit-appearance: none;
  //-webkit-appearance: none;
  //-webkit-border-radius: 0px;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background: #fff url('../../svg/input-arrows-black.svg') no-repeat 10px top;
  background-position: 97% 50%;
  background-repeat: no-repeat;
  border-radius: 0;
}

select#singleSelect:focus {
  outline: none;
}

.pac-container {
  z-index: 99999999;
}

#manualLocation {
  margin-top: 10px;
}

#reviewLate {
  margin-top: 15px;
}

input[type='file'] {
  line-height: 10px;
}

////////////////////////////////////////////////////////////////////////////////
//Generic should be able to applied to all inline forms in large modals
.form-inline.center-inline-modal-form-lg {
  @media (max-width: 991px) {
    .form-field-wrapper {
      text-align: left;
      padding: 10px 0px;

      textarea {
        width: 100%;
        z-index: 10;
      }

      label {
        font-size: 1.6rem;
      }

      input[type='text'].free-text-input {
        width: 100%;
      }

      input[type='number'].free-text-input {
        width: 100%;
      }

      input[type='password'],
      input[type='tel'],
      input[type='email'] {
        width: 100%;
      }

      input[type='file'] {
        display: inline-block;
        width: 83%;
      }

      .remove-file {
        opacity: 100;
        font-size: 21px;
        font-weight: 700;
        -webkit-appearance: none;
        cursor: pointer;
        background: 0 0;
        border: 0;
        padding: 0px;
      }

      .dropdown {
        width: 100%;

        .input-group {
          width: 100%;
        }
      }

      #singleSelect {
        width: 100%;
      }

      #multiSelect {
        width: 100%;
      }

      span.input-group-btn {
        width: 1%;

        button {
          background: $white;
        }
      }

      .dropdown-menu {
        width: 100%;

        table {
          width: 100%;
        }
      }

      .time-picker-wrapper {
        display: inline-block;

        button {
          background-color: $white;
        }
      }

      #startTimeLabel {
        vertical-align: top;
        margin-top: 35px;
      }

      input[type='checkbox'] {
        cursor: pointer;
      }

      label.checkbox-inline {
        text-align: left;
        width: 100px;
        padding-left: 5px;
        font-weight: 700;
        font-size: 1.6rem;
        cursor: auto;
      }

      .job-type {
        display: block;

        .checkbox-inline {
          width: 140px !important;
        }
      }

      br#jobTypeBreak {
        display: none;
      }

      #customUrl {}
    }

    // form-field-wrapper
  }

  //  @media (max-width: 991px)
  @media (min-width: 992px) {
    .form-field-wrapper {
      text-align: initial;
      padding: 10px 0px;

      label {
        font-size: 1.6rem;
      }

      .dropdown {
        display: inline-block;
        width: 300px;
        height: 34px;

        .input-group {
          width: 100%;
        }
      }

      #singleSelect {
        display: inline-block;
        width: 300px;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
      }

      #otherLocationDiv {
        padding: 10px 0px;
      }

      label {
        padding-right: 20px;
        text-align: right;
        width: 360px;
      }

      #textLabel {
        vertical-align: top;
      }

      textarea {
        display: inline-block;
        width: 300px;
        padding: 6px 12px;
        font-size: 14px;
        z-index: 10;
      }

      input[type='text'].free-text-input {
        display: inline-block;
        width: 300px;
        padding: 6px 12px;
        font-size: 14px;
      }

      input[type='number'].free-text-input {
        display: inline-block;
        width: 300px;
      }

      input[type='password'],
      input[type='email'],
      input[type='tel'] {
        display: inline-block;
        width: 300px;
        padding: 6px 12px;
        font-size: 14px;
      }

      input[type='file'] {
        display: inline-block;
        width: 265px;
      }

      .remove-file {
        opacity: 100;
        color: $red;
        font-size: 21px;
        font-weight: 700;
        -webkit-appearance: none;
        cursor: pointer;
        background: 0 0;
        border: 0;
        padding: 0px;
        min-width: 30px;
      }

      input#Autocomplete {
        width: 300px;
      }

      #multiSelect {
        width: 300px;
        display: inline-block;
      }

      .multiSelect .checkboxLayer {
        width: 100%;
        left: 0;
      }

      .multiSelect .multiSelectItem .acol label {
        text-align: left;
      }

      .slider {
        padding: 10px 0px;
        width: 400px;
        display: inline-block;
      }

      input[type='checkbox'] {
        cursor: pointer;
      }

      label.checkbox-inline {
        text-align: left;
        width: 100px;
        padding-left: 5px;
        font-weight: 700;
        font-size: 1.6rem;
        cursor: auto;
      }

      span.input-group {
        width: 300px;
      }

      span.input-group-btn {
        width: 45px;

        button {
          background: $white;
        }
      }

      .time-picker-wrapper {
        display: inline-block;

        button {
          background-color: $white;
        }
      }

      .date-picker {
        display: inline-block;
      }

      #startTimeLabel {
        vertical-align: top;
        margin-top: 35px;
      }

      #customUrl,
      #returnEmail {
        width: 202px;
      }

      #nowJobWaitTime,
      #scheduleJobWaitTime {
        width: 268px;
      }
    }

    //end form field wrapper
  }

  //@media (min-width: 992px)
}

////////////////////////////////////////////////////////////////////////////////
//Specific to Add Job Form
.form-inline.schedule-job {
  .scheduleOfferHolder {
    width: 26%;
    position: relative;
    height: 33px;
    display: inline-block;
    top: 10px;

    .schedulePercent,
    .offerPercent {
      position: absolute;
      display: block;
      height: 100%;
    }

    .schedulePercent {
      background: $niceBlue;
      top: 0;
      left: 0;
    }

    .offerPercent {
      background: $skyBlue;
      top: 0;
      right: 0;
    }

    .scheduleWorkers,
    .offerWorkers {
      position: relative;
      color: white;
      font-size: 14px;
      line-height: 33px;
      font-weight: bold;
    }

    .scheduleWorkers {
      float: left;
      margin-left: 10px;
    }

    .offerWorkers {
      float: right;
      margin-right: 10px;
    }
  }

  .bundled-worker-selection {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 50%;
    display: inline-block;

    li {
      list-style: none;
      display: inline-block;
      padding: 0 30px 0 5px;
      background: $niceBlue;
      border-radius: 20px;
      color: #fff;
      position: relative;
      margin: 5px;

      .bundled-worker-close {
        position: absolute;
        background: #fff;
        color: $niceBlue;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        line-height: 20px;
        top: 3px;
        right: 3px;
        text-align: center;
      }
    }
  }

  @media (max-width: 767px) {
    .form-field-wrapper {
      .day-selection-wrapper {
        width: 70% !important;
      }
    }

    //end form field wrapper
  }

  //media max width 991
  @media (max-width: 991px) {
    .form-field-wrapper {
      .day-selection-wrapper {
        display: inline-block;
        width: 90%;
        font-size: 1.4rem;
        margin: 0px 40px;

        label.checkbox-inline {
          padding-right: 5px;
          width: 80px;
          font-size: 1.4rem;
          font-weight: 700;
        }

        .day-list-span {
          display: inline-flex;
          width: 120px;
          padding-bottom: 5px;
        }
      }

      label#daysLabel {
        vertical-align: top;
      }

      #numberOfWorkers {
        width: 75px;
      }
    }

    //end form field wrapper
  }

  //media max width 991
  @media (min-width: 992px) {
    .form-field-wrapper {
      .day-selection-wrapper {
        display: inline-block;
        width: 400px;
        font-size: 1.4rem;

        label.checkbox-inline {
          padding-right: 5px;
          width: 80px;
          font-size: 1.4rem;
        }

        .day-list-span {
          display: inline-flex;
          width: 120px;
          padding-bottom: 5px;
        }
      }

      label#daysLabel {
        vertical-align: top;
      }

      #numberOfWorkers {
        width: 75px;
      }
    }

    //end form field wrapper
  }

  //media min width 992
}

////////////////////////////////////////////////////////////////////////////////
//Specific to Price Form
.form-inline.price-form {
  label {
    font-size: 1.6rem;
  }

  .Absolute-Center {
    height: 50%;
    width: 50%;
    overflow: auto;
    margin: auto;
  }

  .Absolute-Center.cancel-policy {
    width: 85%;
  }

  .Center-Container {
    position: relative;
  }

  .check-box-wrapper {
    text-align: left;
    padding-left: 0;
    min-height: 40px;

    .checkbox-inline {
      font-weight: 700;
      padding-left: 5px;
    }
  }

  .cancel-input-wrapper {
    @media (min-width: 992px) {
      position: absolute;
    }

    @media (max-width: 991px) {
      display: block;
      padding: 10px;
    }
  }

  .dollar-input {
    padding: 10px;

    input {
      min-width: 60px;
      max-width: 120px;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      text-align: center;
    }

    @media (max-width: 767px) {
      input {
        width: 100%;
        text-align: center;
        margin: 5px;
      }
    }
  }

  .time-input {
    padding: 10px;

    input {
      width: 60px;
      text-align: center;
    }

    @media (max-width: 767px) {
      input {
        width: 100%;
        text-align: center;
        margin: 5px;
      }
    }
  }

  .trigger-event-wrapper {
    background-color: aliceblue;
    padding: 20px 20px 20px 110px;
    margin: 30px;
    text-align: left;

    @media (max-width: 991px) {
      padding: 20px 20px 20px 70px;
    }

    .trigger-field-wrapper {
      padding: 10px 0px;

      select {
        width: 250px;

        @media (max-width: 991px) {
          width: 200px;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }

    .check-box-wrapper {
      padding: 0;
    }

    .billing-wrapper {
      padding: 10px 0;

      .dollar-input {
        padding: 0px;

        @media (max-width: 991px) {
          text-align: right;
          padding: 15px;

          input {}
        }
      }

      .rate-select {
        text-align: right;
      }
    }

    .minimum-wrapper {
      padding: 10px 0;

      label {
        font-weight: 700;
      }

      .dollar-input {
        padding: 0px;

        @media (max-width: 991px) {
          text-align: right;
          padding: 15px;

          input {}
        }
      }

      .rate-select {
        text-align: right;
      }
    }

    .travel-wrapper {
      padding: 10px 0;

      .dollar-input {
        padding: 0px;

        @media (max-width: 991px) {
          text-align: right;
          padding: 15px;

          input {}
        }
      }

      .rate-select {
        text-align: right;
      }
    }

    .split-wrapper {
      padding: 10px 0;
    }
  }

  //end of trigger
  .split-price-structure {
    padding: 20px 20px 20px 110px;
    margin: 30px;

    table {

      thead>tr>td,
      tbody>tr>td {
        font-size: 1.6rem;
      }

      select,
      input {
        width: 100%;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .Absolute-Center {
      width: 100%;
    }

    .Absolute-Center.cancel-policy {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .field-wrapper {
      text-align: left;

      input {
        width: 100%;
      }
    }

    .Absolute-Center {
      width: 100%;
    }

    .Absolute-Center.cancel-policy {
      width: 100%;
    }
  }

  .form-field-wrapper {
    label {
      span#providerCheckBox input[type='checkbox'] {
        margin: 0px 10px;
        bottom: 2px;
        position: relative;
      }
    }
  }
}

//.form-inline.price-form
// TODO: refactor forms to use col-sm-6 code below
////////////////////////////////////////////////////////////////////////////////
//Generic for all forms
.form-inline.center-inline-modal-form-lg {
  .row {
    padding: 5px 0px;

    label {
      font-size: 1.6rem;
    }
  }

  .slider {
    font-size: 1.6rem;
    width: 400px;
    display: inline-block;
    display: inline-block;
    text-align: initial;
  }

  .time-picker-wrapper {
    display: inline-block;

    button {
      background-color: $white;
    }

    .form-group {
      input {
        height: 35px;
      }
    }
  }

  .time-picker-label-wrapper {
    display: inline-block;
    vertical-align: top;
    margin-top: 35px;
  }

  .date-picker-btn {
    background-color: $white;
  }

  .date-picker-wrapper {
    .input-group {
      width: 140px;
    }
  }

  .left-side-form {
    text-align: right;
    height: 40px;

    label {
      vertical-align: -webkit-baseline-middle;
    }
  }

  .right-side-form {
    text-align: left;

    select#singleSelect {
      width: 250px;
      height: 40px;
    }

    input[type='text'] {
      width: 250px;
      height: 40px;
    }
  }
}

// end .form-inline.center-inline-modal-form-lg

////////////////////////////////////////////////////////////////////////////////
//Specific to Change Session Time Form
.change-time-form {
  label {
    vertical-align: baseline !important;
  }

  .col-sm-6 {
    #rightLabel {
      font-weight: 400;
    }

    #changeTimeForm.date-picker-wrapper {
      margin-top: 35px;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
//Specific to CHECKOUT Session Form
.check-out-form {
  .col-sm-7.right-side-form {
    text-align: left;

    input[type='number'] {
      width: 80px;
    }

    .input-group {
      padding-right: 5px;
    }

    .input-group-addon {
      background-color: $white;
    }
  }

  .col-sm-5.left-side-form {
    text-align: right;
  }

  .input-group-date-time {
    width: 100%;

    .date-time {
      position: relative;
      width: 100%;
      top: 5px;
      left: 0;
      max-width: 300px;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
//Specific to escalation Form
.form-inline.center-inline-modal-form-lg.escalation-form {
  @media (max-width: 768px) {
    .col-sm-6.left-side-form {
      text-align: left;
      height: 100%;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
//Specific to check-in Form
.check-in-selection {
  select#singleSelect {
    width: 275px !important;
  }
}

////////////////////////////////////////////////////////////////////////////////
.img-preview {
  width: 120px;
  max-height: 80px;
  padding: 5px;
  cursor: pointer;
}

.notification-text {
  font-size: 1.2rem;
  color: $red;
  padding-top: 10px;
  display: inline-block;
}

.form-error-alert {
  font-size: 1.2rem;
  color: $red;
  padding-top: 10px;
  display: inline-block;
}

.enterprise-form {
  margin-top: 40px;

  .logo-item {
    .form-field-wrapper {
      display: inline;
    }
  }

  .geoFence-item {
    border: 1px solid $modalBorder;
    padding: 4px 0;
    margin-bottom: 8px;
  }

  @media (max-width: 991px) {
    .with-tail {
      label {
        width: 100%;
      }

      input {
        max-width: 75%;
        display: inline;
      }
    }

    .tooltip-field {
      position: relative;

      .tooltip-holder {
        position: absolute;
        top: 10px;
        right: 0;
      }
    }

    .logo-item {
      label {
        display: inline;

        &:after {
          content: '\a';
          white-space: pre;
        }
      }
    }

    input[type='checkbox'] {
      display: inline !important;
      width: auto;
      vertical-align: middle;
    }
  }

  .color-field {
    width: 32px;
    padding: 0;
  }
}

.job-type {
  label {
    width: auto !important;
  }
}

.tooltip-field {
  .note {
    text-align: center;
    font-size: 0.7em;
    padding-top: 10px;
  }
}

.clear-date-time {
  z-index: 99999999;
  position: absolute;
  font-size: 15px;
  cursor: pointer;
  top: 50px;
  right: 5px;
  background: $niceBlue;
  color: #fff;
  border-radius: 20px;
  padding: 0 10px;
}

.remove-file {
  >span:before {
    font-family: 'Glyphicons Halflings' !important;
  }
}

.form-field-wrapper {
  .custom-error {
    display: inline-block;
    color: #cc514c;
    font-size: 1.6rem;
  }
}