//table count option
.tableDisplayCount {
  width: 50px;
  text-align: right;
  margin: 0px 5px 0px 10px;
  padding: 6px 4px;
  border-radius: 20px;
  border: none;
  font-size: 14px;
  text-align: center;
}

.tableDisplayCount:focus {
  outline: none;
}

/* TABLE STYLINGS */
table > thead > tr > th {
  background-color: $newGray;
  color: #2e2e2e;
  font-size: 1.4rem;
  font-weight: 700;
}
.table-striped > tbody > tr:nth-of-type(odd),
.table-striped > tbody > tr:nth-of-type(even) {
  background-color: $white;
}

.table > thead > tr > th {
  padding: 8px 2px 8px 8px;
}
.table > tbody > tr {
  font-weight: 400;
  border-top: none;
  border-bottom: none;
  border-bottom: 1px solid #f2f2f2;
}
.table > tbody > tr > td:not(:first-child):not(:last-child) {
}
.table > tbody > tr > td {
  border-top: none;
}
.table#waitingApproval > tbody > tr > td {
  color: $red;
}
.pendingJobScreen {
  .table > tbody > tr > td:first-child {
    border-right: 3px solid $white;
  }
}
.individual-job-table {
  .table > tbody > tr > td:first-child {
    border-right: 3px solid $white;
    width: 40%;
  }
}
.table.table-striped {
  span {
    padding-right: 20px;
  }
}
////////////////////////////////////////////////////////////////////////////////
//Completed Job table view
.completed-jobs {
  .table-striped {
    margin-bottom: 10px;
  }
  .table-striped > tbody > tr {
    height: 60px;
  }
  .table-striped > tbody > tr > td {
    vertical-align: middle;
  }
  .table-striped > tbody > tr > td:first-child {
    font-weight: 700;
    border-right: 3px solid $white;
    width: 50%;
  }
  .table-striped > tbody > tr > td:last-child {
    text-align: left;
  }
}
////////////////////////////////////////////////////////////////////////////////
//Job Receipt session details table view
.col-sm-4.session-details-receipt {
  padding-right: 0px;
  padding-left: 5px;
  .table-striped {
    margin-bottom: 5px;
  }
  .table-striped > tbody > tr {
    height: 60px;
  }
  .table-striped > tbody > tr > td {
    vertical-align: middle;
  }
  .table-striped > tbody > tr > td:first-child {
    font-weight: 700;
    border-right: 3px solid $white;
    width: 50%;
  }
  .table-striped > tbody > tr > td:last-child {
    text-align: left;
  }
}
////////////////////////////////////////////////////////////////////////////////
.table.clickable > tbody > tr:hover {
  cursor: pointer;
}
.table.clickableHeader > thead > tr > th:hover {
  cursor: pointer;
}
.non-clickable {
  cursor: text !important;
}
////////////////////////////////////////////////////////////////////////////////
// session waiting for approval scroll table
.scroll-table {
  .clickable.single-line {
    white-space: nowrap;
    outline: none;
  }
  tbody > tr.scroll-table-header > th:first-child {
    border-radius: 0;
  }

  .scroll-table-header {
    height: 50px;
    display: flex;
    justify-content: space-between;

    th:first-child {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    .notification-toggle-container {
      padding-right: 0;
      display: flex;
      align-items: center;

      span {
        padding-right: 5px;
      }

      .notification-toggle {
        padding-right: 10px;
      }
    }
  }
}
.scroll-table-header > th {
  background-color: $niceBlue;
  color: $white;
  font-size: 1.4rem;
  font-weight: 700;
}
.scroll-table-body > td:first-child {
  padding: 0;
}
.scroll-div {
  width: 100%;
  height: 160px;
  overflow: auto;
}
////////////////////////////////////////////////////////////////////////////////
// provider-profile-table view
.table.table-striped.provider-profile-table {
  .disaply-value {
    text-align: left;

    img.provider-image {
      float: left;
      margin: 0 10px 10px 0;
      cursor: pointer;
    }
  }
}

.provider-table-container {
  background-color: #fff;
  position: relative;

  .worker-filter {
    padding-top: 15px !important;
    padding-left: 40px;

    h4 {
      display: none;
    }

    select {
      border-radius: 20px !important;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 10px;

      li {
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid red;

        &:last-child {
          border-right: none;
        }

        label {
          margin-right: 5px;
        }

        select,
        textarea,
        input,
        .multiSelect > button {
          border-radius: 20px !important;
          outline: none;
        }
      }
    }
  }

  .provider-count {
    position: absolute;
    right: 40px;
    top: 20px;
  }

  .table.provider-table {
    background-color: #fff;

    .profile-picture-container {
      display: inline-block;
      margin-right: 25px;

      .cal-provider-img {
        width: 50px;
        height: 50px;
      }
    }

    thead {
      tr {
        background-color: rgba(112, 112, 112, 0.03);

        th {
          padding: 20px;
          background-color: unset;

          &.table-capitalize {
            text-transform: capitalize;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-of-type(even) {
          background-color: rgba(112, 112, 112, 0.03);
        }

        td {
          padding: 20px;

          &.table-actions {
            display: flex;
          }
        }
      }
    }

    .limit-width {
      width: 40%;
    }
  }
}

.branch-table {
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      padding: 0;
    }
  }
}
////////////////////////////////////////////////////////////////////////////////
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  .table-collapse {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    td {
      /* Behave  like a "row" */
      position: relative;
      padding-left: 50% !important;
      border: 0px solid !important;
    }
    tr:first-child {
      border-top: 2px solid $lightGray;
    }
    tr {
      border-left: 2px solid $lightGray;
      border-right: 2px solid $lightGray;
    }
    tr:last-child {
      border-bottom: 2px solid $lightGray;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: 700;
    }
    .session-queue {
      td:nth-of-type(1):before {
        content: 'Start Time';
      }
      td:nth-of-type(2):before {
        content: 'Reserved';
      }
      td:nth-of-type(3):before {
        content: 'Requester';
      }
      td:nth-of-type(4):before {
        content: 'SVC Type';
      }
      td:nth-of-type(5):before {
        content: 'Worker';
      }
      td:nth-of-type(6):before {
        content: 'Type';
      }
      td:nth-of-type(7):before {
        content: 'Status';
      }
    }
    .map-view {
      td:nth-of-type(1):before {
        content: 'Start Time';
      }
      td:nth-of-type(2):before {
        content: 'Duration';
      }
      td:nth-of-type(3):before {
        content: 'Requester';
      }
      td:nth-of-type(4):before {
        content: 'SVC Type';
      }
      td:nth-of-type(5):before {
        content: 'Worker';
      }
      td:nth-of-type(6):before {
        content: 'Status';
      }
    }
    .price-table {
      td:nth-of-type(1):before {
        content: 'Order';
      }
      td:nth-of-type(2):before {
        content: 'Name';
      }
      td:nth-of-type(3):before {
        content: 'Description';
      }
      td:nth-of-type(4):before {
        content: 'Cancel Policy';
      }
      td:nth-of-type(5):before {
        content: 'Worker';
      }
      td:nth-of-type(6):before {
        content: 'Actions';
      }
    }
    .requester-table {
      td:nth-of-type(1):before {
        content: 'Name';
      }
      td:nth-of-type(2):before {
        content: 'ID';
      }
      td:nth-of-type(3):before {
        content: 'Market Place';
      }
      td:nth-of-type(4):before {
        content: 'Enterprise';
      }
      td:nth-of-type(5):before {
        content: 'Permission';
      }
      td:nth-of-type(6):before {
        content: 'Status';
      }
      td:nth-of-type(7):before {
        content: 'Actions';
      }
    }
    .provider-table {
      td:nth-of-type(1):before {
        content: 'Profile Picture';
      }
      td:nth-of-type(2):before {
        content: 'Name';
      }
      td:nth-of-type(3):before {
        content: 'SVC Type';
      }
      td:nth-of-type(4):before {
        content: 'Username';
      }
      td:nth-of-type(5):before {
        content: 'Staff';
      }
      td:nth-of-type(6):before {
        content: 'Verified';
      }
      td:nth-of-type(7):before {
        content: 'Actions';
      }
    }
    .pool-table {
      margin-top: 40px;
      td:nth-of-type(1):before {
        content: 'Pool';
      }
      td:nth-of-type(2):before {
        content: '# of Individuals';
      }
      td:nth-of-type(3):before {
        content: 'Staff';
      }
      td:nth-of-type(4):before {
        content: 'Actions';
      }
    }
    .escalations-table {
      td:nth-of-type(1):before {
        content: 'Order';
      }
      td:nth-of-type(2):before {
        content: 'Name';
      }
      td:nth-of-type(3):before {
        content: 'Pools';
      }
      td:nth-of-type(4):before {
        content: 'Total Response Time';
      }
      td:nth-of-type(5):before {
        content: 'Actions';
      }
    }
    .meeting-places-table {
      margin-top: 20px;
      td:nth-of-type(1):before {
        content: 'Meeting Places';
      }
      td:nth-of-type(2):before {
        content: 'Requirements';
      }
      td:nth-of-type(3):before {
        content: 'Special Instructions';
      }
      td:nth-of-type(4):before {
        content: 'Actions';
      }
    }
    .pool-provider-view {
      td:nth-of-type(1):before {
        content: 'Select';
      }
      td:nth-of-type(2):before {
        content: 'Worker';
      }
      td:nth-of-type(3):before {
        content: 'SVC Type';
      }
    }
    .jobs-table {
      td:nth-of-type(1):before {
        content: 'Name';
      }
      td:nth-of-type(2):before {
        content: 'Description';
      }
      td:nth-of-type(3):before {
        content: 'Actions';
      }
    }
    .verify-table {
      td:nth-of-type(1):before {
        content: 'Session ID';
      }
      td:nth-of-type(2):before {
        content: 'Worker';
      }
      td:nth-of-type(3):before {
        content: 'Status';
      }
      td:nth-of-type(4):before {
        content: 'SVC Type';
      }
      td:nth-of-type(5):before {
        content: 'Duration';
      }
      td:nth-of-type(6):before {
        content: 'Check In';
      }
      td:nth-of-type(7):before {
        content: 'Check Out';
      }
      td:nth-of-type(8):before {
        content: 'Time Worked';
      }
      td:nth-of-type(9):before {
        content: 'Amount';
      }
      td:nth-of-type(10):before {
        content: 'Verify';
      }
    }
    .role-table {
      td:nth-of-type(1):before {
        content: 'Name';
      }
      td:nth-of-type(2):before {
        content: 'Enterprise';
      }
      td:nth-of-type(3):before {
        content: 'Actions';
      }
    }
    .role-privilege-view {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      td:nth-of-type(1):before {
        content: 'Select';
      }
      td:nth-of-type(2):before {
        content: 'Name';
      }
    }
    .user-table {
      td:nth-of-type(1):before {
        content: 'Name';
      }
      td:nth-of-type(2):before {
        content: 'Actions';
      }
    }
    .enterprise-table {
      td:nth-of-type(1):before {
        content: 'Name';
      }
      td:nth-of-type(2):before {
        content: '# Workers';
      }
      td:nth-of-type(3):before {
        content: '# Requesters';
      }
      td:nth-of-type(4):before {
        content: '# Sessions';
      }
      td:nth-of-type(5):before {
        content: 'Actions';
      }
    }
    .dashboard-request-table {
      td:nth-of-type(1):before {
        content: 'Start Time';
      }
      td:nth-of-type(2):before {
        content: 'Job Template Name';
      }
      td:nth-of-type(3):before {
        content: 'Reserved';
      }
      td:nth-of-type(4):before {
        content: 'Requester';
      }
      td:nth-of-type(5):before {
        content: 'Key Attribute';
      }
      td:nth-of-type(6):before {
        content: 'Worker';
      }
      td:nth-of-type(7):before {
        content: 'Type';
      }
      td:nth-of-type(8):before {
        content: 'Status';
      }
    }
    .dashboard-report-table {
      td:nth-of-type(1):before {
        content: 'Session ID';
      }
      td:nth-of-type(2):before {
        content: 'Worker';
      }
      td:nth-of-type(3):before {
        content: 'Status';
      }
      td:nth-of-type(4):before {
        content: 'Key Attribute';
      }
      td:nth-of-type(5):before {
        content: 'Duration';
      }
      td:nth-of-type(6):before {
        content: 'Date';
      }
      td:nth-of-type(7):before {
        content: 'Check In';
      }
      td:nth-of-type(8):before {
        content: 'Check Out';
      }
      td:nth-of-type(9):before {
        content: 'Time Worked';
      }
      td:nth-of-type(10):before {
        content: 'Location';
      }
      td:nth-of-type(11):before {
        content: 'Amount';
      }
    }
  }
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1045px) {
  .table-collapse {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    td {
      /* Behave  like a "row" */
      position: relative;
      padding-left: 50% !important;
      border: 0px solid !important;
    }
    tr:first-child {
      border-top: 2px solid $lightGray;
    }
    tr {
      border-left: 2px solid $lightGray;
      border-right: 2px solid $lightGray;
    }
    tr:last-child {
      border-bottom: 2px solid $lightGray;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: 700;
    }
    .jobs-table {
      td:nth-of-type(1):before {
        content: 'Name';
      }
      td:nth-of-type(2):before {
        content: 'Description';
      }
      td:nth-of-type(3):before {
        content: 'Enterprise';
      }
      td:nth-of-type(4):before {
        content: 'Requester';
      }
      td:nth-of-type(5):before {
        content: 'Start Date';
      }
      td:nth-of-type(6):before {
        content: 'End Date';
      }
      td:nth-of-type(7):before {
        content: 'Days of Week';
      }
      td:nth-of-type(8):before {
        content: 'Position';
      }
      td:nth-of-type(9):before {
        content: 'Status';
      }
      td:nth-of-type(10):before {
        content: 'Actions';
      }
    }
  }
}
