.loader-component {
  width: 100%;
  height: 100%;

  .loader-component-indicator {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
