.schedule-job {

  .button-container {

    .button-name {
      font-size: small;

      &.inactive {
        opacity: 0.5;
        cursor: default;
      }

      &.active {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .adhoc-toggler {
    margin-left: 20px;
    font-size: small;
    color: var(--main-color);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .unbundled {
    padding-right: 0 !important;
  }

  .adhoc-schedule {
    margin: 0 -15px;
  }
}
