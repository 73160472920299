.app-workers-page {
  &-section {
    &-header {
      font-weight: bold;
      font-size: 14px;
    }
  }

  &-profile {
    &-item {
      display: flex;
    }
  }

  &-skill-list {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      display: inline-block;
      border-radius: 20px;
      color: #fff;
      margin-right: 10px;
      padding: 5px;
    }
  }
}
