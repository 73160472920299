.communications {
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 26px;
  }

  .items {
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 248px;
      border: 1px solid #d3d2d3;
      border-radius: 5px;
      margin-right: 30px;
      margin-bottom: 60px;
      background-color: #fff;

      .item-header {
        height: 118px;
        background-color: #ddd7d7;
        display: flex;
        justify-content: center;
        padding-top: 18px;
        position: relative;

        &-edit {
          color: #4e6b45;
          font-size: 12px;
          line-height: 22px;
          background-color: #f9fff6;
          border: 1px solid #93aa8c;
          border-radius: 33px;
          width: 60px;
          height: 22px;
          position: absolute;
          text-align: center;
          right: 15px;
          top: 10px;

          &.inactive {
            color: #00000099;
            background: #fff;
            border: 1px solid #00000033;
            width: 85px;
            right: 10px;
          }
        }

        &-icon {
          width: 29px;
          height: 29px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          position: absolute;
          left: 26px;
          top: 8px;

          &.custom {
            background-color: #095f9d;
          }

          &.default {
            background-color: #0d99ff;
          }
        }
      }

      .item-footer {
        padding: 12px 12px 0 16px;
        display: flex;
        justify-content: space-between;

        &-title {
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
        }

        &-description {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
        }

        .item-toggler {
          .toggle {
            width: 25px !important;
            height: 13px !important;
            border-radius: 31px;

            &.toggler-on {
              background: #34c759 !important;

              .toggle-group {
                right: -15px;
                top: -5px;
                width: 150%;
              }
            }

            &.off {
              background: #cacaca !important;

              .toggle-group {
                left: -18px;
                top: -5px;
              }
            }

            .toggle-on,
            .toggle-off {
              display: none !important;
            }

            .toggle-handle {
              width: 9px !important;
              height: 9px !important;
              background: #fff !important;
              padding: 0;
              margin: 0;
              border-radius: 50%;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &-edit {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      flex-wrap: wrap;

      &-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 20px;
      }

      &-description {
        font-size: 16px;
        line-height: 18px;
      }

      .actions {
        button {
          border-radius: 30px;
          border-width: 1px;
          border-style: solid;
          width: 151px;
          height: 44px;
          line-height: 44px;
          font-size: 16px;
          color: #000;
          background: none;
          padding: 0;
          margin-left: 30px;

          &:first-child {
            margin-left: 0;
          }

          &.button-revert {
            border-color: #dc8a5a;
            background-color: #dc8a5a33;
          }

          &.button-preview {
            border-color: #b7b7b7;
            background-color: #d3d2d3;
          }

          &.button-save {
            border-color: var(--main-color);
            background-color: var(--main-color);
            font-weight: 700;
            color: #fff;
          }
        }
      }
    }

    .content {
      border: 1px solid #d3d2d3;
      background-color: #f4f7f9;
      border-radius: 5px;
      padding: 30px;

      .communication-field {
        margin-bottom: 30px;

        p {
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 10px;
        }

        .text-message-holder {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .character-counter {
            border: 1px solid #d3d2d3;
            border-radius: 5px;
            background-color: #eceff2;
            font-size: 12px;
            line-height: 15px;
            padding: 3px;

            display: flex;
            align-items: center;

            .tooltip-holder {
              margin-left: 12px;
            }
          }
        }

        .text-message-tooltip {
          margin-top: 10px;
          font-weight: 400;
        }

        & > div[contenteditable] {
          border: 1px solid #d3d2d3;
          border-radius: 5px;
          padding: 14px 18px;
          resize: vertical;
          overflow: auto;
          white-space: pre;
        }
      }

      .communication-variables {
        margin-bottom: 30px;

        p {
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 10px;
        }

        .variable-content {
          background-color: #eceff2;
          border-radius: 5px;
          padding: 14px 18px;

          .variable-item {
            margin-bottom: 16px;
            .variable-name {
              color: #00000080;
            }
          }
        }
      }

      .communication-upload {
        .upload-title {
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 12px;
        }

        .upload-description {
          font-size: 10px;
          margin-bottom: 15px;
        }

        .upload-content {
          border: 1px solid #d3d2d3;
          border-radius: 5px;
          background-color: #ebeef1;
          min-height: 125px;
          font-size: 10px;
          cursor: pointer;

          input[type='file'] {
            display: none;
          }

          .flex-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 18px;
            margin-bottom: 18px;

            img {
              max-width: 90%;
            }

            button {
              border-radius: 30px;
              border-width: 1px;
              border-style: solid;
              width: 151px;
              height: 44px;
              line-height: 44px;
              font-size: 16px;
              color: #000;
              background: none;
              padding: 0;
              margin-top: 18px;
            }
          }
        }
      }
    }
  }
}

.text-message-template {
  position: relative;
  width: 313px;
  margin: 0 auto;

  .text-message-content {
    position: absolute;
    top: 90px;
    background-color: #d9d9d9;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 20px;
    top: 80px;
    left: 23px;
    width: 85%;
    text-align: left;
  }

  .notification-content {
    background-color: #25252580;
    border-radius: 12px;

    display: flex;
    align-items: center;
    color: #fff;
    padding: 8px;
    position: absolute;
    top: 55px;
    left: 23px;
    width: 85%;

    img {
      margin-right: 8px;
      border-radius: 8px;
    }

    .notification {
      display: flex;
      flex-direction: column;
      font-size: 12px;

      .notification-header {
        display: flex;
        justify-content: space-between;

        .header-label {
          font-size: 10px;
          color: rba(235, 235, 245, 0.6);
        }
      }

      .notification-body {
        text-align: left;
      }
    }
  }
}

.email-template {
  .template-subject {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    max-width: 400px;

    @media (max-width: 600px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 20px;

      .template-label {
        margin-bottom: 20px;
      }
    }

    .template-label {
      font-weight: 700;
      font-size: 14px;
      margin-right: 20px;
    }

    .template-value {
      border: 1px solid #d3d2d3;
      border-radius: 5px;
      background-color: #ededed;
      padding: 14px;
      font-size: 14px;
    }
  }
  .template-content {
    background-color: #f8f8f8;
    padding: 60px 128px;
    display: flex;
    justify-content: center;

    @media (max-width: 1400px) {
      flex-wrap: wrap;

      .desktop-item {
        margin-right: 0 !important;
        margin-bottom: 60px !important;
      }

      .mobile-item {
        width: 50% !important;
      }
    }

    @media (max-width: 1000px) {
      padding: 60px 0 !important;

      .desktop-item {
        width: 100% !important;
      }

      .mobile-item {
        width: 100% !important;
      }
    }

    @media (max-width: 600px) {
      padding: 0 10px !important;

      .desktop-item,
      .mobile-item {
        .preview-content {
          padding: 0 10px !important;
        }
        .preview-body {
          padding: 0 10px !important;
        }
      }
    }

    .desktop-item {
      width: 70%;
      margin-right: 100px;

      img {
        max-width: 200px;
        margin-bottom: 40px;
      }

      .preview-content {
        padding: 50px 66px;
        overflow: scroll;

        .preview-body {
          padding: 40px;

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-size: 2rem;
          }

          p {
            font-size: 1rem;
          }

          table {
            table-layout: fixed;
            width: 100%;
            word-wrap: break-word;
          }
        }
      }
    }

    .mobile-item {
      width: 25%;

      img {
        max-width: 110px;
        margin-bottom: 20px;
      }

      .preview-content {
        padding: 30px;
        overflow: scroll;

        .preview-body {
          padding: 20px;

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-size: 2rem;
          }

          p {
            font-size: 1rem;
          }

          table {
            table-layout: fixed;
            width: 100%;
            word-wrap: break-word;
          }
        }
      }
    }

    .desktop-item,
    .mobile-item {
      & > p {
        color: #a5a5a5;
        font-weight: 700;
        font-size: 19px;
        text-align: center;
        margin-bottom: 34px;
      }

      .preview-content {
        filter: drop-shadow(0px 0px 29px rgba(0, 0, 0, 0.15));
        border-radius: 20px;
        background-color: #fff;

        .preview-body {
          border: 1px solid #1b1b1b33;
          border-radius: 6px;
          font-size: 16px;
          line-height: 22px;
          text-align: left;
        }

        .preview-footer {
          margin-top: 20px;
          text-align: center;

          p {
            font-size: 12px;
            color: #00000080;

            a {
              font-weight: 700;
              text-decoration: underline;
            }
          }

          div {
            color: #00000080;
            background-color: #f7f7f7;
            padding: 10px;
            border-radius: 6px;
            text-align: center;
            margin-top: 20px;
            font-size: 10px;
            display: inline;
          }
        }
      }
    }
  }
}

.template-highlight {
  color: #00000080;
}
