@media (min-width: 1176px) {
  .navbar-header {
    .user-toggler {
      display: none;
    }
  }
}

@media (max-width: 1175px) {
  .navbar-header {
    float: none;
    padding-right: 0px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    &::before,
    &::after {
      content: none;
    }

    .user-toggler {
      border: none;
      background: transparent;
      color: #fff;
      cursor: pointer;
      margin-top: 15px;

      &:active,
      &:focus {
        outline: none;
      }

      i {
        font-size: 40px;
      }
    }
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }

  .nav.navbar-nav.navbar-right {
    margin-right: 0 !important;
  }

  .navbar-toggle {
    display: block;
  }
  //.nav.navbar-nav.navbar-right {
  //  height: 500px!important;
  //}
  //.navbar-fixed-top {
  //  top: 0;
  //  border-width: 0 0 1px;
  //}

  .navbar-collapse {
    height: 500px;
    border-top: 1px solid $white;
    padding: 0;
    margin: 0 !important;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }
  //.navbar-nav {
  //  float: none!important;
  //  margin-top: 7.5px;
  //}

  .navbar-nav > li {
    float: none;
  }

  .navbar-nav > li > a {
    padding: 24px;
    border-bottom: 3px solid $white;
  }

  .navbar-nav > li.user-display-name {
    display: none !important;
  }

  .navbar-nav > li.user-display-name > a {
    border-bottom: 3px solid transparent;
  }

  .navbar-nav > li.user-display-name .user-separator {
    display: none;
  }

  .collapse.in {
    display: block !important;
  }

  .navbar-default .navbar-toggle {
    border: 0;
  }

  .navbar-default .navbar-toggle .icon-bar {
    background-color: $white;
  }

  .navbar-default .navbar-toggle:hover {
    background-color: transparent;
  }

  .navbar-toggle .icon-bar {
    display: block;
    width: 32px;
    height: 4px;
    border-radius: 6px;
  }

  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 8px;
  }

  //.nav.navbar-nav.navbar-right {
  //  min-height: 60px;
  //}

  .container-fluid {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 425px) {
  .navbar-brand h1 {
    display: none;
  }

  .navbar-nav {
    margin: 0 !important;
  }

  .navbar-header {
    margin: 0 0 0 15px !important;
    padding-right: 0;
  }

  .navbar-toggle {
    margin-right: 0;
  }
}

////////////////////////////////////////////////////////////////////////////////
//HEADER NAV

.navbar {
  background-color: $niceBlue;
  border: none;
  border-radius: 0 !important;
  -moz-border-radius: 0 !important;

  a,
  .dropdown-menu a {
    color: white !important;
    font-weight: 400;
    font-size: 14px;
  }
  .open > a,
  .open a:focus {
    background-color: $niceBlue !important;
  }
  .active > a,
  .dropdown-menu a:hover {
    background-color: $lightBlue !important;
    color: $white !important;
    border-bottom: 3px solid $white;
  }

  .dropdown-menu {
    padding: 0;
  }
}

.navbar-nav > li > a {
  padding-top: 23px;
  padding-bottom: 25px;

  img {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 767px) {
    padding-top: 18px;
    padding-bottom: 19px;
  }
}

.navbar-nav > li > a:hover {
  border-bottom: 3px solid $white;
}

.navbar-nav > li.user-display-name {
  display: flex;
  align-items: center;

  a:focus {
    outline: none;
  }
}

.navbar-nav > li.user-display-name > a:hover {
  border-bottom: none;
}

.navbar-nav > li.user-display-name .user-separator {
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  height: 30px;
}

.navbar-header {
  height: 70px;

  .navbar-toggle.collapsed {
    margin-top: 13px;
  }
}

.nav.navbar-nav.navbar-right {
  min-height: 70px;
  margin-right: 15px;

  .disable-schedule-tab{
    opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
  }

  //@media (max-width: 767px) {
  //  height:320px;
  //
  //  li {
  //    height:40px;
  //    &.dropdown {
  //      height: auto;
  //    }
  //  }
  //  a {
  //    height:40px;
  //  }
  //
  //  .dropdown-menu {
  //    display: block;
  //    top: 0;
  //    position: static;
  //    float: none;
  //    background: transparent;
  //    border: none;
  //    text-align: center;
  //    box-shadow: none;
  //
  //    a {
  //      padding: 10px 0;
  //      line-height: 20px;
  //
  //      &:hover,
  //      &:focus {
  //        background-color: $lightGray !important;
  //        color: $black !important;
  //      }
  //    }
  //  }
  //}
}

//@media (min-width: 768px) {
//  .navbar-nav>li>a,
//  .navbar-nav .dropdown-menu a{
//    padding-top:25px;
//    padding-bottom:25px;
//  }
//}
//// while collapsed
//@media (max-width: 767px) {
//  .navbar-collapse {
//    background-color: $lightGray;
//    text-align: center;
//  }
//}
////////////////////////////////////////////////////////////////////////////////
/* NAVBAR FOOTER */
.navbar-static-bottom {
  border: none;
  padding-top: 20px;
  padding-bottom: 20px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    li {
      display: inline;
      padding: 3rem;
      white-space: nowrap;

      @media (max-width: 991px) {
        padding: 1rem;
      }
      a:link {
        text-decoration: none;
        font-weight: 400;
      }
    }
  }
}
.navbar.navbar-default.navbar-static-bottom {
  margin-top: 50px;
  background-color: $white;
}
