.page-header.row {
  margin-left: 0;
}

.page-tittle-left {
  padding: 12px 0 5px 0;
  font-size: 32px;

  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .working-as {
    font-size: 14px;
  }
}

.page-tittle-right {
  text-align: right;
  padding-right: 0;

  .new-version-alert {
    float: right;
    padding: 14px 20px 0 0;

    .text {
      color: red;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .date {
    float: right;
    font-size: 18px;
    padding-top: 14px;
  }

  .notification-button {
    float: right;
    border-left: 1px solid $niceBlue;
    padding: 14px 20px 12px 20px;
    position: relative;
    height: 54px;
    cursor: pointer;

    &.icon-review {
      margin-left: 20px;
    }

    img {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    .notification-counter {
      width: 17px;
      height: 12px;
      border-radius: 6px;
      background: #f9b100;
      color: $white;
      text-align: center;
      font-size: 9px;
      line-height: 12px;
      position: relative;
      top: -25px;
      left: 17px;
    }
  }
}

#branchFilter,
#enterpriseFilter,
.adminFilter {
  border: none;
  color: gray;
  padding: 0 35px 0 20px;
  cursor: pointer;
}

.select-inline-wrapper {
  display: inline-block;
  margin-right: 10px;
  border-radius: 40px;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  .form-control {
    border-radius: 40px;
    border: none;
    padding: 6px 0;
   
  }
}

.select-wrapper {
  display: inline-block;
  border-radius: 40px;
  overflow: hidden;
  margin-right: 10px;
  vertical-align: bottom;
  position: relative;
  cursor: pointer;

  /*style the arrow inside the select element:*/
  &:before {
    position: absolute;
    content: '';
    top: 8px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: transparent transparent #1897b6 transparent;
  }
  /*style the arrow inside the select element:*/
  &:after {
    position: absolute;
    content: '';
    top: 24px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #1897b6 transparent transparent transparent;
  }

  &.skill-select {
    &:after,
    &:before {
      right: 25px;
    }

    #skillSelect {
      padding-right: 35px;
    }
  }

  select {
    height: 44px;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.multiselect-wrapper {
  display: inline-block;
  border-radius: 40px;
  margin-right: 10px;
  vertical-align: bottom;
  position: relative;
  cursor: pointer;
  width: 300px;
}

.label-wrapper {
  display: inline-block;
  line-height: 44px;
  margin-right: 10px;

  label {
    margin-bottom: 0;
  }
}
