.swal2-container {
  div {
    // the site's font size is 10px and the modal's font size is 1rem so it would be really small if we won't override it
    font-size: 1.6rem;
  }

  .cancel-options {
    height: 2.625em;
    padding: 0 .75em;
    width: 100%;

    option {
      width: 100%;
    }
  }
}
