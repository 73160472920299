////////////////////////////////////////////////////////////////////////////////
//Key Tab
.key-filters {
  text-align: left;
  .form-group {
    margin-right: 15px;
    @media (max-width: 767px) {
      margin-right: 0;
    }
  }
}
.key-stats {
  span#dateLabel {
    float: left;
    color: black;
    font-size: 2.3rem;
    padding-right: 10px;
  }
  .row {
    margin: 20px 0 20px 0;
    text-align: center;
    font-weight: 700;
    color: $white;
    .reports {
      border: 1px solid $grayBorder;
    }
    .col-md-4:not(:last-child) {
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }
  .header {
    background-color: $niceBlue;
    padding: 10px 0 10px 0;
    height: 180px;
  }
  .body {
    background-color: $white;
    height: 130px;
    opacity: 0;
  }
  .footer {
    background-color: $grayBorder;
    padding: 20px 0 20px 0;
    .btn {
      width: 200px;
      height: 50px;
    }
  }
  .circle {
    border-radius: 50%;
    border: 1px solid $grayBorder;
    width: 200px;
    height: 200px;
    background-color: $white;
    margin: auto;
    overflow: scroll;
    padding-top: 60px;
    margin-top: 20px;
    color: $lightGray;
    font-size: 6rem;
    /* width and height can be anything, as long as they're equal */
  }
  .reset-filter-btn {
    margin-left: 10px;
  }
  .second-filter {
    margin-left: 10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .provider-filter {
      padding-top: 10px;
      width: 100%;
      select.form-control#queueFilter {
        width: 225px !important;
      }
    }
    .time-filter {
      width: 100%;
      #queueFilter {
        width: 86% !important;
      }
    }
  }
  @media (max-width: 767px) {
    .reset-filter-btn {
      margin-top: 10px;
      margin-left: 0;
      width: 100%;
    }
    .second-filter {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}
.date-range-display {
  color: $black;
  text-align: left;
}
////////////////////////////////////////////////////////////////////////////////
// Map View Tab
.map-views-filter {
  .form-control {
    height: 37px;
    width: 150px !important;
  }
  .form-group {
    padding: 20px 0 20px 0;
    label {
      padding-right: 15px;
    }
  }
}
.marker_labels {
  font-size: 1.6rem;
  font-weight: 700;
  background-color: $white;
  border: 1px solid $black;
  padding: 2px;
}
////////////////////////////////////////////////////////////////////////////////
//Change Verify tab
.row.tab-menu-items {
  div {
    padding: 10px 15px 10px 15px;
  }
  .verifier-name {
    font-weight: 400;
    padding-left: 10px;
  }
  .dropdown-selectors {
    text-align: right;
    label {
      padding-right: 10px;
    }
    .date-selector-dropdown {
      width: 300px;
    }
    .filter-selector-dropdown {
      width: 200px;
    }
    label {
      padding-left: 10px;
    }
    @media (max-width: 991px) {
      text-align: left;
    }
  }
  .col-md-2 {
    .btn.btn-success {
      width: 100%;
    }
  }
  .col-md-2.col-md-offset-6 {
    text-align: right;
    @media (max-width: 991px) {
      text-align: left;
      padding-left: 0;
    }
    .verify-all-option {
      position: relative;
      vertical-align: -webkit-baseline-middle;
      label {
        font-weight: 700;
        font-size: 1.6rem;
      }
    }
  }
}
.verify-table {
  label.checkbox-inline {
    padding-left: 5px;
  }
  thead > th {
    font-size: 1.2rem;
  }
}
.row.no-reports-msg {
  text-align: center;
  font-size: 1.8rem;
  color: $red;
}
////////////////////////////////////////////////////////////////////////////////
//Pentaho reports
.pentaho-reports {
  .row {
    margin-bottom: 20px;
  }
  .time-filter {
    width: 300px;

    select {
      border-radius: 20px;
    }

    #timePeriod {
      width: 100%;
    }
  }
  .single-select {
    #singleSelect {
      width: 300px;
    }
  }
  .format-select {
    #singleSelect {
      width: 150px;
    }
  }
  .date-picker {
    .input-group {
      width: 300px;
    }
  }
  .submit-btn {
    height: 60px;
    padding-top: 25px;
    .btn {
      width: 100%;
    }
  }
  .dashboard-report {
    width: 100%;
    overflow: auto;
    iframe {
      width: inherit;
      height: 1100px;
      border: none;
    }
  }
  .report-col {
    text-align: center;
    overflow: auto;
  }
  #pentaho-report {
    display: inline-block;

    table {
      tr {
        .style-2,
        .style-3,
        .style-4,
        .style-5,
        .style-6,
        .style-7,
        .style-8,
        .style-9,
        .style-10,
        .style-11,
        .style-12,
        .style-13 {
          font-weight: bold;
        }

        .style-5 {
          font-size: 2rem;
          font-weight: bold;
        }
      }
    }
  }
}
