.dashboard-widget {
  .widget-content {
    .widget-header {
      text-align: center;
      .widget-header-number {
        font-size: 36px;
        color: $niceBlue;
      }
    }
    .chart-item {
      padding-bottom: 10px;
      border-left: 4px solid transparent;
      .chart-item-header {
        h4 {
          font-weight: bold;
          font-size: 15px;
          margin: 0;
        }
      }
      .status-percentage {
        float: right;
        font-size: 11px;
        max-width: 50%;
        position: relative;
        top: -17px;
        .status-name {
          display: inline-block;
          padding: 0 14px;
          border-radius: 20px;
          color: #fff;
          font-weight: bold;
          &.Pending {
            background: $pending;
          }
          &.Completed {
            background: $completed;
          }
          &.Committed {
            background: $committed;
          }
          &.Canceled {
            background: $canceled;
          }
          &.Active {
            background: $active;
          }
          &.Open {
            background: $open;
          }
          &.Denied {
            background: $denied;
          }
          &.Interest {
            background: #018080;
          }
        }
        .percentage {
          display: inline-block;
          text-align: right;
          width: 25px;
          &.Pending {
            color: $pending;
          }
          &.Completed {
            color: $completed;
          }
          &.Committed {
            color: $committed;
          }
          &.Canceled {
            color: $canceled;
          }
          &.Active {
            color: $active;
          }
          &.Open {
            color: $open;
          }
          &.Denied {
            color: $denied;
          }
          &.Interest {
            color: #018080;
          }
        }
      }
      .bar-item {
        background: #e6e6e6;
        border-radius: 20px;
        height: 11px;
        .bar-value {
          background: red;
          height: 100%;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          &.Pending {
            background: $pending;
          }
          &.Completed {
            background: $completed;
          }
          &.Committed {
            background: $committed;
          }
          &.Canceled {
            background: $canceled;
          }
          &.Active {
            background: $active;
          }
          &.Open {
            background: $open;
          }
          &.Denied {
            background: $denied;
          }
          &.Interest {
            background: #018080;
          }
        }
      }
      &:hover {
        border-left: 4px solid $niceBlue;
        background: #f0f0f0;
      }
    }
  }
}
