.analytics-reports-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
  background-color: #fff;
  position: relative;
  margin: 0 auto;
  padding: 50px;

  .report-title {
    width: 700px;
    font-size: 22px;
    color: #5a5a5a;
    margin-bottom: 50px;
  }
}

@media (max-width: 1175px) {
  .analytics-reports-container {
    width: 100%;
    padding: 20px 0;

    .report-title {
      width: auto;
    }
  }
}

@media (max-width: 570px) {
  .analytics-reports-container {
    width: 100%;
    padding: 20px 0;

    .report-title {
      width: auto;
    }

    .chart-legend .horizontal-legend li {
      display: block;
    }
  }
}
