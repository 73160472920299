.dashboard-widget {
  .widget-content {
    .data-row,
    .first-data-row {
      .data-value,
      .data-label {
        width: 40%;
        border-top: 1px solid #cdcdcd;
        height: 50px;
        line-height: 50px;
      }
      .data-value {
        float: left;
        color: #5a5a5a;
        font-size: 30px;
        font-weight: bold;
        text-align: right;
      }
      .data-label {
        float: right;
        color: #6b6b6b;
        text-align: left;
      }
    }
    .first-data-row {
      .data-value,
      .data-label {
        border-top: none;
      }
      .data-value {
        color: #94c11a;
      }
    }
  }
}
