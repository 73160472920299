.action-button {
  height: 55px;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: var(--main-color);

  padding-left: 6px;
  padding-right: 24px;
  border-radius: 43px;
  font-size: 16px;
  margin-right: 18px;
  cursor: pointer;

  .toggler {
    background-image: linear-gradient(rgb(0 0 0/30%) 0 0);
    width: 47px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    margin-right: 16px;
    transition: ease-in 0.2s;

    &.active {
      transform: rotate(180deg);
    }
  }

  &.disabled {
    background: #eaeef1;
    color: #000;
    cursor: not-allowed;

    .toggler {
      background-color: #7c7c7c !important;
    }
  }

  &.repeat-action-button {
    background-color: #eaeef1 !important;
    color: #363636 !important;

    .toggler {
      transform: scaleX(-1) rotate(270deg);
      background: rgba(0, 0, 0, 0.2) !important;

      i {
        color: #000;
      }
    }

    button {
      background-color: var(--main-color);
      color: #fff !important;
      cursor: pointer;
      border-radius: 30px;
      margin-left: 12px;
      padding: 5px 16px;

      &.secondary {
        background: rgba(0, 0, 0, 0.2) !important;
        border: none !important;
        color: #000 !important;
      }
    }
  }
}

.time-reports-tab-menu-items {
  display: flex;
  justify-content: space-between;
  min-height: 100px;
  align-items: center;
  background: white;
  flex-wrap: wrap;
  padding-bottom: 16px;

  .action-button-container {
    display: flex;
    flex: 1;
    margin-top: 16px;
  }

  .filter-section {
    display: flex;
    align-items: center;
    padding-left: 16px;
    width: 100%;
    flex-wrap: wrap;

    .filter-job-order {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #b6b6b6;
      border-radius: 36px;
      height: 55px;
      padding: 0 8px;
      margin-right: 24px;
      margin-top: 16px;
    }

    .all-actions {
      margin-left: auto;
    }

    .filter-type-selectors {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eaeef1;
      border-radius: 36px;
      height: 39px;
      padding: 0 8px;
    }

    .job-order-btn,
    .extract-btn {
      line-height: 39px;
      padding: 0 10px;
      cursor: pointer;
      min-width: 95px;
      text-align: center;
      background: transparent;
      border-radius: 36px;
      height: 39px;
      font-size: 16px;
      overflow: hidden;

      &.active {
        font-weight: 700;
      }
    }

    .select-wrapper {
      display: flex;
      overflow: hidden;
      margin-right: 10px;
      vertical-align: bottom;
      position: relative;
      min-width: 320px;

      &:before,
      &:after {
        content: none;
      }

      select {
        font-size: 16px;
        border: none;
        padding: 0 35px 0 20px;
        box-shadow: none;
      }

    }

    .sorter {
      display: inline-block;
      overflow: hidden;
      background-color: #eaeef1;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 43px;
      font-size: 16px;
      padding: 0 23px;
      margin-right: 8px;

      label {
        font-weight: 400 !important;
        margin: 0;
        height: 23px;
        overflow: hidden;
      }

      select {
        font-size: 16px;
        border: none;
        box-shadow: none;
        background: transparent;
        // -webkit-appearance: none;
        margin-left: 10px;
        font-weight: bold;
      }
    }
  }
}

.button-section {
  display: flex;
  align-items: center;
  border-top: 1px solid #b6b6b6;
  width: 100%;
  background-color: #fff;
  flex-wrap: wrap;
  padding-top: 16px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 21px;

  &>p {
    flex-basis: 100%;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .btn-container {
    cursor: pointer;
    margin: 0 15px;
  }

  .verified-info-container {
    height: 55px;
    border: 1px solid #b6b6b6;
    padding: 18px 24px;
    padding-right: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    border-radius: 43px;
    margin-left: auto;

    .bold {
      font-weight: 700;
    }

    .separator {
      width: 1px;
      height: 100%;
      background-color: #b6b6b6;
      margin: 0 16px;
    }

    .verify-all {
      margin-left: 24px;
      margin-right: 0;
    }
  }
}

#timereports-tab-table {
  min-height: 270px;

  th,
  td {
    line-height: 33px;
    color: hsl(0, 0%, 44%);
  }

  th {
    background-color: #fafafa;
    border: none !important;
    color: #707070;
    padding: 25px 2px 25px 8px;

    .fa-sort,
    .fa-sort-desc,
    .fa-sort-asc {
      padding: 10px 0 8px 0;
      float: right;

      &:before {
        color: #d9d9d9;
      }
    }
  }

  tr {
    &:nth-child(even) {
      background-color: #f6f6f6 !important;
    }

    .button {
      &.verify-all {
        display: block;

        &.greyed-out {
          color: gray;
          border-color: gray;
          background: none;

          &:hover,
          &:focus,
          &:active {
            background-color: unset !important;
          }
        }
      }
    }

    .verify-all-text {
      display: block;
    }
  }

  td {
    input {
      border-radius: 40px;
      border: 1px solid;
      padding-left: 5px;

      &.check-in {
        width: 95px;
      }

      &.check-out {
        width: 95px;
      }

      &.time-worked {
        width: 110px;
      }

      &.break {
        width: 125px;
      }

      &.amount {
        width: 85px;
      }

      .date-picker-wrapper {
        margin: 10px 15px 10px 15px;
        padding: 0 !important;
      }
    }

    .penalty {
      width: 60px;
      border-radius: 28px;
      height: 37px;
      border: 1px solid #707070;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .has-penalty {
        background-color: #ff5454;
        border-radius: 28px;
        width: 44px;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    input:focus {
      outline: none;
    }

    &.date-picker-container {
      span.date-picker-wrapper {
        border-radius: 40px;
        border: 1px solid;
        padding-right: 0;
        background-color: white;

        ul#dLabel {
          display: block;
        }

        .date-picker-btn:focus {
          outline: none;
        }
      }

      input {
        border: none;
        padding-left: 5px;
        border-radius: 40px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 77px;
        background-color: white;
        height: auto;
        margin: 1px;
      }

      .input-group-btn {
        padding-right: 0;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;

        button {
          margin: 1px;
          border: none;
          border-top-right-radius: 40px;
          border-bottom-right-radius: 40px;
        }
      }

      .datetimepicker {
        width: 400px;
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        left: -300px;

        .picker-header {
          display: flex;
          justify-content: space-between;
          position: absolute;
          top: 0;
          height: 30px;
          width: 100%;

          #picker-error-msg {
            color: #880000;
            position: relative;
            top: 0;
            width: auto;
            background: transparent;
            margin: 0;
            line-height: 30px;
            height: 30px;
          }

          #picker-close-btn {
            z-index: 99999999;
            position: absolute;
            font-size: 15px;
            cursor: pointer;
            top: 2px;
            color: #fff;
            border-radius: 20px;
            padding: 0 10px;
            width: 70px;
            height: 25px;
            margin: 0;
            line-height: 25px;
            text-align: center;
          }

          #picker-save-btn {
            right: 80px;
          }
        }

        table {

          @media only screen and (max-width: 760px),
          (min-device-width: 768px) and (max-device-width: 1024px) {
            display: table;

            thead {
              display: table-header-group;
            }

            tbody {
              display: table-row-group;
            }

            th,
            td {
              display: table-cell;
            }

            tr {
              display: table-row;
            }

            thead tr {
              position: unset;
              top: 0;
              left: 0;
            }

            td {
              /* Behave  like a "row" */
              position: relative;
              padding-left: 0 !important;
              border: 0px solid !important;
            }

            tr:first-child {
              border-top: inherit;
            }

            tr {
              border-left: inherit;
              border-right: inherit;
            }

            tr:last-child {
              border-bottom: inherit;
            }

            td:before {
              /* Now like a table header */
              position: unset;
              /* Top/left values mimic padding */
              top: 0;
              left: 0;
              width: auto;
              padding-right: 0;
            }
          }

          &.day-view {
            flex: 1;
          }

          &.minute-view {
            flex: 1;
          }

          th {
            padding: 0;
            background-color: white;
          }

          tr:nth-child(even) {
            background: white !important;
          }

          td {
            line-height: 25px;
          }

          td.active.active {
            color: white;
          }

          td.active.active:hover {
            color: white;
          }

          span.active.active {
            border-radius: 20px;
          }
        }

        .minute {
          width: 12% !important;
          padding-right: 0;
        }

        .vertical-divider {
          width: 7px;
          height: auto;
        }
      }
    }

    .time-interval-wrapper {
      position: relative;
    }

    .time-interval-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 30px;
      padding: 0 15px;
      background-color: white;
      z-index: 99;
      box-shadow: 0px 5px 10px 0px #c9cccf;

      .time-interval-units {
        display: flex;
        flex-direction: row;
        width: 75px;

        input {
          width: 40px;
          border: none;
          padding: 0;
          text-align: end;
          padding-right: 5px;
        }

        span {
          padding-right: 0;
        }
      }

      .time-container {
        display: flex;
        padding-top: 15px;

        .colon {
          padding-right: 0;
          width: 20px;
          text-align: center;
        }
      }
    }

    &.verify-button {
      padding-right: 0;

      .exclamation-mark {
        padding-right: 0;
      }

      .narrow-button {
        width: 85px !important;
      }

      .greyed-out {
        color: gray;
        border-color: gray;
        background: none;

        &:hover,
        &:focus,
        &:active {
          background-color: unset !important;
        }
      }
    }

    &.no-show-button {
      .link-button:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .table.table-striped span {
    padding-right: 0;
  }

  .table.table-striped span:focus {
    outline: none;
  }

  .button {
    width: 110px;
    background: transparent;
    border-width: 1px;
    border-style: solid;
    margin: 0;
    text-align: center;
    border-radius: 40px;
    cursor: pointer;
  }

  .verified {
    display: block;
    height: 20px;
    cursor: default;
  }

  .link {
    display: block;
    height: 20px;
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
  }

  .link:hover {
    font-weight: bold;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }

  .link:visited {
    color: #707070;
  }

  /* Specify styling for tooltip contents */
  .tooltip.customClass .tooltip-inner {
    color: #fff;
    background-color: #880000;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    padding: 10px;
    font-size: 15px;
  }

  .time-picker-container {
    position: absolute;
    top: 40px;
    height: 132px;
    padding: 0 10px;
    left: -40px;
    background-color: white;
    z-index: 99;
    box-shadow: 0px 5px 10px 0px #c9cccf;

    tr:nth-child(even) {
      background-color: white !important;
    }

    td>input {
      border: none;
      padding-left: 0;
      border-radius: 0;
      background-color: white;
      padding: 0;
    }

    .time-picker-wrapper {
      button {
        background-color: white;
        width: 48px;
      }

      button:focus {
        outline: none;
      }
    }
  }

  .break-editor {
    position: relative;

    .icon-plus-blue {
      position: absolute;
      right: 3px;
      top: 6px;

      @media (max-width: 1045px) {
        position: relative;
        right: 23px;
        top: 5px;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
  }

  .time-btn {
    font-size: 15px;
    cursor: pointer;
    color: #fff;
    border-radius: 20px;
    padding: 0;
    width: 70px;
    height: 25px;
    margin: 0;
    line-height: 25px;
    text-align: center;

    &.ok {
      &:before {
        content: '';
        width: 10px;
        border-bottom: 1px solid white;
      }

      &:after {
        content: '';
        width: 5px;
        border-left: 1px solid white;
      }
    }
  }

  #time-picker-popup-container {
    .time-hours-input-row {
      background-color: white !important;

      input {
        width: 50px;
        border: none;
        padding-left: 0;
        border-radius: 0;
        background-color: white;
        padding: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        text-align: center;
      }
    }
  }

  .supervisor-disabled {
    cursor: default !important;
    pointer-events: none;
  }

  .check-out-action-buttons {
    display: flex;
    justify-content: space-between;

    .supervisor-button {
      margin-right: 14px;
    }
  }
}

.popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.visible-overlay {
    display: block;
  }
}

.verifier-popover {
  visibility: visible !important;
}

.break-container {
  position: absolute;
  top: 10px;
  left: -35px;
  z-index: 99;
}

.break-popup {
  position: fixed;
  display: flex;
  flex-direction: column;
  border: 1px solid #717171;
  border-radius: 20px;
  width: 487px;
  background: white;
  z-index: 999;
  max-height: 90%;

  &-tabs {
    display: flex;
    margin: 10px 30px 0;
    border-bottom: 1px solid #dedede;

    a {
      margin-right: 40px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      display: block;
      padding-bottom: 14px;
      color: rgba(0, 0, 0, 0.5);

      &.active {
        color: #000;
        font-weight: 700;
        border-bottom: 3px solid var(--main-color);
      }
    }

    &-content {
      padding: 0 30px;
    }
  }

  @media (max-width: 767px) {
    left: 0 !important;
    top: 20px;
    width: 100%;

    .break-popup-timepicker-wrapper {
      z-index: 100;
    }
  }

  h4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    color: #717171;
    margin-bottom: 16px;
    margin-top: 0;
    padding: 20px 35px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 9;
  }

  .total-time-error {
    color: red;
    line-height: 18px;
    align-self: center;
    text-align: center;
    padding: 20px;
  }

  &-section {
    position: relative;
    display: flex;
    padding: 30px 0 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #f6f6f6;

    a {
      color: #666;
      text-decoration: underline;
      text-align: center;
    }

    &-line {
      display: flex;
      justify-content: space-around;
      font-weight: bold;
      color: #000;
      text-align: left;
      margin: 0;

      .display-value {
        background-color: #e2e2e2;
        text-align: center;
        width: 66px;
        border-radius: 10px !important;
        border: none !important;
        font-weight: bold;
        color: #000;

        &.invalid {
          border: 1px solid red !important;
        }
      }

      &-dash {
        margin: 0 8px;
        font-weight: bold;
      }
    }

    &-separator {
      width: 1px;
      height: 80%;
      background-color: #f6f6f6;
    }

    .break-popup-icon {
      margin-right: 12px;
    }

    &-editing {
      display: flex;
      flex: 1;

      &-items {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        width: 100%;

        .overlap-error {
          color: red;
          line-height: 18px;
          align-self: center;
          margin-left: 20px;
        }

        button {
          margin-left: auto;
          background: var(--main-color);
          color: #fff;
          border-radius: 9px;

          &:disabled {
            background: #e8e8e8;
            color: #707070;
          }
        }
      }
    }

    &-show {
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: 0 20px;
      width: 100%;
    }

    &-actions {
      display: flex;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 9px;
        background-color: #e8e8e8;
        cursor: pointer;
        margin-right: 8px;
      }
    }
  }

  button.btn-success {
    border-radius: 10px !important;
    margin-bottom: 16px;
  }

  &-timepicker-wrapper {
    position: absolute;
    background-color: #fff;
    border: 1px solid #666;
    border-radius: 20px;
    top: 0;
    padding: 10px;
    z-index: 999;

    &-buttons {
      display: flex;
      justify-content: space-evenly;
    }
  }

  &-link {
    font-weight: bold;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 40px;
  }

  &-add {
    font-weight: bold;
    color: var(--main-color);
    margin-top: 26px;
    display: block;
  }

  &-total {
    display: flex;
    justify-content: space-between;
    padding: 28px 35px;

    .total-label {
      font-weight: bold;
      color: black;
    }

    .total-value {
      border: 1px solid #b6b6b6;
      padding: 8px 15px !important;
      border-radius: 9px;
      font-weight: bold;
      line-height: 14px;
      color: black;
    }
  }

  &-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    padding: 0 30px;
    height: 73px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .cancel-button {
      background-color: transparent;
      color: #000;
      border: 1px #b6b6b6 solid !important;
    }

    .submit-button {
      background-color: var(--main-color);
      color: #fff;
      font-weight: bold;
      margin-left: 30px;
    }

    button {
      border-radius: 9px;
    }
  }

  .penalty-section {
    display: flex;
    justify-content: space-between;
    padding: 26px 0;
    border-bottom: 1px solid #f6f6f6;

    .penalty-label {
      font-weight: bold;
    }
  }

  .penalty-adding {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 26px 0;

    .input-holder {
      display: flex;

      input {
        border-radius: 9px !important;
        margin-left: 16px;
        background-color: #e8e8e8;
        color: #000;
        border: none !important;

        &.time-value {
          font-weight: bold;
          width: 70px;
          text-align: center;
          padding: 0 !important;
        }
      }
    }

    .penalty-action {
      margin: 26px auto;
      text-align: center;
    }
  }

  .penalty-action {
    border-radius: 9px;
    background-color: var(--main-color);
    color: #fff;
    font-weight: bold;
  }

  &-error {
    text-align: center;
    line-height: 1.5rem;
    color: red !important;
    width: 100%;
  }

  .supervisor-signOff-section {

    .approved-header {
      background-color: #0368971A !important;
    }

    .supervisor-header {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .supervisor-details {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: left;

      .name {
        display: flex;
        gap: 6px;
        padding-left: 8px;
      }

      .signature {
        border-radius: 8px;
        vertical-align: middle;
        padding: 6px 6px 6px 10px;
        margin-bottom: 10px;

        img {
          padding-left: 10px;
        }
      }
    }
  }
}

.date-holder {
  margin-top: 10px;
  height: 40px;
  clear: both;

  .date-control {
    display: inline-block;

    .date-label {
      line-height: 40px;
      display: block;
      float: left;
    }

    .date-text {
      margin: 0 15px;
      outline: none;
      float: right;
      text-align: center;
      cursor: default;
      border-radius: 20px;
      height: 40px;
      font-weight: bold;
      border: 1px solid var(--main-color);
    }
  }
}