@import './widget/_dnd';
@import './widget/_fillRate';
@import './widget/_cancelBreakDown';
@import './widget/_shiftCount';
@import './widget/_toggleButton';
.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.dashboard-widget {
  position: relative;
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  width: 440px;
  height: 343px;
  padding: 15px 20px;

  .widget-drag {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .title {
    font-size: 22px;
    color: #5a5a5a;
  }
  .drag.right {
    text-align: right;
    padding: 0;
  }
  .first-data-row {
    text-align: left;
    position: relative;
    border-left: 4px solid transparent;
    .top-value {
      float: left;
      color: $niceBlue;
      font-size: 52px;
      padding: 0;
      height: 78px;
      line-height: 78px;
      padding-left: 20px;
      padding-right: 15px;
    }
    .top-label {
      float: left;
      position: relative;
      bottom: 15px;
      font-size: 16px;
      top: 40px;
    }

    &:hover {
      border-left: 4px solid $niceBlue;
      background: #f0f0f0;
    }
  }

  .scrollbar-holder {
    height: 200px;
  }
  .other-data-row {
    border-left: 4px solid transparent;
    padding: 5px 0 0 0;
    .data-value {
      font-size: 35px;
      text-align: right;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 40px;
      color: $niceBlue;
      padding-bottom: 5px;
      &.financial {
        color: #5a5a5a;
      }
    }
    .data-label {
      font-size: 16px;
      text-align: left;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 40px;
      color: #5a5a5a;
      font-weight: normal;
      padding-top: 16px;
      line-height: 16px;
      height: 100%;
    }
    &:hover {
      border-left: 4px solid $niceBlue;
      background: #f0f0f0;
    }
  }
  .widget-loading {
    width: 100%;
    height: 100%;
    .widget-loading-indicator {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .widget-empty {
    h4 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .chart-toggler {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    opacity: 0;
  }

  .export-csv {
    transition: 0.2s all ease;
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 30px;
    opacity: 0;
    width: 30px;
    height: 30px;

    img {
      width: 30px;
      height: 30px;
    }
  }

  &:hover {
    .export-csv {
      display: block;
      opacity: 1;
    }
    .chart-toggler {
      display: block;
      opacity: 1;
    }
  }
}

.dragHandler {
  position: absolute;
  right: 15px;
  top: 15px;

  i {
    font-size: 20px;
  }
}

@media (max-width: 425px) {
  .dashboard-widget {
    max-width: 100%;
  }
}
