.notification-widget {
  &.review:before {
    right: 95px;
  }

  &:before {
    content: '';
    position: absolute;
    right: 30px;
    top: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $niceBlue;
    clear: both;

    @media (max-width: 1024px) {
      right: 8px;
    }

    @media (max-width: 768px) {
      right: 8px;
    }
  }

  position: absolute;
  top: 150px;
  right: 25px;
  width: 450px;
  min-height: 362px;
  z-index: 9999;
  background-color: $white;
  box-shadow: 0 5px 10px 0 rgba(201, 204, 207, 1);
  border-top: 2px solid $niceBlue;
  padding-top: 24px;

  @media (max-width: 768px) {
    width: 450px;
  }

  @media (max-width: 736px) {
    width: 450px;
    top: 190px;
    right: 11px;
  }

  @media (max-width: 425px) {
    width: 290px;
    top: 190px;
    right: 11px;
  }

  .notification-close {
    position: absolute;
    top: 15px;
    right: 13px;

    &:hover {
      opacity: .7;
      cursor: pointer;
    }
  }

  .notification-item {
    cursor: pointer;
    border-bottom: 1px solid #DADADA;
    padding: 15px 0 0px 0;
    .notification-content {
      padding-left: 25px;

      &.completed {
        border-left: 5px solid $completed;
      }
      &.open {
        border-left: 5px solid $open;
      }
      &.pending {
        border-left: 5px solid $pending;
      }
      &.committed {
        border-left: 5px solid $committed;
      }
      &.active {
        border-left: 5px solid $active;
      }
      &.canceled {
        border-left: 5px solid $canceled;
      }

      .notification-time {
        color: #9B9B9B;
        font-size: 13px;
      }

      .notification-text {
        color: $black;
        display: block;
        font-size: 16px;
        line-height: 18px;
        padding: 0;
        padding-right: 40px;
      }
    }
  }

  &.review {
    table {
      margin-top: 15px;

      .scroll-div{
        height: 300px;
      }
    }
  }
}
