.api-logs-filter {
  padding-top: 0 !important;

  .selector-container {
    display: flex;
    align-items: baseline;
  }

  label {
    color: #2e2e2e;
    font-weight: 700;
    font-size: 1.4rem;
    margin-top: 0;
    min-width: 100px;
  }

  button {
    outline: none !important;
  }

  #singleSelect.filter-list {
    display: inline-block;
    width: auto;
    min-width: 150px;
  }

  ul {
    list-style-type: none;
    padding: 0 0 0 15px;
    margin: 20px 0;

    li {
      vertical-align: top;
      padding: 0;
      margin: 20px 0 0 0;

      .form-field-wrapper {
        padding: 0;
        display: inline-block;

        label {
          margin: 0;
          padding: 0;
          vertical-align: middle;
          min-width: 100px;
        }

        input, textarea {
          width: auto;
          padding: 6px 12px;
          max-height: 34px;
          vertical-align: middle;
        }

        select {
          min-width: 200px;
        }

        .dropdown {
          display: inline-table;
          padding: 0;
          margin: 0;

          .input-group {
            padding: 0;

            input {
              min-width: 300px;
            }
          }
        }

        #singleSelect {
          display: inline-block;
          width: auto;
        }
      }

      .remove-icon {
        vertical-align: middle;
      }

      .glyphicon {
        top: 0;
      }
    }
  }

  .apply-filters {
    background: none;
    color: $niceBlue;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
}

// API Logs Detail Modal

.apilogs-detail-modal {

  textarea {
    width: 400px;
    height: 200px;

    &.small-area {
      height: 30px;
    }
  }

  .detail-value {
    text-align: left !important;
  }
}
