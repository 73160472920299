.btn.btn-primary.back-button {
  position: absolute;
  height: 75px;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.1);
  .glyphicon {
    top: 2px;
  }
}
.jobView {
  padding-top: 30px;
  padding-bottom: 50px;
  border-bottom: 1px solid $lightGray;
}
//map indicator of loc tracking unavailable
.location-unavailable-banner {
  position: absolute;
  background-color: white;
  z-index: 100;
  right: 15px;
  top: 0px;
  height: 45px;
  font-weight: 700;
  padding: 10px;
  width: 95.5%;
  text-align: center;
  background-color: $yellow;
  color: $white;
}
//Diable-Enable auto adjust gmap btn
.map-auto-fit {
  position: absolute;
  top: 10px;
  left: 118px;
  border: none;
  border-radius: 0;
  font-size: 1.3rem;
  color: $black;
  background-color: $white;
}
.provider_marker_labels {
  font-size: 1.6rem;
  font-weight: 700;
  background-color: $white;
  border: 1px solid $black;
  padding: 2px;
  white-space: nowrap;
}
////////////////////////////////////////////////////////////////////////////////
// General Well Styling
.well {
  padding: 0;
  height: 75px;
  margin-bottom: 0;
  h3 {
    color: $white;
    padding-left: 15px;
    padding-top: 3px;
    float: left;
    @media (max-width: 767px) {
      font-size: 2rem;
      padding-top: 5px;
    }
  }
  .close {
    float: right;
    color: #fff;
    font-size: 4rem;
    padding-right: 2rem;
    line-height: 75px;
  }
}
h3#provider {
  color: $darkGray;
  font-size: 2.4rem;
  font-weight: 700;
  span {
    padding-right: 15px;
  }
}
////////////////////////////////////////////////////////////////////////////////
// Specific Styling
.well.jobPending {
  background-color: $yellow;
}
.well.jobOpen {
  background-color: $black;
}
.well.jobCommitted {
  background-color: $green;
}
.well.jobInProgress {
  background-color: $green;
}
.well.jobCanceled {
  background-color: $red;
}
.well.jobCompleted {
  background-color: $black;
}
.well.jobInterest {
  background-color: $interest;
}
.well.jobWaiting {
  background-color: $waiting;
}
////////////////////////////////////////////////////////////////////////////////
// completed job customer Review section
.row.customer-review {
  b {
    padding-right: 10px;
    vertical-align: top;
  }
  .glyphicon {
    font-size: 20px;
    color: $skyBlue;
  }
}
.panel {
  border-radius: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
////////////////////////////////////////////////////////////////////////////////
// Open Issue Panel Section
.row.open-issue {
  .glyphicon {
    font-size: 15px;
  }
  .panel.panel-default {
    border: none;
    border-bottom: none;
    background-color: $white;
  }
  .panel-heading {
    border: none;
    border-bottom: none;
    background-color: $white;
    padding: 10px 0 0 0;
  }
  .panel-body {
    border: none;
    padding-left: 0;
    font-size: 1.4rem;
  }
  .checkbox {
    font-size: 1.8rem;
    label:not(:first-child) {
      padding-right: 30px;
      margin-bottom: 10px;
      vertical-align: top;
      @media (max-width: 767px) {
        margin-right: 75px;
      }
    }
    label:first-child {
      padding-right: 30px;
      margin-bottom: 10px;
      vertical-align: top;
      @media (max-width: 767px) {
        margin-right: 100px;
      }
    }
    input[type='number'] {
      margin-right: 30px;
      height: 40px;
    }
  }
  .checkbox-inline input[type='checkbox'] {
    height: 18px;
    width: 18px;
  }
}
////////////////////////////////////////////////////////////////////////////////
//submit button
.completed-jobs {
  padding-top: 10px;
  .btn {
    width: 200px;
    height: 40px;
    margin-top: 5px;
  }
  .button-submission {
    text-align: right;
  }
  .customer-comments {
    @media (min-width: 992px) {
      text-align: right;
    }
    @media (max-width: 991px) {
      padding: 20px 0 20px 0;
    }
  }
  .table > tbody > tr > td {
    @media (max-width: 991px) {
      width: 50%;
    }
  }
  .checkbox {
    @media (max-width: 767px) {
      width: 50%;
    }
    .checkbox-inline {
      margin-left: 0;
    }
  }
}
//completed job details view
.col-md-4.completed-jobs {
  min-height: 90px;
  table {
    min-height: inherit;
  }
}
////////////////////////////////////////////////////////////////////////////////
//session provider header details
.edit-btn-section {
  height: 130px;
  display: inline-block;
}
.btn-group.edit {
  float: right;
  position: relative;
  top: 45px;
  .btn.edit-session-dropdown {
    width: 160px;
    color: $white;
    font-weight: 700;
    background-color: $gray;
    span {
      float: right;
      position: relative;
      top: 7px;
    }
  }
  .session-dropdown-menu {
    background-color: $black;
    cursor: pointer;
    li {
      padding: 5px;
      list-style-type: none;
      text-align: right;
      color: $white;
    }
  }
}
