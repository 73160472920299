.pricetab,
.requestertab,
.providertab,
.pooltab,
.escalatinontab,
.meetingtab,
.jobtab,
.usertab,
.roletab {
  .glyph-link {
    top: auto;
    padding: 0;
  }

  .excel-export-icon {
    &:before {
      margin-left: -5px;
      margin-right: 5px;
    }
  }
}

.provider-page {
  padding: 0 15px;
}

.providertab{
  margin-top: -85px;

  @media (max-width: 1024px) {
    margin-top:-40px;
  }
}