// ---------------------------------------------------------------------------------------------------------
// REPEAT MODAL Styles
// ---------------------------------------------------------------------------------------------------------

.modal-header.repeat-modal {
  border-bottom: none;

  h1 {
    padding-top: 40px;
    font-size: 2.7rem;
    padding-bottom: 20px;
  }

  p {
    color: #707070;
    font-weight: 300;
  }
}

.modal-close-container {
  position: absolute;
  top: 10px;
  right: 13px;
  z-index: 1;
}

.modal-body.repeat-modal,
.modal-body.terms-modal {
  background-color: white;

  .container-fluid {
    display: flex;
    flex-direction: column;

    div {
      margin-bottom: 20px;
    }

    .error-message {
      color: red;
      font-size: 20px;
    }

    .date-picker-container,
    .time-picker-outer-container,
    .selected-worker-number-container {
      width: 300px;
      align-self: center;
    }

    .date-picker-container {
      .date-picker-wrapper {
        border: 1px solid #707070;
        border-radius: 20px;
        line-height: 36px;
        padding: 2px;

        &.repeat-modal-error {
          border-color: red !important;
        }

        input:focus,
        .btn:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }

        input,
        .input-group-btn {
          border: none;
        }

        input {
          padding-left: 15px;
          background-color: white;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          color: #707070;
        }
        .btn {
          border: none;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }

        ul#dLabel {
          display: block;
        }
      }
    }

    .time-picker-outer-wrapper {
      border: 1px solid #707070;
      border-radius: 20px;
      line-height: 36px;
      position: relative;
      padding: 2px;

      &.repeat-modal-error {
        border-color: red !important;
      }

      input:focus,
      .btn:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }

      input,
      .input-group-btn {
        border: none;
      }

      input {
        padding-left: 15px;
        background-color: white;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        color: #707070;
      }
      .btn {
        border: none;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      ul#dLabel {
        display: block;
      }

      .time-picker-container {
        position: absolute;
        top: 40px;
        height: 132px;
        padding: 0 10px;
        left: 60px;
        background-color: white;
        z-index: 99;
        box-shadow: 0px 5px 10px 0px #c9cccf;

        .button-container {
          display: flex;
          justify-content: space-between;
          padding-top: 5px;
          margin-bottom: 0px;
        }

        .time-btn {
          font-size: 15px;
          cursor: pointer;
          color: #fff;
          border-radius: 20px;
          padding: 0;
          width: 70px;
          height: 25px;
          margin: 0;
          line-height: 25px;
          text-align: center;

          &.ok {
            &:before {
              content: '';
              width: 10px;
              border-bottom: 1px solid white;
            }
            &:after {
              content: '';
              width: 5px;
              border-left: 1px solid white;
            }
          }
        }

        .time-picker-wrapper {
          button {
            background-color: white;
            width: 48px;
          }
          button:focus {
            outline: none;
          }

          input {
            border: none;
            border-radius: 0;
            background-color: white;
            padding: 0;
          }
        }
      }
    }

    .selected-worker-number-container {
      border: 1px solid #707070;
      border-radius: 40px;
      line-height: 33px;
      position: relative;

      &.repeat-modal-error {
        border-color: red !important;
      }
    }

    .selected-worker-number-container:after {
      outline: none;
    }

    .selected-worker-number-container:after {
      position: absolute;
      content: '';
      top: 15px;
      right: 17px;
      width: 0;
      height: 0;
      border: 6px solid;
    }

    .selected-worker-number {
      border: none;
      color: #707070;
      padding: 0 35px 0 15px;
      cursor: pointer;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .selected-worker-number:focus {
      outline: none;
    }

    .comments-container {
      width: 100%;

      .comments {
        width: 75%;
        min-width: 300px;
        height: 100px;
        padding: 10px;
        color: #707070;
        font-size: 14px;
        border: 1px solid #707070;
      }

      .comments:focus {
        outline: none;
      }
    }

    .button-container {
      display: flex;
      justify-content: center;
      padding-top: 20px;

      .button {
        width: 150px;
        background: transparent;
        border-width: 1px;
        border-style: solid;
        margin: 0;
        text-align: center;
        border-radius: 40px;
        cursor: pointer;
        line-height: 3.5rem;
        margin-right: 30px;
      }
    }
  }

  .link {
    display: block;
    height: 20px;
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
  }

  .link:hover {
    font-weight: bold;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }

  .link:visited {
    color: #707070;
  }
}

// ---------------------------------------------------------------------------------------------------------
// REPEAT MODAL Styles
// ---------------------------------------------------------------------------------------------------------
