/* MODAL STYLINGS */
/* MODAL STYLINGS */
.close {
  opacity: 100;
}

.modal-xlg {
  width: 90%;
}

.modal-dialog {
  .form-inline.center-inline-modal-form-lg .slider {
    width: 290px !important;
  }

  @media (max-width: 768px) {
    .form-inline.center-inline-modal-form-lg .slider {
      width: 450px !important;
    }
  }

  @media (max-width: 425px) {
    .datetimepicker {
      top: 43px;
      left: 0px;
    }

    .hour,
    .datetimepicker table thead tr th:first-child,
    .day,
    .minute {
      font-size: 13px;
    }
  }

  @media (max-width: 425px) {
    .form-inline.center-inline-modal-form-lg .slider {
      width: 370px !important;
    }
  }

  @media (max-width: 320px) {
    .form-inline.center-inline-modal-form-lg .slider {
      width: 270px !important;
    }
  }
}

.modal-header {
  border-radius: 0px;
  border: none;
  font-size: 1.4em;
  font-weight: 700;
  background-color: $white;
  text-align: center;
  min-height: 100px;
  color: $niceBlue;
  border-bottom: 14px solid $niceBlue;

  span {
    color: $black;
    font-size: 2.4rem;
  }

  h1 {
    font-weight: 400;
    font-size: 2.4rem;
  }

  h2 {
    font-weight: 400;
    font-size: 2.6rem;
  }
}

.modal-body {
  border-radius: 0px;
  border: none;
  font-size: 1.4em;
  background-color: $modalBackground;
  text-align: center;

  h3 {
    margin-bottom: 30px;
    z-index: 1;
    position: relative;
    text-align: center;
  }

  h3:before {
    border-top: 2px solid $white;
    content: '';
    margin: 0 auto;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    z-index: -1;
  }

  h3 span {
    padding: 0 10px;
  }

  label {
    font-weight: 400;
  }
}

.modal-footer {
  border-radius: 0px;
  border: none;
  background-color: $white;
  text-align: center;

  .btn {
    width: 150px;
  }

  .spinner {
    top: -40px;
  }

  &.bulk-loaded {
    .btn {
      width: auto;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// Login help Modal
.modal-body.login-help {
  label {
    margin-bottom: 20px;
  }

  .form-errors {
    top: -10px;
    position: relative;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Provider Modal
.modal-header.provider-modal {
  padding: 20px;
  background-color: $niceBlue;
  color: $white;

  span {
    color: $white;
  }

  #provider-details {
    padding-top: 30px;

    img.img-circle {
      cursor: pointer;
    }
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 400;
  }

  #contact-button {
    margin-top: 30px;
  }

  .contact-form {
    color: $black;
    font-weight: 400;

    textarea {
      margin: 15px 0 15px 0;
      z-index: 10;
    }
  }

  .permission-section {
    select {
      margin-top: 10px;
    }

    .btn {
      margin-top: 15px;
    }
  }

  .btn-success {
    background-color: $lightBlue;
  }
}

.modal-body.provider-modal {
  color: $black;

  .table>tbody>tr>td:first-child {
    border-right: 3px solid $white;
    text-align: left;
    width: 50%;
  }

  .table {
    font-size: 1.6rem;
  }
}

// review section
.provider-review-rating {
  text-align: left;

  .glyphicon {
    font-size: 20px;
    color: $skyBlue;
  }
}

.provider-review-issues {
  text-align: left;

  ul {
    list-style-type: none;

    li {}
  }
}

.provider-review-comments {
  text-align: left;
}

.provider-phone {
  h4 {
    color: $white;
    padding-top: 10px;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Job Receipt Modal
.modal-body.job-receipt-modal {
  font-size: 1.6rem;
  text-align: left;

  pre {
    background-color: $lightGrayBlue;
  }

  ul {
    li {
      padding: 10px 0 5px 0;
    }
  }

  button {
    margin-top: 10px;
  }
}

.modal-footer.job-receipt-modal {
  .btn {
    width: 225px;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Map Detail View Modal

.modal-header.map-detail-modal {
  h2 {
    font-size: 2rem;
  }
}

.modal-body.map-detail-modal {
  font-size: 1.4rem;
  text-align: left;
}

////////////////////////////////////////////////////////////////////////////////
// Add provider / requester Modal
.registration-form {
  text-align: left;
}

////////////////////////////////////////////////////////////////////////////////
//Both Pool Modals
.modal-body {
  .pool-provider-view {
    .newly-removed-provider {
      background: $canceled;
    }

    thead>tr>th:first-child {
      width: 1px;
    }

    font-size: 1.4rem;

    input[type='checkbox'] {
      margin-top: 0;
      width: 100%;
    }

    @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
      text-align: left;

      input[type='checkbox'] {
        height: 20px;
        width: auto;
      }
    }
  }

  .table>thead>tr>th {
    text-align: center;
  }
}

// New Pool Modal
.pool-form-wrapper {
  text-align: left;

  label {
    padding-top: 10px;
  }

  .form-errors {
    padding-top: 10px;
  }

  br {
    display: none;
  }

  @media (min-width: 768px) {
    text-align: right;

    label {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .form-errors {
      padding: 0px;
    }

    .form-control {
      min-width: 190px;
    }

    br {
      display: block;
    }
  }
}

#pool-modal-footer {
  padding-top: 40px;
}

////////////////////////////////////////////////////////////////////////////////
// Edit Pool Modal
.edit-pool-modal {
  ul {
    margin: 0;
    padding: 0;
    text-align: left;
  }

  h4 {
    margin: 21px 0;
    font-weight: bold;
  }

  .selected-providers {
    font-size: 1.4rem;

    .newly-added-provider {
      background: $green;
    }

    thead>tr>th:first-child {
      width: 1px;
    }

    tbody>tr>td:first-child {
      input {
        margin: 0;
      }
    }
  }

  .rating-holder {
    font-size: 16px;
    font-weight: bold;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Add User to Pool Modal
.add-user-pool-modal {
  text-align: left;
}

////////////////////////////////////////////////////////////////////////////////
// Add New Escalation Modal
.escalation-modal-body {
  .pool-list {
    padding: 10px 0;
  }

  .btn {
    width: 170px;
    margin-left: 5px;
  }

  .Absolute-Center {
    height: 50%;
    width: 75%;
    overflow: auto;
    margin: auto;
  }

  .Center-Container {
    position: relative;
  }

  .field-wrapper {
    text-align: center;
    padding: 10px;

    label {
      padding-right: 20px;
    }

    input {
      width: 200px;
    }
  }

  .dynamic-attribs {
    label {
      float: left;
    }

    .multiSelect .helperButton.reset {
      float: none;
    }

    .multiSelect .checkboxLayer {
      width: 100%;
      position: relative;
    }

    .field-wrapper {
      text-align: right;
    }
  }
}

.escalation-modal-footer {
  button {
    width: 170px !important;
    margin-left: 5px;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Add Location Modal - Meeting Place Section
.location-modal {
  #location-form {
    text-align: left;

    h3 {
      text-align: center;
    }
  }

  label {
    padding-top: 15px;
  }

  h3 {
    margin-bottom: 15px;
  }

  .move-down {
    margin-bottom: 0;
  }

  button {
    width: 170px;
    margin-left: 5px;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.location-modal-footer {
  button {
    width: 170px !important;
    margin-left: 5px;
  }
}

//Meating place modal
.meeting-place-modal {
  #meetingPlaceForm {
    @media (max-width: 767px) {
      text-align: left;

      h3 {
        text-align: center;
      }
    }
  }

  #locationFilter {
    margin-bottom: 0;

    @media (min-width: 768px) {
      width: 50%;
      margin-bottom: 20px;
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////
//add job
.modal-footer.job-modal {
  .btn {
    width: 200px !important;
    margin: 30px 40px;
  }
}

//////////////////////////////////////////////////////////////////////////////////
//Transfer split end session modal
.modal-body.transferSplitModal {
  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    padding: 10px;
  }

  .form-inline.center-inline-modal-form-lg {
    label {
      font-size: 1.6rem;
    }
  }

  span.glyphicon.exit-session {
    position: relative;
    left: 10px;
  }
}

//////////////////////////////////////////////////////////////////////////////////
//Cal Session details view
.session-detail-modal {
  text-align: left;
  font-size: 1.6rem;

  .table>tbody>tr>td:first-child {
    border-right: 3px solid #ffffff;
    width: 50%;
  }
}

//////////////////////////////////////////////////////////////////////////////////
//Individual image viewer modal
.modal-body.image-view-modal {
  .img-modal-view {
    max-width: 800px;
    width: 100%;

    &.base64 {
      max-height: 500px;
      height: 100%;
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////
//Change verify modal
.modal-body.verify-modal {
  font-size: 1.6rem;

  div.display-label {
    font-weight: 700;
    display: inline-block;
    width: 45%;
    text-align: right;
  }

  div.display-value {
    display: inline-block;
    width: 45%;
    text-align: left;
    margin-left: 30px;

    .checkbox-inline {
      padding: 0 10px 0 5px;
    }
  }

  .glyphicon.expand-form-field {
    float: right;
  }

  .status-selector-dropdown {
    width: 200px;
  }

  .row {
    padding: 10px 0 10px 0;
  }

  .left-side {
    text-align: right;
    font-weight: 700;
  }

  .right-side {
    text-align: left;
  }

  .col-md-8.right-side {
    .row {
      padding-top: 0;

      div {
        padding: 0 0px 0 10px;
      }

      .glyph-arrow {
        padding-right: 30px;
      }
    }
  }

  .glyph-arrow {
    float: right;
  }

  .date-time-picker {
    .adjustment-option {
      color: $red;
      font-size: 1.4rem;

      input[type='checkbox'] {
        height: 20px;
        width: 20px;
        position: relative;
        top: 7px;
      }
    }

    .left-side {
      position: relative;
      top: 40px;
    }

    .calendar-picker {
      display: inline-block;
      width: 200px;
      height: 100px;

      .input-group {
        top: 33px;
      }
    }

    .dropdown-menu {
      background-color: $lightGray;
    }
  }

  .time-picker-wrapper {
    display: inline-block;
    padding-left: 20px;

    span {
      button {
        background-color: $white;
      }
    }
  }

  .duration-input {
    text-align: right;

    .input-group {
      width: 150px;
      display: inline-block;

      input[type='number'] {
        width: 60px;
      }

      .input-group-addon {
        width: 60px;
        height: 34px;
      }
    }
  }

  .break-section-add {
    font-weight: bold;
    color: var(--main-color);
    display: block;
    margin-bottom: 4px;
    cursor: pointer;
  }

  .delete-break-session {
    color: $canceled;
  }

  .verified-message {
    border: 1px solid #eed882;
    background-color: #eed882;
    border-radius: 10px;
    padding: 8px 0;
    font-size: 14px;
  }

  .amount-input {
    .total-cost-input {
      padding-left: 50px;
    }

    div {
      text-align: right;
    }

    .input-group {
      width: 120px;
    }
  }

  .row.verify-note {
    .display-label {
      position: relative;
      vertical-align: top;
    }

    textarea {
      width: 250px;
      z-index: 10;
    }
  }

  @media (max-width: 991px) {
    .left-side {
      text-align: left;
    }

    .right-side {
      padding-top: 10px;

      .row {
        div {
          width: 30%;
          display: inline-block;
        }
      }
    }

    .checkin-date-time {
      .col-md-3.glyph-arrow {
        position: relative;
        top: -20px;
      }
    }

    .row.duration-input {
      text-align: left;
    }

    .row.amount-input {
      text-align: left;

      .dropdown-selection {
        display: inline-block;
        position: relative;
        vertical-align: top;
      }

      .col-md-2 {
        width: 50%;
        display: inline-block;
      }
    }


  }
}

.modal-body {
  .role-privilege-view {
    font-size: 1.4rem;

    .select-column {
      width: 15%;
    }

    .name-column {
      text-align: left;
    }
  }
}

.enterprise-form {
  span.tail {
    font-size: 14px;
  }
}

.serviceRequestModal,
.schedule-job {
  .job-type-form {
    .checkbox-inline {
      cursor: pointer !important;
    }

    #pickListCheckbox:disabled+label {
      color: #aaa;
    }
  }

  .pick-list {
    clear: both;

    table {
      font-size: 1.4rem;

      .newly-removed-provider {
        background: $canceled;
      }

      .newly-added-provider {
        background: $green;
      }

      .provider-name {
        cursor: pointer;
      }
    }
  }

  .export-button-container {
    display: flex;
    justify-content: center;

    .glyph-link {
      a {
        border: 1px solid var(--main-color);
      }
    }
  }
}

.instance-count-glyph-btns {
  .glyphicon.glyphicon-plus-sign {
    top: 3px;
  }
}

.worker-item {
  font-size: 1.4rem;
}

.tracking-session {
  .tracking-session-status {
    div {
      padding: 1px;
      color: #fff;
      border-radius: 20px;
      font-size: 14px;
      // width: 70%;
      margin: 0 auto;
      min-width: 70px;
    }

    .rejected {
      background: #ca4346;
    }

    .accepted {
      background: #6cb700;
    }

    .no-answer {
      background: $pending;
    }

    .not-notified {
      background: #818181;
    }
  }
}

.date-range-control {
  background: #fff;
  box-shadow: 0px 5px 10px 0px #c9cccf;
  position: relative;

  .date-range-close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #c9cccf;
    border: none;
    background: none;
    font-weight: bold;
    font-size: 25px;
    z-index: 9999;
  }

  .date-range {
    text-align: center !important;

    label {
      text-align: left !important;
    }

    .mighty-picker__holder {
      background: #fff;
      padding-top: 20px;

      .mighty-picker-calendar__day--in-range {
        color: #fff;
        background: $lightBlue;
      }

      .mighty-picker-calendar__day--selected {
        background: $niceBlue;
      }

      .mighty-picker-calendar__day--disabled {
        background: #fff;
      }

      th,
      td {
        text-align: center;
        padding: 5px 10px;
        border-bottom: 1px solid #e5e5e5;
        font-size: 14px;
      }

      .mighty-picker-calendar {
        margin-top: 10px;
      }

      .mighty-picker-calendar__days>th {
        text-align: center;
        color: $niceBlue;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 14px;
      }

      .mighty-picker__month {
        text-align: center;
        font-size: 16px;
        text-transform: uppercase;
        color: $niceBlue;
      }

      .mighty-picker__next-month {
        right: 10px;
        background: url('../../svg/date-arrow-right-gray.svg') no-repeat;
      }

      .mighty-picker__prev-month {
        left: 10px;
        background: url('../../svg/date-arrow-left-gray.svg') no-repeat;
      }

      .mighty-picker__prev-month,
      .mighty-picker__next-month {
        position: absolute;
        top: 23px;
        width: 20px;
        height: 20px;
        background-size: cover;
        border: none;
        color: transparent;
      }
    }
  }
}

@media (min-width: 768px) {
  .pick-list-modal {
    .modal-dialog {
      width: 75%;
    }
  }
}

@media (min-width: 1400px) {
  .pick-list-modal {
    .modal-dialog {
      width: 50%;
    }
  }
}

.pick-list-modal {
  .modal-body {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.wizard-hidden {
  .steps-indicator {
    display: none;
  }
}

.steps-indicator {
  margin-bottom: 55px;

  &:before {
    background-color: $niceBlue;
    height: 5px;
    left: calc(105% / 5 / 2) !important;
    right: calc(105% / 5 / 2) !important;
  }

  li {
    padding-top: 30px;
    cursor: pointer;

    &.editing a {
      color: $lightBlue;
    }

    a {
      text-transform: none;
      font-size: 16px;

      &:before {
        font-family: FontAwesome;
        color: #fff;
        width: 45px;
        height: 45px;
        top: -20px;
        left: calc(50% - 22px);
        font-size: 22px;
        line-height: 45px;
        height: 45px;
        font-weight: 400;
      }
    }

    &:nth-child(1) a:before {
      content: '\f044';
    }

    &:nth-child(2) a:before {
      content: '\f1fc';
    }

    &:nth-child(3) a:before {
      content: '\f017';
    }

    &:nth-child(4) a:before {
      content: '\f155';
    }

    &:nth-child(5) a:before {
      content: '\f10b';
    }

    &.done a:before {
      background-color: $niceBlue;
    }

    &.editing a:before {
      background-color: $lightBlue;
    }
  }
}

.wizard-toggle {
  position: absolute;
  right: 10px;
  top: 10px;

  .btn-info {
    background-color: $niceBlue;
  }
}

.pick-list {
  .img-circle {
    width: 40px;
    height: 40px;
  }
}

.interested-providers {
  .img-circle {
    width: 30px;
    height: 30px;
  }

  .export-csv {
    float: right;
    border: none;
    background: none;
    margin-bottom: 5px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.selected-providers {
  font-size: 1.4rem;

  .profile-picture {
    display: inline-block;
  }
}

.new-user-modal {
  .required {
    &:after {
      content: '*';
    }
  }

  .email-data {
    font-size: 14px;
    font-weight: 400;
    color: #686868;
    margin-left: 13px;
  }

  input.ng-invalid.ng-dirty {
    border: 1px solid red;
  }
}

.invite-user-modal {
  .description {

    label,
    p {
      display: inline-block;
    }

    p {
      width: 300px;
      color: #b6b6b6;
      font-size: 10px;
      line-height: 11.5px;
      font-weight: 400;
      margin-top: 10px;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    label {
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}

.invitations-modal,
.invite-user-modal {
  .bulk-loaded-button {
    height: 30px;
    line-height: 30px;
    border: 1px solid #b6b6b6;
    border-radius: 15px;
    padding-left: 5px;
    font-size: 14px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    width: 300px;

    .glyphicon {
      position: static !important;
      margin-left: 5px;
    }
  }
}

.price-label.required {
  &:after {
    content: '*';
  }
}

.form-legend {
  text-align: center !important;
  font-style: italic;
  font-size: 0.7em;
}

.selector-buttons {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10px;

  .btn {
    width: 150px;
  }
}

.export-button {
  .glyph-link {
    a {
      border: 1px solid var(--main-color);
    }
  }
}

.internal-notes-textarea {
  width: 100%;
  min-height: 200px;
}

.invitations-modal {
  .container-fluid {
    margin: 0 40px;
  }

  &.bulk-loaded {
    table {
      td {
        padding: 0 !important;
      }
    }
  }

  .invitations-table-control {
    display: inline-block;
    width: 300px;
    overflow-y: auto;
    background: #fff;
    max-height: 300px;
    padding: 20px;

    &>div {
      display: flex;
      align-items: center;

      &>label {
        margin: 0 !important;
        text-align: left !important;
        margin-left: 20px !important;

        span {
          word-break: break-all;
        }
      }
    }

    .checkbox-helper {
      width: 24px;
      position: relative;
      margin: 10px auto;

      label {
        width: 24px;
        height: 24px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        background: #7d7d7d;
        box-shadow: inset 0px 0px 7px rgba(0, 0, 0, 0.25);
        border-radius: 6px;

        &:after {
          content: '';
          height: 8px;
          width: 16px;
          left: 4px;
          top: 5px;
          opacity: 0;
          position: absolute;
          border: 2px solid #fcfff4;
          border-top: none;
          border-right: none;
          background: transparent;
          opacity: 0;
          transform: rotate(-45deg);
        }
      }

      input[type='checkbox'] {
        visibility: hidden;

        &:checked+label:after {
          opacity: 1;
        }
      }
    }
  }

  table {
    width: 100%;
    font-size: 16px;
    line-height: 18.4px;

    thead {
      tr {
        th {
          background: none;
          font-weight: bold;
          padding-top: 16px;
          font-size: 16px;
          line-height: 18.4px;
          padding-bottom: 20px;
        }
      }
    }

    tbody {
      tr {
        border-top: 1px solid #cfcfcf;

        td {
          text-align: left;
          padding: 24px 0;

          .invitation-email {
            word-break: break-all;
            max-width: 80%;
          }

          &.bulk-loaded {
            label {
              margin: 0;
            }

            .bulk-loaded-label {
              font-size: 16px;

              .label-name {
                margin-right: 24px;
              }

              .label-email {
                color: #686868;
              }
            }
          }

          .invitation-status {
            display: inline-flex;
            background: #eaeaea;
            border-radius: 15px;
            font-weight: bold;
            text-transform: capitalize;
            padding: 7px 16px 7px 10px;

            &.expired {
              background: #ff5454;
              color: #fff;
            }

            .image-holder {
              margin-right: 10px;
              background: #fff;
              padding: 5px;
              border-radius: 50%;
              width: 16px;
              height: 16px;
              display: flex;
              padding: 2px;
              align-items: center;
              justify-content: center;
            }
          }

          .invitation-action-first {
            margin-right: 17px;
          }
        }
      }
    }
  }
}

.provider-email-toggler {
  .toggle {
    width: 34px !important;
    height: 20px !important;
    border-radius: 54px;

    &.toggler-on {
      background: #7edf6e !important;

      .toggle-group {
        right: -15px;
        top: -2px;
        width: 150%;
      }
    }

    &.off {
      background: #cdcccd !important;

      .toggle-group {
        left: -23px;
        top: -2px;
      }
    }

    .toggle-on,
    .toggle-off {
      display: none !important;
    }

    .toggle-handle {
      width: 16px !important;
      height: 16px !important;
      background: #fff !important;
      padding: 0;
      margin: 0;
      border-radius: 50%;
      opacity: 1;
    }
  }

  &-text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #686868;
    margin-left: 25px;
  }
}

.bulk-invite-warning {
  display: flex;
  flex-direction: column;
  align-items: center;

  .image-holder {
    width: 57px;
    height: 57px;
    background: rgba(216, 146, 42, 0.2);
    border: 2px solid rgba(216, 146, 42, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: 42px;
    margin-bottom: 16px;
  }

  h3 {
    color: var(--main-color);
    margin: 0;
    margin-bottom: 16px;
    font-size: 23px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    line-height: 18px;
    max-width: 50%;
    text-align: center;
  }

  hr {
    width: 100%;
  }

  .buttons {
    margin-bottom: 30px;
  }
}